<template lang="html">
    <div class="footer w-100 d-flex flex-wrap justify-content-center align-content-center align-items-center pt-4">
        <div class="col-12 col-md-4 ps-lg-5 ps-5 d-flex flex-wrap">
            <div class="col-12 text-start font-7 s1 mt-3">{{phrases.f1[lang]}}</div>
            <div class="col-xl-6 col-12 text-start font-7 mt-3 ps-4"><a class="link1" href="https://www.bostons.com.mx/" target="_blank">Bostons.mx</a></div>
            <div class="col-xl-6 col-12 text-start font-7 mt-3 ps-4"><a class="link1" href="https://bostons.com.mx/merida/welcome/privacy" target="_blank">{{phrases.f3[lang]}}</a></div>
            <div class="col-12 text-start font-7 mt-3 ps-4"><a class="link1" href="https://bptgroup.mx/" target="_blank">BTPgroup.mx</a></div>
            <div class="col-12 text-start font-7 mt-3 ps-4"><a class="link1" href="https://bostons.com.mx/merida/welcome/privacy" target="_blank">{{phrases.f2[lang]}}</a></div>
        </div>
        <div class="col-12 col-lg-4 ps-0 d-flex mt-5 mt-lg-0 flex-wrap">
            <div class="w-100 justify-content-center d-flex"><img :src="logo" class="logo"></div>
        </div>
        <div class="col-12 col-lg-4 ps-0 d-flex mt-5 mt-lg-0 flex-wrap justify-content-end">
            <div class="d-flex justify-content-center col-12 col-lg-10 socialLabel s1 font-7"><div class="socialLabelInner text-center text-lg-start d-flex align-items-center align-content-center ps-lg-4 justify-content-center justify-content-lg-start">{{phrases.f4[lang]}}</div></div>
            <div class="w-100 d-flex justify-content-center mt-3">
                <a class="circleSocial me-2" :href="getUserSocial()[0]" target="_blank"><img :src="a40"></a>
                <a class="circleSocial ms-2" :href="getUserSocial()[1]" target="_blank"><img :src="a41"></a>
            </div>
        </div>    
        <div class="col-12 col-lg-3 col-xl-4 ps-0 d-flex flex-wrap mt-5 mt-lg-4">
            <div class="w-100 justify-content-center d-flex font-7 s3 px-5">{{phrases.f6[lang]}}</div>
        </div>
        <div class="col-12 col-lg-5 col-xl-4 ps-0 d-lg-flex d-none flex-wrap mt-4 d-flex justify-content-center">
            <div class="col-lg-10 col-xxl-8 col-12 centerLabel">
                <div class="centerLabelInner align-items-center align-content-center justify-content-center d-flex">
                    <div class="col-6 s4 font-7 text-center px-lg-3">800 BOSTONS</div>
                    <div class="col-6 s4 font-7 text-center px-lg-3">800 267-86-67</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3 col-xl-4 ps-0 mt-4 mb-5 mb-lg-0">
            <div class="col-lg-10 col-12 text-center d-flex justify-content-center"><span class="s3 font-9">{{phrases.f5[lang]}}</span>&nbsp;<a class="link2 s5 font-7" target="_blank" href="https://www.efisense.com/">./Efisense.com</a></div>
        </div>   
    </div>
</template>
<script>
export default {
    name:"footerPart",
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        }
    },
    methods:{
        getSocialByid(id){
            if(this.socials.hasOwnProperty(id)){
                return [this.socials[id].it, this.socials[id].fb]
            }else{
                return['https://www.instagram.com/bostonsmerida/?hl=es', 'https://www.facebook.com/boston.merida']
            }
        },
        getUserSocial(){
            if(this.user.token != null){
                if(this.user.data.deliveryDir != '' && this.user.data.deliveryDir != undefined){
                    if(this.user.data.homes.hasOwnProperty(this.user.data.deliveryDir)){
                        return this.getSocialByid(this.user.data.homes[this.user.data.deliveryDir].storeNumber)
                    }
                }
            }else{
                if(this.cityMenu.hasOwnProperty('storeNumber')){
                    return this.getSocialByid(this.cityMenu.storeNumber)
                }
            }
            return this.getSocialByid(6001)
        }
    },
    data(){
        return{
            socials:{
                6012:{
                    fb:"https://www.facebook.com/Bostons-M%C3%A9xico-1656031098025355/",
                    it:"https://www.instagram.com/bostons_mx/?hl=es"
                },
                6022:{
                    fb:"https://www.facebook.com/bostonsdf",
                    it:"https://www.instagram.com/bostons_mx/?hl=es"
                },
                6024:{
                    fb:"https://www.facebook.com/bostonsdf",
                    it:"https://www.instagram.com/bostons_mx/?hl=es"
                },
                6002:{
                    fb:"https://www.facebook.com/boston.merida",
                    it:"https://www.instagram.com/bostonsmerida/?hl=es"
                },
                6001:{
                    fb:"https://www.facebook.com/boston.merida/",
                    it:"https://www.instagram.com/bostonsmerida/?hl=es"
                },
                6008:{
                    fb:"https://www.facebook.com/boston.merida/",
                    it:"https://www.instagram.com/bostonsmerida/?hl=es"
                },
                6019:{
                    fb:"https://www.facebook.com/boston.merida/",
                    it:""
                },
                6007:{
                    fb:"https://www.facebook.com/bostonsgalerias/?locale=es_LA",
                    it:"https://www.instagram.com/bostonscampeche"
                },
                6023:{
                    fb:"https://www.facebook.com/BostonsQro/",
                    it:"https://www.instagram.com/bostons.qro/"
                },
            },
            logo:require('@/assets/images/franchise/logo.svg'), 
            a40:require('@/assets/images/a40.png'), 
            a41:require('@/assets/images/a41.png'), 
            phrases:{
                f1:{
                    es:"Sitios de Interés",
                    en:"Interesting Websites"
                },
                f2:{
                    es:"Aviso de Privacidad",
                    en:"Notice of Privacy"
                },
                f3:{
                    es:"Términos y Condiciones",
                    en:"Terms and Conditions"
                },
                f4:{
                    es:"Redes Sociales",
                    en:"Social Networks"
                },
                f5:{
                    es:"Creado por",
                    en:"Created by"
                },
                f6:{
                    es:"Todos los derechos reservados BPT Group México 2024",
                    en:"All rights reserved BPT Group Mexico 2024"
                }
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .footer{
        margin: 0 5px 5px 5px;
        height: auto;
        background-color:var(--quartenary);
        border-radius: 0 0 30px 30px;
    }
    .logo{
        width: 161px;
        height: 161px;
        object-position: left center; 
        object-fit: contain;
        background-color: #fff;
        padding: 5px;
        border-radius:50%;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .s1{
        font-size: 26px;
        color: var(--text2);
    }
    .link1{
        font-size: 16px;
        color: var(--text8);
        text-decoration: none;
    }
    .link1:hover{
        color: var(--text11);
    }
    .link2{
        text-decoration: none;
    }
    .s2{
        font-size: 24px;
        color: var(--text2);
    }
    .s3{
        font-size: 16px;
        color: var(--text8);
    }
    .s4{
        font-size: 18px;
        color: var(--text2);
    }
    .s5{
        font-size: 16px;
        color: var(--text2);
    }
    .socialLabel{
        height: 70px;
        position: relative;
    }
    .socialLabelInner{
        width: 100%;
        height: 100%;
        top: 0;
        left: 2px;
        background-color: var(--primary);
        position: absolute;
        border-radius: 12px 0 0 12px;
    }

    @media (max-width: 991px){
        .socialLabelInner{
            width: 80%;
            left:10%;
            text-align:center;
            border-radius: 12px;
        }
    }

    .circleSocial{
        width: 81px;
        height: 81px;
        border-radius: 50%;
        background-color: #fff;
        border:4px solid var(--vigecuartenary);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .circleSocial img{
        object-fit: contain;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
        pointer-events: none
    }
    .circleSocial:hover img{
        transform: scale(1.07);
        filter: contrast(0.9);
    }
    .centerLabel{
        position: relative;
        height: 60px;
    }
    .centerLabelInner{
        background-color: var(--primary);
        border-radius: 30px 30px 0 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 2px;
    }
</style>