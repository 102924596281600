<template>
    <div class="menu d-flex flex-wrap justify-content-center">

        <div class="w-100 mt-5 ps-lg-5 d-flex flex-wrap align-items-center align-content-center justify-content-between" v-if="this.$route.name != 'item'">
            <div class="d-flex ps-3 align-items-center align-content-center">
                <div class="buttonCity d-flex align-items-center align-content-center px-0">
                    <div class="titleSelect font-7 fw-bold">{{phrases.menu[lang]}}</div>
                    <div class="selectSpaceSelector font-1 d-flex align-items-center align-content-center justify-content-center">
                        <span @click="changeCity()">{{cityMenu.friendlyName}}</span>
                    </div>
                </div>
                <span @click="changeCity()" class="ms-2 me-2 ms-md-4 me-md-0 font-7 ts-3 changeCityLabel text-start ps-2 ps-md-0">{{phrases.label1[lang]}}</span>
            </div>
            <div class="col-xxl-4 col-lg-6 col-12 col-lg-4 pe-lg-5">
                <div class="col-12 pe-lg-1">
                    <order-sign :socket="socket" :lang="lang"></order-sign>
                </div>
            </div>
        </div>

        <city-selector :cities="cities" :cityMenu="cityMenu" :lang="lang" v-if="citySelectModal" @close="closeSelectCity()"></city-selector>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transition" :mode="route.meta.mode">
                <KeepAlive :include="['MenuGeneral']">
                    <component :is="Component" :socket="socket" :con="con" :view="view" :categories="categories" :transfer="transfer" :user="user" :car="car" :cityMenu="cityMenu" :lastPosition="lastPosition" class="font-1"/>
                </KeepAlive>
            </transition>
        </router-view>

    </div>
</template>

<script>
import citySelector from "@/components/responsive/citySelector.vue";
import orderSign from "@/components/common/orderSign.vue";

export default {
    name: "Menu",
    emits:['event'],
    components:{
        orderSign,
        citySelector
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        view:{
            default:"responsive",
            type: String
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        car:{
            default:{},
            type:Object
        }
    },
    data(){
        return{
            categories:[],
            citySelectModal:false,
            phrases:{
                menu:{
                    es:"Menú:",
                    en:"Menu:",
                },
                label1:{
                    es:"Cambiar de ciudad",
                    en:"Change city"
                }
            }
        }
    },
    methods:{
        changeCity(){
            this.citySelectModal = true;
        },
        closeSelectCity(){
            this.citySelectModal = false;
        },
        getCategories(){
            return new Promise((resolve, reject) => {
                this.con.getCategories({token:this.cityMenu.token}).then((res)=>{
                    this.categories = res.data;
                    resolve(true)
                }).catch((err)=>{
                    reject(err)
                })
            })
            
        },
    },
    mounted(){
        this.getCategories();
    },
};
</script>

<style scoped>
    .menu{
        width: 100%;
        height: auto;
    }
    .changeCityLabel{
        font-size: 18px;
    }
    .buttonCity{
        height: 60px;
        width: 250px;
        background-color: var(--primary);
        border-radius: 15px;
        overflow: hidden;
    }
    .titleSelect{
        width: 30%;
        color:var(--text2);
        font-size: 15px;
    }
    .selectSpaceSelector{
        color:var(--text4);
        font-weight: bold;
        font-size: 15px;
        width:70%;
        height: 100%;
        background-color: var(--primary);
        overflow: hidden;
        padding-right: 3px;
        padding-top: 3px;
        padding-bottom: 2px;
    }
    .selectSpaceSelector span{
        text-align: center;
        border: 0;
        width: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-radius: 0 12px 12px 0;
        background-color: #fff;
        -webkit-appearance: none;
        cursor: pointer;
    }
</style>
