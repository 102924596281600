<template>
  <div :id="unique" class="map"></div>  
</template>

<script>
import {Loader, LoaderOptions} from 'google-maps';
const options = LoaderOptions = {libraries: ["places", "geometry"]};
const loader = new Loader('AIzaSyBzTUlTCkpSro0LUfKZcUseK4_yfhnqhFI', options);
export default {
    name: "googlemap",
    props:{
        unique:{ //almacena el nombre de la orden, id
            default: "thismap",
            type: String
        },
        status:{//datos de tienda, franquicia
			default: 0,
			type: Number
		},
        store: { //datos de tienda, franquicia
			default: {},
			type: Object
		},
        userLocation:{
            default:"México",
            type: String
        },
		con: { //clase para peticiones a api
			default: {},
			type: Object
		},
        type:{ //typo de mapa y animacion que mostrará
            default:"home",
            type: String
        },
        storeCords:{ //typo de mapa y animacion que mostrará
            default:[],
            type: Array
        },
    },
    watch:{
        status(){
            switch(this.status){
                case 1:
                    break;
                case 2:
                    if(!this.apiLoaded){
                        this.loadData();
                    }else if(this.apiLoaded && !this.markersWrited){
                        this.loadMap(this.api)
                    }else if(this.apiLoaded && this.markersWrited && this.animating){
                        this.stopAnimate();
                    }
                    break;
                case 3:
                    if(!this.apiLoaded){
                        this.loadData();
                    }else if(this.apiLoaded && !this.markersWrited){
                        this.loadMap(this.api)
                    }
                    break;
                case 4:
                    if(!this.apiLoaded){
                        this.loadData();
                    }else if(this.apiLoaded && !this.markersWrited){
                        this.loadMap(this.api)
                    }else if(this.apiLoaded && this.markersWrited && !this.animating){
                        this.centerMap()
                        this.drawRoute()
                    }
                    break;
                case 5:
                    if(!this.apiLoaded){
                        this.loadData();
                    }else if(this.apiLoaded && !this.markersWrited){
                        this.loadMap(this.api)
                    }else if(this.apiLoaded && this.markersWrited && this.animating){
                        this.stopAnimate()
                        this.centerMap()
                    }
                    break;
            }
        },
        changes(){ //intenta dibujar la ruta entre los puntos si start y destiny tienen valores
            if(this.status == 4 && this.type == 'home'){
                this.drawRoute();
            }
        },
    },
    data(){
        return{
            data:{},
            defStoreCoords: {lat: 21.0111819, lng: -89.588584}, //cordenadas de la sucursal (falta poner en brand)
            iconStore: require('@/assets/images/a36.png'),
            iconDestiny: require('@/assets/images/a37.png'),
            iconDelivery: require('@/assets/images/a38.gif'),
            request: { //objeto helper para hacer busquedas en la api de google
                query: null,
                fields: ["name", "geometry"],
            },
            start:null, //cordenadas del inicio del recorrido (sucursal)
            destiny:null, //cordenadas del final del recorrido (direccion del cliente)
            changes:0,
            map:{},
            markersWrited:false,
            apiLoaded:false,
            animating:false,
            animationTimeout: null,
            zoom:13,
            api:null,
            previousPathSlice:null,
            trackedRoutes: []
        }
    },
    methods:{
        loadData(){
            /**
             *
             * ejecuta la peticion en la api para obtener datos de la orden por el id de la orden y token
             * @param /
             * @change /
             * @return /
             */
             this.chargeMapApi()
        },
        chargeMapApi(){
            /**
             *
             * carga el paquete npm para cargar google async por vue
             * @param /
             * @change /
             * @return /
             */
			loader.load().then(google => {
                this.api = google;
                this.apiLoaded = true;
				this.loadMap(google)
            });
     	},
        locatePlace(req, icon, type=null){
            /**
             *
             * busca una direccion en api de google y obtiene sus coordenadas
             * asigna valores al helper changes
             * @param req: object, objecto que incluye la direccion escrita
             * @param icon: icono a asignar al crear el marcador cuando se encuentre la ubicacion en el mapa
             * @param type: determina si asginara cierto objeto helper
             * @change changes, destiny, start, map
             * @return /
             */

            return new Promise((resolve, reject)=>{
                let place = new google.maps.places.PlacesService(this.map);
                place.findPlaceFromQuery(req, (results, status) => {
                    
                    if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                        //console.log(results, '<-----', results[0].geometry.location.lat(), results[0].geometry.location.lng())
                        switch (type){
                            case "start":
                                this.start = [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
                                break;
                            case "destiny":
                                this.destiny = [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
                                break;
                            default:
                                break;
                        }
                        const marker = new google.maps.Marker({
                            map: this.map,
                            position: results[0].geometry.location,
                            icon: {
                                url: icon,
                                scaledSize: new google.maps.Size(45, 45),
                                origin: new google.maps.Point(0, 0),
                            }
                        });
                    }
                    resolve(true)
                });
            }).catch((err)=>{})
           
        },
        drawBreakRoute(){
            /**
             *
             * funcion para dibujar una ruta en el mapa de google apartir de dos direcciones
             * ejecuta una animacion en la ruta
             * @param /
             * @change /
             * @return /
             */
            
            
            if(this.start != null && this.destiny != null){
                var display = new google.maps.DirectionsRenderer();
                var services = new google.maps.DirectionsService();

                let request = {
                    origin : new google.maps.LatLng(this.start[0], this.start[1]),
                    destination:new google.maps.LatLng(this.destiny[0], this.destiny[1]),
                    travelMode: 'DRIVING'
                };

                display.setOptions({ 
                    suppressMarkers: true ,
                    polylineOptions: {
                        strokeWeight: 4,
                        strokeOpacity: 0.6,
                        strokeColor:  'blue' 
                    }
                });

                services.route(request, (result,status) => {
                    if(status =='OK'){
                        display.setDirections(result);
                        this.animation(result);
                    }
                });

                display.setMap(this.map);
                    
            }
        },
        drawRoute(){

            if (this.start != null && this.destiny != null) {
                const numPoints = 300; // Número de puntos para generar la curva (ajústalo según sea necesario)

                // Convertir los puntos de inicio y destino a objetos LatLng
                const startLatLng = new google.maps.LatLng(Number(this.start[0]), Number(this.start[1]));
                const destinyLatLng = new google.maps.LatLng(Number(this.destiny[0]), Number(this.destiny[1]));

                // Calcular los puntos intermedios a lo largo de la curva que conecta los dos puntos
                const points = [];
                for (let i = 0; i <= numPoints; i++) {
                    const fraction = i / numPoints;
                    const point = google.maps.geometry.spherical.interpolate(startLatLng, destinyLatLng, fraction);
                    points.push(point);
                }

                // Crear una ruta poligonal utilizando los puntos intermedios
                const route = new google.maps.Polyline({
                    path: points,
                    geodesic: true,
                    strokeColor: '#b4b4b4', // Inicialmente, la ruta es de color blanco
                    strokeOpacity: 0.6,
                    strokeWeight: 7,
                    map: this.map
                });

                this.trackedRoutes.push(route);

                // Iniciar animación de entrega a lo largo de la ruta
                this.animating = true;
                this.animateGeodesic(route);
            }
        },
        stopAnimate(){
            this.animating = false;
        
            // Detener la animación (si está en curso)
            clearTimeout(this.animationTimeout);

            // Eliminar todas las rutas trazadas en el mapa
            this.trackedRoutes.forEach(route => {
                route.setMap(null);
            });

            // Limpiar la lista de rutas trazadas
            this.trackedRoutes = [];

            // También elimina el último tramo de ruta animado
            if (this.previousPathSlice) {
                this.previousPathSlice.setMap(null);
            }
        },
        animateStep(currentStep, route, numPoints, stepDuration, previousPathSlice) {
            // Eliminar la porción de ruta dibujada en la iteración anterior

            // Dibujar una porción de la ruta en rojo sólido
            const pathSlice = new google.maps.Polyline({
                path: route.getPath().getArray().slice(0, currentStep + 1),
                geodesic: true,
                strokeColor: '#FF0000', // Color rojo sólido
                strokeOpacity: 0.6,
                strokeWeight: 7,
                map: this.map
            });

            if (previousPathSlice) {
                previousPathSlice.setMap(null);
            }

            // Almacenar la porción de ruta actual para eliminarla en la próxima iteración
            previousPathSlice = pathSlice;

            // Si la animación no ha terminado, continuar animando
            if (currentStep < numPoints - 1) {
                this.animationTimeout = setTimeout(() => {
                    this.animateStep(currentStep + 1, route, numPoints, stepDuration, previousPathSlice); // Avanzar al siguiente paso de la animación
                }, stepDuration);
            } else {
                // Si la animación ha terminado, reiniciarla
                this.animationTimeout = setTimeout(() => {
                    this.animateStep(0, route, numPoints, stepDuration, previousPathSlice); // Reiniciar la animación desde el principio
                }, stepDuration);
            }
            this.previousPathSlice = previousPathSlice;
        },

            // Método para animar la ruta geodésica
        animateGeodesic(route) {
            const duration = 5000; // Duración total de la animación en milisegundos
            const numPoints = route.getPath().getLength();
            const stepDuration = duration / numPoints; // Duración de cada paso de la animación

            let previousPathSlice = null;

            // Iniciar la animación
            this.animateStep(0, route, numPoints, stepDuration, previousPathSlice);
        },
        animationBreaks(response){
            /**
             *
             * ejecuta una animacion con los punto de quiebre de la ruta trazada anteriormente
             * @param response: object, resultado de la consulta services route de la api google
             * @change /
             * @return /
             */

            let path = response.routes[0].overview_path; //puntos de quiebre
            let maxIter=path.length; //iteracion maxima antes de reiniciar

            let delivery=new google.maps.Marker({
                position: path[0],
                map: this.map, 
                icon: {
                    url: this.iconDelivery,
                    scaledSize: new google.maps.Size(45, 45),
                    origin: new google.maps.Point(0, 0),
                }
            });

            let delay = 1000, count = 0;
            function delayed () {
                delivery.setPosition({lat:path[count].lat(),lng:path[count].lng()});
                count += 1;
                if (count <= maxIter-1) {
                    setTimeout(delayed, delay);
                }else{
                    count = 0;
                    setTimeout(delayed, delay);
                }
            }
            delayed();
        },
        centerMap() {
            if(this.storeCords.length > 0 && this.destiny != null && this.type == 'home' && this.status == 4){
                this.map.setCenter(new google.maps.LatLng(
                    ((Number(this.destiny[0]) + Number(this.storeCords[0])) / 2.0),
                    ((Number(this.destiny[1]) + Number(this.storeCords[1])) / 2.0)
                ));
                this.map.setZoom(14);
            }else if(this.destiny != null && this.type == 'home' && this.status >= 5){
                this.map.setCenter(new google.maps.LatLng(
                    Number(this.destiny[0]),
                    Number(this.destiny[1])
                ));
            }else if(this.storeCords.length > 0){
                this.map.setCenter(new google.maps.LatLng(
                    Number(this.storeCords[0]),
                    Number(this.storeCords[1])
                ));
            }else if(this.destiny != null){
                this.map.setCenter(new google.maps.LatLng(
                    Number(this.destiny[0]),
                    Number(this.destiny[1])
                ));
            }
        },
        loadMap(google){
            /**
             *
             * carga el mapa y ejecuta las opciones dependiendo
             * del tipo de mapa a cargar home/pickup
             * @param google, objeto google creado por la api google con ayuda del paquete npm
             * @change /
             * @return /
             */
            
            this.map = new google.maps.Map(document.getElementById(this.unique), {
                    center: this.storeCords.length > 0 ? new google.maps.LatLng(Number(this.storeCords[0]), Number(this.storeCords[1])) : this.defStoreCoords,
                    zoom: this.zoom,
                    disableDefaultUI: true,
                    zoomControl: true,
            })

            if(this.type == "home" && this.status > 1){ //mapa para delivery animacion

                if(this.storeCords.length > 0){
                    this.start = this.storeCords;
                    const marker = new google.maps.Marker({
                        map: this.map,
                        position: new google.maps.LatLng(Number(this.storeCords[0]), Number(this.storeCords[1])),
                        icon: {
                            url: this.iconStore,
                            scaledSize: new google.maps.Size(45, 45),
                            origin: new google.maps.Point(0, 0),
                        }
                    });
                }

                if(this.userLocation != ''){
                    this.request.query = this.userLocation;
                    //console.log(this.userLocation)
                    this.locatePlace(this. request, this.iconDestiny, "destiny").then(()=>{
                        this.centerMap();
                        this.changes++;
                    }).catch((err)=>{})
                }

                this.markersWrited = true;
            }
            else if(this.type == "pickup" && this.status > 1){ //mapa de la punto de la sucursal para recoger
                
                if(this.storeCords.length > 0){
                    this.start = this.storeCords;
                    const marker = new google.maps.Marker({
                        map: this.map,
                        position: new google.maps.LatLng(Number(this.storeCords[0]), Number(this.storeCords[1])),
                        icon: {
                            url: this.iconStore,
                            scaledSize: new google.maps.Size(45, 45),
                            origin: new google.maps.Point(0, 0),
                        }
                    });
                }

                this.markersWrited = true;
                this.centerMap()
                
            }
        }
    },
    mounted(){

        /**
        *
        * vue, carga todo el proceso del mapa de google
        * @param /
        * @change /
        * @return /
        */
        this.loadData();
    }
}
</script>
<style>
    .map{
        position: relative;
        width:100%;
        min-height: 365px;
    }
</style>