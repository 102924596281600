import * as jose from 'jose'

export default {
    name: "auth",
    tokenName: 'crmAuthService',
    keyValidator: 'data',
    components: {
        jose,
    },
    removeLogin() {
        try{
            localStorage.removeItem(this.tokenName)
        }catch(err){}
    },
    existToken() {
        if (!this.tokenName in localStorage) {
            return [false, false]
        }
        return [localStorage.getItem(this.tokenName), true]
    },
    getDecodedJwt(token = false, pass = false) {//verifica una llave en particular
        if (token == '' || token == null || token == false || !pass) {
            return [false, false, false];
        }

        let decripted = null;
        let isJWT = true;

        try {
            decripted = jose.decodeJwt(token);
        } catch (e) { isJWT = false }

        if (!isJWT) {
            return [false, false, false];
        }

        if (!decripted.hasOwnProperty(this.keyValidator)) {
            if (decripted[this.keyValidator] != 'ordena') {
                return [decripted, token, false];
            }
        }
        return [decripted, token, true];
    },
    vigency(decriptedToken = false, token = false, pass = false) {//verifica la vigencia
        if (token == '' || token == null || token == false || !pass || !decriptedToken) {
            return [false, false, false];
        }

        let now = new Date().getTime() / 1000
        if (decriptedToken.exp <= now) {
            return [decriptedToken, token, false];
        }
        return [decriptedToken, token, true];
    },
    getIsLogged() {
        let [dataToken, token, pass] = this.vigency(...this.getDecodedJwt(...this.existToken()));
        if (!pass || !dataToken) { return false }
        return true;
    },


    /* uniqueid creator */
    async getIPAddress() {
        return new Promise(function(resolve, reject) {
            let ip = "";
            try {

                let rtcPeerConnection = new RTCPeerConnection({ iceServers: [] });

                rtcPeerConnection.createDataChannel('');

                rtcPeerConnection.onicecandidate = function (event) {
                    if (!event || !event.candidate || ip) {
                        rtcPeerConnection.close();
                        resolve(ip);
                        return;
                    }

                    let regex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
                    let ipAddress = '';
                    try{
                        ipAddress = regex.exec(event.candidate.candidate)[0];
                    }catch(err){
                        console.log("Error getting IP address:", err);
                        resolve('');
                    }
                    ip = ipAddress;
                };

                rtcPeerConnection.createOffer().then(function (offer) {
                    rtcPeerConnection.setLocalDescription(offer);
                });
            } catch (e) {
                console.log("Error getting IP address:", e);
                resolve('');
            }
        });
    },

    async generateUniqueID() {
        let currentDate = new Date().getTime();
        let randomNum = Math.floor(Math.random() * 10000);
        
        try {
            let ip = await this.getIPAddress();
            let uniqueID = currentDate.toString() + randomNum.toString() + ip;
            
            let hash = 0;
            for (let i = 0; i < uniqueID.length; i++) {
                let char = uniqueID.charCodeAt(i);
                hash = ((hash << 5) - hash) + char;
                hash = hash & hash; // Convert to 32-bit integer
            }
            
            hash = Math.abs(hash);
            let finalID = hash.toString();
            
            return finalID;
        } catch (error) {
            console.error("Error generating ID:", error);
            return null
        }
    },

    getUnixserviceId(force = false) {
        return new Promise((resolve) => {
            let existingID = localStorage.getItem('crmUnixserviceId');
            if (!existingID || force) {
                this.generateUniqueID().then((newID) => {
                    localStorage.setItem('crmUnixserviceId', newID);
                    resolve(newID)
                }).catch((err) => { console.log(err) });
            }else{
                resolve(existingID)
            }
        }).catch((err) => { console.log(err) })
    }


}
