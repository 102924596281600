<!-- Componente Hijo (SelectNumeric) -->
<template>
    <div ref="selector" class="custom-select" @click="toggleDropdown" :class="isDropdownOpen ? 'opened' : 'closed'" :orient="choosingFilter">
        <div class="selected-option font-7">
            <span class="d-inline-flex overflow-hidden">
                <template v-if="type == 'key'">
                    <template v-if="keyShow == ''">
                        {{ validateEmptyness(value) }}
                    </template>
                    <template v-else-if="list.hasOwnProperty(value)">
                        {{ validateEmptyness(list[value][keyShow]) }}
                    </template>
                    <template v-else>
                        <span class="holder">{{ placeholder }}</span>
                    </template>
                </template>
                <template v-if="type == 'index'">
                    {{ validateEmptyness(list[value]) }}
                </template>
            </span>
            <svg fill="#0066cc" class="icon-select" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M759.2 419.8L697.4 358 512 543.4 326.6 358l-61.8 61.8L512 667z"/></svg>
        </div>
        <div v-if="isDropdownOpen" class="dropdownCustom">
            <div class="relDrop font-7">
                <!-- Opciones del dropdown -->
                <template v-if="type == 'key'">
                    <div class="option" v-for="(option, opkey, opindex) in list" :key="opkey" @click="selectOption($event, opkey)" :class="opkey == value ? 'actived' : ''">
                        <template v-if="keyShow == ''">{{ opkey }}</template><template v-else>{{  option[keyShow] }}</template>
                    </div>
                </template>
                <template v-if="type == 'index'">
                    <div class="option" v-for="(option, opindex) in list" :key="opindex" @click="selectOption($event, opindex)" >
                        {{ option }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "SelectNumeric",
    emits:['input', 'change'],
    props: {
        value: '',
        placeholder:{
            default:'',
            type: String
        },
        list:{
            type: Object,
            default: {}
        },
        keyShow:{
            default:'',
            type: String
        },
        type:{
            default:'key',
            type: String
        }
    },
    data() {
        return {
            choosingFilter: 'down',
            isDropdownOpen: false,
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] // Lista de opciones
        };
    },
    methods: {
        validateEmptyness(value){
            return value == '' ? this.placeholder : value;
        },
        toggleDropdown() {
            const dF = window.innerHeight - this.$refs.selector.getBoundingClientRect().bottom;
			if(dF > 280){
				this.choosingFilter = 'down';
			}else{
				this.choosingFilter = 'up';
			}

            this.isDropdownOpen = !this.isDropdownOpen;
            if(this.isDropdownOpen){
                this.$refs.selector.style.zIndex= 1000;
            }else{
                this.$refs.selector.style.zIndex= 1;
            }
        },
        selectOption(e, option) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            this.isDropdownOpen = false;
            this.$refs.selector.style.zIndex= 1;
            this.$emit('input', option, e);
            this.$emit("change", option, e); // Emitir el evento 'changed' con el nuevo valor seleccionado
        }
    }
};
</script>
<style scoped>
.custom-select {
    position: relative;
    display: inline-block;
    width: 100% ;
    min-height: 65px;
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 20px;
    padding: 0;
    border: 0;
    z-index: 700;
    -ms-overflow-style: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    flex-shrink: 0;
}

.custom-select.closed .selected-option{
    border: 4px solid var(--nonadecagenary);
    border-radius: 16px;
}

.custom-select .holder{
    color: var(--text8);
}


.custom-select.opened .selected-option{
    border: 4px solid var(--nonadecagenary);
}
.custom-select.opened[orient="up"] .selected-option{
    border-top: 4px solid transparent !important;
    border-radius: 0 0 16px 16px;
}
.custom-select.opened[orient="down"] .selected-option{
    border-bottom: 4px solid transparent !important;
    border-radius: 16px 16px 0 0;
}



.selected-option {
    display: flex;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    white-space:nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    font-size: 20px;
    width: 100%;
    height: 100%;
    color: var(--text17);
}

.icon-select {
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top:10px;
    height: 50px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    transition: transform 0.3s ease;
    -ms-overflow-style: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}

path{
    fill:var(--nonadecagenary)
}

.custom-select.opened .icon-select{
    transform: rotate(180deg);
}

.dropdownCustom {
    position: absolute;
    width: 100%;
    left: 0;
    border: 4px solid var(--nonadecagenary);
    background-color: #fff;
    max-height: 206px;
    overflow: hidden;
    -ms-overflow-style: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}

[orient="up"] .dropdownCustom {
    bottom: 94%;
    border-bottom: none;
    border-radius: 16px 16px 0 0;
    padding: 0 0 0 0;
}
[orient="down"]  .dropdownCustom {
    top: 94%;
    border-top: none;
    border-radius: 0 0 16px 16px;
    padding: 0 0 20px 0;
}

.dropdownCustom .relDrop{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 186px;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
    align-items: start;
    align-content: start;
    -ms-overflow-style: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}

.relDrop::-ms-scrollbar {
    display: none;
}
.relDrop::-webkit-scrollbar {
    width: 20px;
}
.relDrop::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.relDrop::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #BE2A34, #D10831);
    height: 62px;
}

.dropdownCustom .option{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    padding: 5px 60px 5px 20px;
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    color: var(--text17);
}

.dropdownCustom .option.actived{
    background-color: var(--sexagenary);
    color: var(--text2);
}

.dropdownCustom .option:hover{
    background-color: var(--nonadecagenary);
    color: var(--text2);
}

.dropdownCustom .option:active{
    background: linear-gradient(45deg, #BE2A34, #D10831);
}
</style>
  