<template lang="html">
    <div class="w-100 d-flex flex-wrap font-1 mt-3 mt-md-5" v-if="articles.length > 0">
        <div class="title ts-1 col-12 text-start fw-bold mt-2 pe-2 ps-lg-5 ps-3">
            {{phrases.title[lang]}}
        </div>
        <div class="subtitle ts-3 col-12 text-start fw-bold mt-0 pe-2 ps-lg-5 ps-3">
            <span class="ps-4">{{phrases.subtitle[lang]}}</span>
        </div>
        <div class="d-flex flex-wrap w-100 align-content-stretch align-items-stretch mt-4 mt-md-5 px-md-5 px-2">
            <div class="d-flex flex-wrap w-100 align-content-stretch align-items-stretch px-0 px-md-0">
                <template v-for="(item, itmdx) in articles">

                    <div v-if="itmdx < 4" :key="itmdx" class="col-6 itemSpace col-xxl-4 d-flex flex-wrap align-items-stretch align-content-stretch mb-md-5 mb-4 px-md-4 px-2" @click="viewItem(item)">
                        <div class="d-flex w-100 flex-wrap align-items-start align-content-start justify-content-start">
                            <div class="col-12 overflow-hidden d-flex align-items-start align-content-start">
                                <div class="rectangleImage placeholder">
                                    <img :src="item.image" :alt="item.name" class="itemImage imgHidden" @load="display($event, false)" @error="display($event, true)">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex w-100 flex-wrap align-items-start align-content-between justify-content-start">
                            <div class="w-100 text-start ps-md-4 pt-2 ts-3 fw-bold itemName text-end text-md-start">{{item.name}}</div>
                            <div class="w-100 text-start ps-md-5 ps-1 pt-md-2 pt-1 ts-3 fw-bold text-end text-md-start">{{$filters.cash4(Number(item.price))}}&nbsp;</div>
                        </div>
                    </div>


                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"topFavorites",
    emits:['event'],
    props:{
        lang:{
            default:"es",
            type: String
        },
        con:{
            default:{},
            type: Object
        },
        cityMenu:{
            default:{},
            type: Object
        },
        user:{
            type:Object,
            default:{}
        }
    },
    data(){
        return{
            a9:require('@/assets/images/a9.png'),
            articles:[],
            phrases:{
                title:{
                    es:"Favoritos Boston's",
                    en:"Boston's Favorites"
                },
                subtitle:{
                    es:"Los favoritos de nuestros fans",
                    en:"Our fans' favorites"
                },
                buttonOrder:{
                    es:"Ordena Ahora",
                    en:"Order Now"
                }
            },
        }
    },
    beforeMount(){
        this.con.getFavorites(this.comprobateUserDir()).then((res)=>{
            if(res.hasOwnProperty('data') && res.hasOwnProperty('code')){
                this.articles = res.data;
            }
        }).catch((err)=>{
            if(err.hasOwnProperty('request')){
                if(err.request.status == 401){
                    this.$parent.$parent.$parent.$parent.$parent.$emit('event', {type:'closeSession', noEraseCar:true});
                }
            }else{
                console.log(err)
            }
        });
    },
    methods:{
        comprobateUserDir(){
            if(this.user.data != null && this.user.token != null){
                let homes = Object.keys(this.user.data.homes);
                if(homes.length == 1){
                    return this.user.token;
                }
            }
            return this.cityMenu.token
        },
        viewItem(item){
            this.$router.push({ path: '/menu/'+item.catUrl+'/'+item.idItem })
        },
        display(e, error=false){
            if(error){
                e.target.src = this.a9
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay', 'error')
            }else{
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay')
            }
		},
        reduceChars(string=""){
            if(string.length>0){
                if(string.length>100){
                    let shorted = string.substring(0, 100);
                    if(string[100] != " "){
                        for(let i = 100; i<string.length; i++){
                            if(string[i] != " "){
                                shorted += string[i];
                                if(i == string.length-1){
                                    return shorted;
                                }
                            }else{
                                shorted += "...";
                                return shorted
                            }
                            
                        }
                    }else{
                        shorted += "...";
                        return shorted
                    }
                }
            }
            return string
        }
    }
}
</script>
<style lang="css" scoped>
    .title{
        color:var(--text4);
        line-height:1.2;
    }
    .subtitle{
        color:var(--text6)
    }
    .itemDescription{
        text-overflow: ellipsis;
        overflow-wrap: break-word;
    }
    /* imagen */


    .rectangleImage{
        border:1px solid var(--octagenary);
        overflow: hidden;
        width:100%;
        height: 200px;
        position: relative;
        border-radius: 17px;
        cursor: pointer;
    }
    .itemSpace:hover .rectangleImage img{
        transform: scale(1.05);
    }
    .itemSpace:hover .itemName{
        color: var(--primary);
    }
    .rectangleImage.placeholder{
        background: #ddd;
        background-size: 800px 104px;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        animation: placeholderer 1s infinite;
    }
    .rectangleImage:not(.placeholder){
        background-color:var(--octagenary);
    }
    .rectangleImage img{
        pointer-events: none;
        position: absolute;
        object-position: center top;
        object-fit: cover;
        object-position: center center;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .imgHidden{
		visibility: hidden;
	}
	.imgDisplay{
		animation: 0.3s fade 1;
	}
	
    .itemImage.error{
        object-position: center center;
        background-color: var(--octagenary);
        object-fit: contain !important;
    }

    @media (max-width: 1199.99px){
        .rectangleImage{
            height: 130px;
        }
    }

    /* fin de imagen */
</style>