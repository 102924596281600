<template lang="html">
    <div ref="carList" class="containCenterExpansed w-100 d-flex flex-wrap justify-content-center align-items-start align-content-start mb-5 mb-md-0">

        <div class="w-100 d-flex justify-content-center justify-content-md-end text-start mt-5" v-if="carItems > 0">
            <div class="col-12">

                
                <div class="categoryArticles w-100 d-flex flex-wrap text-start mb-md-0 mb-3">
                    <div class="categoryName fw-bold font-8 ps-3 mb-4 mt-md-4 mt-0 w-100 py-3">{{phrases.f4[lang]}}</div>
                 </div>

                 <template v-for="(carItemsInCat, carCat) in car.articles">
                
                    <div class="categoryArticles w-100 d-flex flex-wrap text-start mb-md-0 mb-3">

                        <div class="categoryItems w-100 d-flex flex-wrap text-start justify-content-center">

                            <template v-for="(carItem, cidx) in carItemsInCat">

                                <div class="col-11 col-md-11 item d-flex flex-wrap align-content-start align-items-stretch mb-5">
                                    <div class="col-12 col-md-8 d-flex align-items-start align-items-start mb-3 mb-md-0">
                                        <div class="col-6 col-md-4 d-flex flex-wrap align-items-start align-items-start">
                                            <div class="w-100 imageRectangle placeholder">
                                                <img :src="carItem.image" class="item-image imgHidden" @load="display($event, false)"
                                                @error="display($event, true)">
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-8 d-flex flex-wrap align-items-start align-items-start">
                                            <div class="w-100 articleName ps-4 mt-md-4">{{carItem.name}}</div>
                                            <div class="w-100 articleOptions ps-4">
                                                <price-box :options="carItem.options" :nivel="0" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 d-flex flex-wrap justify-content-center align-items-start align-content-start">
                                        <div class="flex-fill articlePrice text-end font-7 mt-1 mt-md-4 pe-md-3 pe-3 ps-3">
                                             {{$filters.cash4(Number(carItem.price) * Number(carItem.quantity))}}
                                        </div>
                                        <div class="selectorQuantity font-7 d-none d-md-flex flex-wrap justify-content-end align-items-start align-content-start w-100 pe-3 mt-0">
                                            <div class="articleQuantity d-flex text-end justify-content-end align-items-center align-content-center">{{phrases.f5[lang]}}&nbsp;{{carItem.quantity}}</div>
                                        </div>
                                        <div class="selectorQuantityLarge font-7 d-flex flex-nowrap d-md-none justify-content-center align-items-start align-content-start">
                                            <div class="articleQuantity d-flex text-end justify-content-end align-items-center align-content-center">{{phrases.f5[lang]}}&nbsp;{{carItem.quantity}}</div>
                                        </div>
                                    </div>
                                </div>

                            </template>

                        </div>

                    </div>

                </template>

            </div>
        </div>

        <div class="w-100 d-flex justify-content-center text-end" v-if="carItems > 0">
            <div class="col-12">
                <div class="w-100 d-flex flex-wrap text-end">
                    <div class="w-100 d-flex flex-wrap ps-0 ps-md-5 pe-0 pe-md-2 justify-content-end">
                        <div class="summaryPricePre w-100 font-7 text-end pe-md-4 pe-0" :class="sumatoryProductsCost[0] == sumatoryProductsCost[1] ? 'normal' : 'remove' ">
                            <span class="color-3" v-if="sumatoryProductsCost[0] == sumatoryProductsCost[1]">{{phrases.f1[lang]}}&nbsp;</span>  $ {{$filters.cash2(Number(sumatoryProductsCost[0]))}}
                        </div>
                        <div class="summaryPriceTotal d-flex flex-wrap justify-content-end align-items-center align-items-center w-100 font-7 text-end pe-md-4 pe-0" v-if="sumatoryProductsCost[0] != sumatoryProductsCost[1]">
                            {{phrases.f1[lang]}} - $ {{$filters.cash2(Number(sumatoryProductsCost[1]))}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
                
    </div>
</template>
<script>

import priceBox from "@/components/responsive/structures/fillStructures/priceBoxCar.vue"
export default {
    name:"carOrder",
    emits:['event'],
    components:{
        priceBox,
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        car:{
            default:{},
            type:Object
        },
        order:{
            default:{},
            type:Object
        }
    },
    computed:{
        carItems(){
            let total = 0;
            for(let i in this.car.articles){
                for(let j in this.car.articles[i]){
                    total += this.car.articles[i][j].quantity;
                }
            }
            return total
        },
        sumatoryProductsCost(){
            let brutePrice = 0;
            for(let i in this.car.articles){
                for(let j in this.car.articles[i]){
                    brutePrice += (this.car.articles[i][j].price * this.car.articles[i][j].quantity);
                }
            }

            let calculatedPrice = brutePrice;

            for(let i in this.car.promos){
                if(this.car.promos[i].type == 'amount'){
                    calculatedPrice -= Number(this.car.promos[i].give)
                }
            }

            return [brutePrice, calculatedPrice]
        }
    },
    methods:{

        display(e, error = false) { //eventos de imagen
            if (error) {
                e.target.src = this.a9
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay', 'error')
            } else {
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay')
            }
        },

    },
    data(){
        return{
            phrases:{
                f1:{
                    es:"Total",
                    en:"Total"
                },
                f2:{
                    es:"Forma de envio:",
                    en:"Send form:"
                },
                f3:{
                    es:"Tipo de pedido:",
                    en:"Order type:"
                },
                f4:{
                    es:"Orden",
                    en:"Order"
                },
                f5:{
                    es:"Cantidad",
                    en:"Quantity"
                }
            },

            loading:false,
            a9: require('@/assets/images/a9.jpg'),
            a47:require('@/assets/images/a47.svg'),
        }
    },
}
</script>
<style lang="css" scoped>
    .containCenterExpansed{
        position: relative;
    }

    /* articles */
    .categoryName{
        font-size: 22px;
        color: var(--text3);
        color: var(--text2);
        background-color: var(--primary);
        border-radius: 7px;
        font-weight: bold;
    }

    .selectorQuantity{
        flex-shrink: 0;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }

    .selectorQuantityLarge{
        height: 40px;
        flex-shrink: 0;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }

    .selectorQuantity .articleAction{
        font-size: 20px;
        color: var(--text12);
        height: 40px;
        white-space: nowrap;
        cursor: pointer;
    }

    .selectorQuantityLarge .articleAction{
        font-size: 20px;
        color: var(--text12);
        width: 50px;
        height: 100%;
        white-space: nowrap;
        cursor: pointer;
    }

    .selectorQuantity .articleQuantity{
        font-size: 20px;
        width: 110px;
        color: var(--text12);
        flex-shrink: 0;
        border-radius: 10px;
        height: 45px;
        white-space: nowrap;
    }

    .selectorQuantityLarge .articleQuantity{
        font-size: 20px;
        color: var(--text12);
        flex-shrink: 0;
        border-radius: 10px;
        height: 100%;
        width: 110px;
        white-space: nowrap;
    }

    .articleName{
        font-size: 19px;
        padding-top: 4px;
        color: var(--text24);
        font-weight: bold;
    }

    .articlePrice{
        padding-top: 2px;
        font-size: 20px;
        color: var(--text7);
        flex: 1;
    }

    .articleOptions{
        font-size: 17px;
        color: var(--text17);
    }


    .summaryPricePre{
        line-height: 1;
        padding-top: 2px;
        color: var(--text4);
    }
    .summaryPricePre.normal{
        color: var(--text4);
        font-size: 35px;
    }
    .summaryPricePre.remove{
        color: var(--cuadecagenary);
        font-size: 35px;
        text-decoration: line-through;
    }
    .summaryPriceTotal{
        line-height: 1;
        padding-top: 2px;
        font-size: 35px;
        color: var(--text4);
    }

    .imageRectangle{
        border-radius: 10px;
        overflow:hidden;
    }

    .imageRectangle.placeholder {
        background: #ddd;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        background-size: 800px 104px;
    }
    
    .imageRectangle.placeholder {
        animation: placeholderer 1s infinite;
    }

    .item-image{
        width: 100%;
        height: 120px;
        object-fit: cover;
        border: 1px dotted rgba(204, 204, 204, 0.5);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
        pointer-events: none;
    }

    .promoBar{
        line-height: 1.3;
        font-size: 20px;
        color: var(--text16);
    }

    .promoBar svg{
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        padding-top: 2px;
    }

    ::v-deep(.promoBar svg path:nth-child(2)){
        fill:var(--primary);
    }

    .imgHidden {
        visibility: hidden;
    }
    

</style>