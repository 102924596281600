import { createRouter, createWebHistory } from 'vue-router'
import auth from '../assets/js/auth.js'
import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'
import AccountDesk from '../views/Account.vue'
import orderDesk from '../views/Order.vue'
import trackerDesk from '../views/Tracker.vue'
import NotFound from '../views/404.vue'

const DEFAULT_TITLE = 'Ordena en linea BOSTONS PIZZA';


const routes = [
    {
        path: '/404', name: 'NotFound', component: NotFound
    },
    {
        path: '/:catchAll(.*)', redirect: '404'
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Ordena Boston's Pizza - Menu en linea", keepAlive: true },
    },
    {
        path: '/account',
        name: 'account',
        component: AccountDesk,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Boston's Pizza - Tu cuenta" },
    },
    {
        path: '/order',
        name: 'order',
        component: orderDesk,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Boston's Pizza - Tu orden" },
    },
    {
        path: '/track',
        name: 'track',
        component: trackerDesk,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Boston's Pizza - Seguimiento" },
    },
    {
        path: '/menu',
        name: 'Menu',
        component: Menu,
        props: true,
        meta: { transition: 'scale', mode: "out-in", requiresAuth: false, title: "Boston's Pizza - Menu en linea", keepAlive: true },
        children: [
            {
                path: "",
                name: "MenuGeneral",
                component: () => import('../components/responsive/structures/MenuGeneral.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: false, requiresCarComprobation: false, keepAlive: true },
            },
            {
                path: ":category",
                name: "category",
                component: () => import('../components/responsive/structures/MenuGeneral.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: false, requiresCarComprobation: false, keepAlive: false },
            },
            {
                path: ":category/:itemId",
                name: "item",
                component: () => import('../components/responsive/itemPage.vue'),
                props: true,
                meta: { transition: 'scale', mode: "out-in", requiresAuth: false, requiresCarComprobation: false, keepAlive: false },
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // siempre vuelve al top de la página
        return { top: 0 };
    }
})

router.beforeEach((to, from) => {

    if (to.meta.requiresAuth && (!auth.getIsLogged() )) {
        return {
            path: '/home',
        }
    }
    else if (to.name === "login" && (auth.getIsLogged()) ) {
        return {
            path: '/home',
        }
    }
})

router.afterEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
