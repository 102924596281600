<template lang="html">
    <div class="unhappyModal modal fade show" ref="unhappyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg px-md-0 px-3">
            <div class="modal-content d-flex justify-content-center align-items-center align-content-center pb-3" v-if="showing">
                <img v-svg-inline :src="a44" class="pt-4">
                <div class="modal-body w-100 d-flex flex-wrap justify-content-center">
                    <div class="w-100 font-7 text-center d-flex justify-content-center mb-3 titled">
                        {{ phrases.f2[lang] }}
                    </div>
                    <div class="w-100 contented font-9 px-md-5 d-flex justify-content-center flex-wrap" v-html="phrases.f3[lang]">
                    </div>
                    <button class="buttonAction mt-5 pushable active font-7 px-4 py-1" @click="hideModal()">{{phrases.f1[lang]}}</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { Modal } from "bootstrap";

export default {
    name:"unhappyModal",
    emits:['close'],
    props:{
        lang:{
            default:"es",
            type: String
        },
        active:{
            default:true,
            type:Boolean
        },
        showIncrement:{
            default:0,
            type:Number
        },
    },
    beforeUnmount(){
        this.modal.hide();
        this.showing = false;
    },
    watch:{
        showIncrement(){
            this.modal.show();
            this.showing = true;
        },
        active(){
            if(this.active){
                this.modal.show();
                this.showing = true;
            }else{
                this.modal.hide();
                this.showing = false;
            }
        }
    },
    mounted(){
        this.modal = new Modal(this.$refs.unhappyModal, {
            backdrop: "static",
            keyboard: false,
        });
        if(this.active){
            this.modal.show();
            this.showing = true;
        }else{
            this.modal.hide();
            this.showing = false;
        }
    },
    data(){
        return{
            a44:require('@/assets/images/a44.svg'),
            modal:null,
            showing:false,
            phrases:{
                f1:{
                    es:"Aceptar y Continuar",
                    en:"Ok, continue"
                },
                f2:{
                    es:"Sin Servicio de Reparto Disponible",
                    en:"No Delivery Service Available"
                },
                f3:{
                    es:"Lo lamentamos, pero aún no llegamos a tu ubicación.<br><br>Puedes continuar con tu pedido y pasarlo a buscar a la sucursal más cercana u ordenar desde Rappi.",
                    en:"We're sorry, but we haven't reached your location yet.<br><br>You can proceed with your order and pick it up from the nearest branch or order through Rappi"
                },
            }
        }
    },
    methods:{
        hideModal(){
            this.$emit('close');
            this.modal.hide();
            this.showing = false;
        }
    }
}
</script>
<style lang="css" scoped>

    .unhappyModal{
        z-index:5000
    }

    .modal-content{
        border: 0 !important;
        background-color: transparent !important;
        
    }

    .titled{
        font-size: 28px;
        color:var(--text7)
    }

    .contented{
        font-size: 18px;
        color:var(--text17)
    }

    .modal-body{
        padding-top: 250px !important;
        padding-bottom: 50px !important;
        background-color: #fff;
        opacity: 0;
        animation: append 1s 1 0.7s forwards;
        border-radius: 0.5rem;
    }

    .unhappyModal .sucessButton{
        width: 50%;
        border: 1px solid var(--undecagenary);
        outline: none;
        background-color: #fff;
        border-radius: 10px;
    }

    .unhappyModal .sucessButton:hover{
        background-color: var(--undecagenary);
        color:#fff;
    }
    .unhappyModal svg {
        position:absolute;
        z-index: 1000;
        height: 200px;
        margin: auto;
        top:calc(50% - 100px);
        width: 200px;
        opacity: 0;
        transform: scale(2);
        animation: translate 0.5s ease-out 0.5s 1 forwards, append 0.5s ease-out 0s 1 forwards;
    }

    .unhappyModal svg circle{
        fill:var(--quinary)
    }

    .unhappyModal svg line{
        stroke:var(--tertiary) !important;
    }

    .buttonAction{
        font-size: 22px;
        background-color: var(--tertiary);
        color:var(--text3);
        border: 2px solid var(--text12);
        outline: none;
        border-radius: 12px;
        pointer-events: none;
        min-width: 250px;
    }

    .buttonAction.off{
        color:var(--text12);
    }

    .buttonAction.pushable{
        pointer-events: all;
        cursor: pointer;
    }

    .buttonAction.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
    }

    .buttonAction.pushable:hover{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
        opacity: 0.7;
    }
</style>