<template lang="html">
    <div ref="carList" class="containCenterExpansed w-100 d-flex flex-wrap justify-content-center align-items-start align-content-start">

        <div class="w-100 ps-lg-5 d-flex flex-wrap align-items-center align-content-center justify-content-end">
            <div class="col-xxl-4 col-lg-6 col-12 col-lg-4 pe-lg-5">
                <div class="col-12 pe-lg-1">
                    <order-sign :socket="socket" :lang="lang"></order-sign>
                </div>
            </div>
        </div>

        <modal-o-k :active="false" :mess="mess" :title="successTitle" :showIncrement="modalSuccess" :lang="lang" :button="successButton" :successEvent="successEvent"></modal-o-k>
        <modal-err :active="false" :mess="mess" :title="errorTitle" :showIncrement="modalError" :lang="lang"></modal-err>
        <loader :active="loading"></loader>

        <modal-order-steps :listStoreStatus="listStoreStatus" :cityMenu="cityMenu" :cities="cities" :con="con" :cartOrderSelection="cartOrderSelection" :lang="lang" :user="user" :transfer="transfer" :prices="sumatoryProductsCost" :articles="car.articles || []" :promos="car.promos || []" @close="closeOrderSteps" @event="filterEvent" @payNoCompleted="payOnlineErr"></modal-order-steps>

        <div class="inner w-100 h-100 d-flex flex-wrap justify-content-center align-items-start align-content-start fitWidth overflow-hidden position-relative">

            <template v-if="true">

                <div class="w-100 d-flex justify-content-center text-start titled ts-2 font-4 fw-bold mt-5 mb-2">
                    <div class="col-xxl-7 col-lg-10 col-11 d-block justify-content-start" v-if="carItems > 0">
                        <span class="col-12 col-md-9 text-start d-block" v-html="phrases.f1[lang]"></span>
                    </div>
                    <div class="col-xxl-7 col-lg-10 col-11 d-block justify-content-start mt-5 px-4" v-else>
                        <span class="col-12 col-md-9 text-start d-block mt-5" v-html="phrases.f3[lang]"></span>
                    </div>
                </div>


                <div class="w-100 d-flex justify-content-center text-start mt-5 ps-md-5 ps-0" v-if="carItems > 0">
                    <div class="col-xxl-7 col-lg-10 col-11">

                        <template v-for="(carItemsInCat, carCat) in car.articles">
                        
                            <div class="categoryArticles w-100 d-flex flex-wrap text-start mb-md-0 mb-3">

                                <div class="categoryName fw-bold font-8 ps-3 mb-4 mt-md-4 mt-0 w-100 py-3">{{carCat}}</div>

                                <div class="categoryItems w-100 d-flex flex-wrap text-start justify-content-center">

                                    <template v-for="(carItem, cidx) in carItemsInCat">

                                        <div class="col-11 col-md-11 item d-flex flex-wrap align-items-start align-content-start mb-5">
                                            <div class="col-12 col-md-8 d-flex align-items-start align-items-start mb-3 mb-md-0">
                                                <div class="col-6 col-md-4 d-flex flex-wrap align-items-start align-items-start">
                                                    <div class="w-100 imageRectangle placeholder">
                                                        <img :src="carItem.image" class="item-image imgHidden" @load="display($event, false)"
                                                        @error="display($event, true)">
                                                    </div>
                                                    <div class="w-100 articleEdit text-center mt-2 font-7" @click="editProduct(carItem.id)">
                                                        {{ phrases.f11[lang] }}
                                                    </div>
                                                   
                                                </div>
                                                <div class="col-6 col-md-8 d-flex flex-wrap align-items-start align-items-start">
                                                    <div class="w-100 articleName ps-4 mt-md-4">{{carItem.name}}</div>
                                                    <div class="w-100 articleOptions ps-4">
                                                        <price-box :options="carItem.options" :nivel="0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 d-flex flex-wrap justify-content-center align-items-start align-items-start">
                                                <div class="flex-fill articlePrice text-end font-7 mt-1 mt-md-4 pe-md-5 pe-3">
                                                    {{$filters.cash4(Number(carItem.price) * Number(carItem.quantity))}}
                                                </div>
                                                <div class="selectorQuantity font-7 d-none d-md-flex flex-wrap justify-content-center align-items-start align-content-start">
                                                    <div class="w-100 articleAction text-center align-items-center align-content-center" @click="manageQ($event, -1, carItem)">-</div>
                                                    <div class="w-100 articleQuantity text-center align-items-center align-content-center">{{carItem.quantity}}</div>
                                                    <div class="w-100 articleAction text-center align-items-center align-content-center" @click="manageQ($event, 1, carItem)">+</div>
                                                </div>
                                                <div class="selectorQuantityLarge font-7 d-flex flex-nowrap d-md-none justify-content-center align-items-start align-content-start">
                                                    <div class="w-100 articleAction text-center align-items-center align-content-center" @click="manageQ($event, -1, carItem)">-</div>
                                                    <div class="w-100 articleQuantity text-center align-items-center align-content-center">{{carItem.quantity}}</div>
                                                    <div class="w-100 articleAction text-center align-items-center align-content-center" @click="manageQ($event, 1, carItem)">+</div>
                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                </div>

                            </div>

                        </template>

                    </div>
                </div>


                <div class="w-100 d-flex justify-content-center text-end" v-if="carItems > 0">
                    <div class="col-xxl-7 col-lg-10 col-11">
                        <div class="w-100 d-flex flex-wrap text-end">
                            <div class="w-100 d-flex flex-wrap ps-0 ps-md-5 pe-0 pe-md-2 justify-content-end">
                                <div class="summaryPricePre w-100 font-7 text-end pe-md-4 pe-0" :class="sumatoryProductsCost[0] == sumatoryProductsCost[1] ? 'normal' : 'remove' ">
                                    $ {{$filters.cash2(Number(sumatoryProductsCost[0]))}}
                                </div>
                                <div class="summaryPriceTotal d-flex flex-wrap justify-content-end align-items-center align-items-center w-100 font-7 text-end pe-md-4 pe-0" v-if="sumatoryProductsCost[0] != sumatoryProductsCost[1]">
                                     $ {{$filters.cash2(Number(sumatoryProductsCost[1]))}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-100 d-flex justify-content-center text-start">
                    <div class="col-xxl-7 col-lg-10 col-11">
                        <div class="w-100 d-flex flex-wrap text-start mt-4">
                            <div v-if="carItems > 0 && sumatoryProductsCost[0] >= 250" class="w-100 d-flex flex-wrap ps-3 ps-md-5 justify-content-end align-items-center align-content-center">
                                <div v-if="sumatoryProductsCost[0] != sumatoryProductsCost[1]" class="promoBar pe-0 pe-md-3 w-100 me-md-3 me-1 d-flex flex-wrap align-items-center align-items-center flex-fill justify-content-end">
                                    <span class="titlePromo w-100 text-end">Promociónes Aplicadas</span>
                                    <template v-for="promo in car.promos">
                                        <template v-if="promo.type == 'amount'">
                                            <div class="w-100 d-flex flex-nowrap align-items-center align-items-center justify-content-end mt-2"><img v-svg-inline :src="a47" class="">{{ promo.name }}</div>
                                        </template>
                                    </template>
                                </div>
                                <button class="buttonCart pushable active font-7 px-5 py-3 mt-4 me-md-4 me-0" @click="processCar()">{{phrases.f2[lang]}}</button>
                            </div>
                            <div v-else-if="carItems > 0 && sumatoryProductsCost[0] < 250" class="w-100 d-flex ps-3 ps-md-5 justify-content-center justify-content-lg-end">
                                <div class="promoBar me-3 d-flex align-items-center align-items-center flex-fill justify-content-end">{{phrases.f12[lang]}} $250 MXN</div>
                                <button class="buttonCart pushable active font-7 px-5 py-3" @click="goToMenu()">{{phrases.f4[lang]}}</button>
                            </div>
                            <div v-else class="w-100 d-flex ps-3 ps-md-5 pe-3 justify-content-center justify-content-lg-start mt-4 mt-md-0">
                                <button class="buttonCart pushable active font-7 px-5 py-3" @click="goToMenu()">{{phrases.f4[lang]}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                
                
            </template>

        </div>
    </div>
</template>
<script>
import orderSign from "@/components/common/orderSign.vue";
import loader from "@/components/common/loader.vue";
import modalOK from "@/components/common/successModal.vue";
import modalErr from "@/components/common/errorModal.vue";
import modalOrderSteps from "@/components/responsive/modalOrderSteps.vue";
import priceBox from "@/components/responsive/structures/fillStructures/priceBoxCar.vue"
export default {
    name:"carOrder",
    emits:['event'],
    components:{
        loader,
        modalOK,
        modalErr,
        modalOrderSteps,
        priceBox,
        orderSign
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        user:{
            type:Object,
            default:{}
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        car:{
            default:{articles:[]},
            type:Object
        }
    },
    computed:{
        carItems(){
            let total = 0;
            for(let i in this.car.articles){
                for(let j in this.car.articles[i]){
                    total += this.car.articles[i][j].quantity;
                }
            }
            return total
        },
        sumatoryProductsCost(){
            let brutePrice = 0;
            for(let i in this.car.articles){
                for(let j in this.car.articles[i]){
                    brutePrice += (this.car.articles[i][j].price * this.car.articles[i][j].quantity);
                }
            }

            let calculatedPrice = brutePrice;

            for(let i in this.car.promos){
                if(this.car.promos[i].type == 'amount'){
                    calculatedPrice -= Number(this.car.promos[i].give)
                }
            }

            return [brutePrice, calculatedPrice]
        }
    },
    beforeCreate(){
        this.$parent.$parent.$parent.$parent.$emit('event', {type:"rechargeCarWithStoreJWT"})
    },
    beforeMount(){
        this.con.getStoreStatusAll().then((res)=>{
            if(res.hasOwnProperty('code')){
                if(res.code == 200){
                    this.listStoreStatus = res.data;
                }
            }
        }).catch((err)=>{})
    },
    mounted(){
        window.scrollTo({
            top: this.$refs.carList.offsetTop -180,
            behavior: 'instant'
        });

        if((this.$route.query.fromDirection || this.$route.query.fromLogin) && this.user.token != null && this.carItems > 0 && this.sumatoryProductsCost[0] >= 250){ // se abre el modal de procesar carrito en tipo de envio y delivery con la direccion del usuario
            this.cartOrderSelection.modalPosition = 'sendType';
            this.cartOrderSelection.sendType.selection = 'delivery';
            this.cartOrderSelection.sendType.value = this.allowDelivery() ? this.user.data.deliveryDir : '';
        }

        this.resetToSendType();
        this.verifyModal();
    },
    methods:{

        payOnlineErr(mess=''){
            this.errorTitle = this.phrases.f13[this.lang];
            this.mess = mess;
            this.modalError += 1;
        },

        validateOrderNowSelectedOpenStore(){
            return new Promise((resolve)=>{

                if(this.user.token != null){

                    if(this.listStoreStatus){
                        resolve(this.validateOpenStoreForNow())
                    }else{
                        this.con.getStoreStatusAll().then((res)=>{
                            if(res.hasOwnProperty('code')){
                                if(res.code == 200){
                                    this.listStoreStatus = res.data;
                                    resolve(this.validateOpenStoreForNow())
                                }
                            }
                        }).catch((err)=>{})
                    }

                }else{
                    resolve(false)
                }

            });
        },

        resetToSendType(){
            if(this.cartOrderSelection.modalPosition != '' && this.cartOrderSelection.modalPosition != 'sendType'){
                this.cartOrderSelection.modalPosition = 'sendType';
            }
            this.cartOrderSelection.schedule.selected = '';
            this.cartOrderSelection.schedule.value = '';
            this.cartOrderSelection.sendType.selected = '';
            this.cartOrderSelection.sendType.value = '';
            this.cartOrderSelection.payForm.selected = '';
            this.cartOrderSelection.payForm.value = '';
            localStorage.setItem('orderSteps', JSON.stringify(this.cartOrderSelection));
        },

        validateOpenStoreForNow(){
            if(this.cartOrderSelection.schedule.selected == 'now' && !this.isOpenStoreSelected()){ // validar siempre que la tienda este abierta en caso se tener pedido como entrega ahora mismo
                this.cartOrderSelection.schedule.selected = '';
                this.cartOrderSelection.schedule.value = null;
                this.cartOrderSelection.sendType.selected == '';
                this.cartOrderSelection.sendType.value == '';
                this.cartOrderSelection.modalPosition = 'sendType'; /*reset todo en caso de tienda cerrada */
                localStorage.setItem('orderSteps', JSON.stringify(this.cartOrderSelection));
                return false
            }
            return true

        },

        isOpenStoreSelected() {
            let storeID = 0;

            if(this.cartOrderSelection.sendType.selected != ''){ // si hay un tipo de envio seleccionado
                switch(this.cartOrderSelection.sendType.selected){
                    case 'delivery': //nombre de direccion del usuario en value
                        storeID = this.user.data.homes.hasOwnProperty(this.cartOrderSelection.sendType.value) ? this.user.data.homes[this.cartOrderSelection.sendType.value].storeNumber : 0;
                        break;
                    case 'pickup': //id de la tienda en value
                        storeID = this.cartOrderSelection.sendType.value;
                        break;
                }

                if(this.listStoreStatus){ // revisa si esta abierta la tienda para permitir el 'hoy'
                    if(this.listStoreStatus.hasOwnProperty(storeID)){
                        if(this.listStoreStatus[storeID].status == 'open'){
                            return true
                        }
                    }
                }
            }

            return false
        },

        display(e, error = false) { //eventos de imagen
            if (error) {
                e.target.src = this.a9
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay', 'error')
            } else {
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay')
            }
        },

        manageQ(e, mult, item){
            switch(mult){
                case -1:
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    if(item.quantity <= 1){
                        this.$parent.$parent.$parent.$parent.$emit('event', {type:"removeToCar", 'id':item.id})
                    }else{
                        this.$parent.$parent.$parent.$parent.$emit('event', {type:"editQuantityIntoCar", 'id':item.id, 'quantity':(--item.quantity)})
                    }
                    break;
                case 1:
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    if(item.quantity >= 5){
                        return false
                    }else{
                        this.$parent.$parent.$parent.$parent.$emit('event', {type:"editQuantityIntoCar", 'id':item.id, 'quantity':(++item.quantity)})
                    } 
                    break;
            }
        },

        filterEvent(event, id='', optionModal='', value='', aditional=''){ //eventos que vienen de modalOrderSteps en sun mayoria
            switch(event){
                case 'resetModal':
                    this.cartOrderSelection.modalPosition = '';
                    break;
                case 'resetChecks':
                    this.cartOrderSelection[optionModal].selected = '';
                    break;
                case 'selectOption':
                    this.cartOrderSelection[optionModal].selected = id;
                    this.cartOrderSelection[optionModal].value = value;
                    break;
                case 'changeModal':

                    if(!this.validateOrderNowSelectedOpenStore()){
                        return false
                    }

                    if(id != '' && id != 'finish'){
                        
                        this.cartOrderSelection.modalPosition = id;
                        localStorage.setItem('orderSteps', JSON.stringify(this.cartOrderSelection));

                    }else if(id == 'finish'){

                        let storeSelected = this.cityMenu.storeNumber;
                        let receiveName = "";
                        if(this.cartOrderSelection.sendType.selected == 'pickup'){
                            storeSelected = this.cartOrderSelection.sendType.value;
                        }
                        if(this.user.dataToken != null){
                            if(this.user.dataToken.data.level == 2){
                                receiveName = this.user.data.name+" "+this.user.data.lastName;
                            }
                        }

                        let request = {
                            token:this.user.token,
                            data:{
                                orden: {
                                    "home": 'casa',
                                    "silverware": 0,
                                    "change":  this.cartOrderSelection.payForm.selected == 'cash' ? this.cartOrderSelection.payForm.value : '000',
                                    "payment": this.cartOrderSelection.payForm.selected == 'cash' ? 1 : (this.cartOrderSelection.payForm.selected == 'card') ? 2 : 3,
                                    "paymentType": this.cartOrderSelection.payForm.selected,
                                    "transactionID": aditional,
                                    "coupon": "",
                                    "sent_at": this.cartOrderSelection.schedule.value,
                                    "storeNumber": storeSelected,
                                    "deliveryType": this.cartOrderSelection.sendType.selected,
                                    "deliveryComment": "",
                                    "deliveryClientReceiver": receiveName,
                                    "products": this.car.articles
                                }
                            }
                        }
                        this.loading = true;
                        this.con.placeOrder(request).then((res)=>{

                            this.loading = false;
                            document.body.classList.remove('modalOpen');

                            if(res.code == 200){

                                if(res.data.currentStore.open != 0){

                                    try{
                                        this.con.sendError({
                                            system: "ordena bpt",
                                            type: 'error',
                                            info: 'caso 1',
                                            data: JSON.stringify(res.data),
                                            aditional: 'cart.vue',
                                            licence: 0,
                                            id: 0,
                                        }).then(()=>{}).catch((err)=>{})
                                        mouseflow.tag("orden_exitosa", String(this.sumatoryProductsCost[0]));
                                    }catch(err){}

                                    this.successTitle = this.phrases.f6[this.lang];
                                    this.mess = this.phrases.f7[this.lang];
                                    this.modalSuccess += 1;
                                    this.successEvent = 1;
                                    localStorage.setItem(res.data.order.uniqueid, JSON.stringify(this.car))
                                    this.$parent.$parent.$parent.$parent.$emit('event', {type:"removeCar"});
                                    this.cartOrderSelection.modalPosition = '';
                                    localStorage.removeItem('orderSteps'); //al eliminar el proceso actual del modal wizard de orden
                                }else{

                                    try{
                                        this.con.sendError({
                                            system: "ordena bpt",
                                            type: 'error',
                                            info: 'caso 2',
                                            data: JSON.stringify(res.data),
                                            aditional: 'cart.vue',
                                            licence: 0,
                                            id: 0,
                                        }).then(()=>{}).catch((err)=>{})
                                        mouseflow.tag("orden_fallida", String(res.data.currentStore.message));
                                    }catch(err){}

                                    this.errorTitle = this.phrases.f10[this.lang];
                                    this.mess = res.data.currentStore.message;
                                    this.modalError += 1;
                                }
                            }else{

                                try{
                                    this.con.sendError({
                                        system: "ordena bpt",
                                        type: 'error',
                                        info: 'caso 3',
                                        data: JSON.stringify(res.code),
                                        aditional: 'cart.vue',
                                        licence: 0,
                                        id: 0,
                                    }).then(()=>{}).catch((err)=>{})
                                    mouseflow.tag("orden_fallida", String(res.msj));
                                }catch(err){}

                                this.errorTitle = this.phrases.f10[this.lang];
                                this.mess = res.msj;
                                this.modalError += 1;
                            }
                        }).catch((err)=>{

                            this.loading = false;

                            try{

                                this.con.sendError({
                                    system: "ordena bpt",
                                    type: 'error',
                                    data: err.toString() + " # " + extractErrorLine(err.stack),
                                    info: 'caso 4',
                                    aditional: 'cart.vue',
                                    licence: 0,
                                    id: 0,
                                }).then(()=>{}).catch((err)=>{})
                                mouseflow.tag("orden_fallida", String(this.sumatoryProductsCost[0]));
                            }catch(err){}

                            if(err.hasOwnProperty('request')){
                                if(err.request.status == 401){
                                    this.$parent.$parent.$parent.$parent.$emit('event', {type:'closeSession', noEraseCar:true});
                                }else{
                                    localStorage.removeItem('orderSteps');
                                    this.cartOrderSelection.modalPosition = '';
                                    this.errorTitle = this.phrases.f8[this.lang];
                                    this.mess = this.phrases.f9[this.lang];
                                    this.modalError += 1;
                                }
                            }else{
                                localStorage.removeItem('orderSteps');
                                this.cartOrderSelection.modalPosition = '';
                                this.errorTitle = this.phrases.f8[this.lang];
                                this.mess = this.phrases.f9[this.lang];
                                this.modalError += 1;
                            }

                        });
                    }
                    break;

                case 'editDirections':

                    localStorage.setItem('orderSteps', JSON.stringify(this.cartOrderSelection));
                    this.$router.push({
                        name: 'account',
                        query: { fromCar: true },
                    });
                    break;

                default:
                    break;
            }
            
        },

        closeOrderSteps(){
            this.cartOrderSelection.modalPosition = '';
            this.cartOrderSelection.schedule.selected = '';
            this.cartOrderSelection.schedule.value = '';
            this.cartOrderSelection.sendType.selected = '';
            this.cartOrderSelection.sendType.value = '';
            this.cartOrderSelection.payForm.selected = '';
            this.cartOrderSelection.payForm.value = '';
            localStorage.removeItem('orderSteps');
        },

        editProduct(id){
            for(let i in this.car.articles){
                for(let j in this.car.articles[i]){
                    if(id == this.car.articles[i][j].id){

                        this.$router.push({
                            name: 'item',
                            params: { category:this.car.articles[i][j].catUrl, itemId:this.car.articles[i][j].baseId }, // Ajusta el nombre del parámetro según tu configuración
                            query: { fromCar: id },
                        });
                        break;
                    }
                }
            }
        },

        async processCar(){
            
            if(this.user.dataToken != null){
                this.cartOrderSelection.modalPosition = 'sendType';
            }else{
                localStorage.removeItem('orderSteps');
                this.$router.push('/account')
            }
        },
        goToMenu(){
            if(this.$route.name != 'menu'){
                this.$router.push({ path: '/menu' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        allowDelivery(){
            if(this.user.token != null){
                if(this.user.data.deliveryDir != '' && this.user.data.deliveryDir != undefined){
                    if(this.user.data.homes.hasOwnProperty(this.user.data.deliveryDir)){
                        return true
                    }
                }
            }
            return false
        },
        verifyModal(){
            if(this.cartOrderSelection.modalPosition != '' && this.user.token != null){
                document.body.classList.add('modalOpen')
            }else{
                document.body.classList.remove('modalOpen')
            }
        }
    },
    watch:{
        cartOrderSelection:{
            handler: function (val, oldVal) {
                this.verifyModal();
            },
            deep: true
        }
    },
    data(){
        return{
            
            loading:false,
            a9: require('@/assets/images/a9.jpg'),
            a47:require('@/assets/images/a47.svg'),
            mess:'',
            successTitle:"",
            modalSuccess:0,
            successEvent:0,
            successButton:'OK',
            errorTitle:"",
            modalError:0,

            cartOrderSelection: localStorage.orderSteps ? JSON.parse(localStorage.orderSteps) : {
                modalPosition:'',
                sendType:{
                    selected: '',
                    value:this.allowDelivery() ? this.user.data.deliveryDir : ''
                },
                schedule:{
                    selected:'',
                    value:''
                },
                payForm:{
                    selected:'',
                    value:'' 
                },
                cashMode:{
                    selected:'cash',
                    value:'' 
                },
                onlineMode:{
                    selected:'paypal',
                    value:'default' 
                },
                storeClosed:{
                    selected:'',
                    value:'' 
                },
                storeError:{
                    selected:'',
                    value:'' 
                },
                
            },

            listStoreStatus:{},

            phrases:{
                f1:{
                    es:"Listos para <br> Ordenar",
                    en:"Added items"
                },
                f2:{
                    es:"Ordenar",
                    en:"Order"
                },
                f3:{
                    es:"Tu carrito está vacío",
                    en:"Your cart is empty"
                },
                f4:{
                    es:"Ir al Menu",
                    en:"Go to Menu"
                },
                f5:{
                    es:"Eliminar",
                    en:"Remove"
                },
                f6:{
                    es:"Orden en Proceso",
                    en:"Order Active"
                },
                f7:{
                    es:"Tu selección llegó a la sucursal",
                    en:"Your order is at the store"
                },
                f8:{
                    es:"Ha ocurrido un pequeño error",
                    en:"A little error"
                },
                f9:{
                    es:"Inténtelo de nuevo en un tiempo considerado",
                    en:"Try again later"
                },
                f10:{
                    es:"La orden no pudo procesarse",
                    en:"The order could not be processed"
                },
                f11:{
                    es:"Editar",
                    en:"Edit"
                },
                f12:{
                    es:"Compra mínima",
                    en:"Minimum amount"
                },
                f13:{
                    es:"Ha ocurrido un error en el pago con Paypal",
                    en:"Something bad occurred in Paypal payment"
                }
            }
        }
    },
}
</script>
<style lang="css" scoped>
    .containCenterExpansed{
        min-height: calc(100vh - 118px);
        min-width: 100vw;
        position: relative;
    }
    .inner{
        padding-bottom: 150px;
        min-height: calc(100vh - 118px);
    }

    .titled{
        color: var(--primary);
        line-height: 1.2;
    }
    .separator{
        border-top:1px solid var(--ondecagenary);
        box-shadow: 0.5px 0.5px 0.5px rgba(200, 200, 200, 0.5);
    }

    .itemSpace{
        cursor: pointer;
    }


    .categoryArticles .itemSpace:last-child .separator{
        display: none !important;
    }

    /* articles */
    .categoryName{
        font-size: 22px;
        color: var(--text3);
        color: var(--text2);
        background-color: var(--primary);
        border-radius: 7px;
        font-weight: bold;
    }

    .article div{

    }

    .selectorQuantity{
        width: 90px;
        border: 4px solid var(--sexagenary);
        flex-shrink: 0;
        border-radius: 10px;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }

    .selectorQuantityLarge{
        width: 170px;
        height: 40px;
        border: 2px solid var(--sexagenary);
        flex-shrink: 0;
        border-radius: 10px;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }

    .selectorQuantity .articleAction{
        font-size: 20px;
        color: var(--text12);
        height: 40px;
        white-space: nowrap;
        cursor: pointer;
    }

    .selectorQuantityLarge .articleAction{
        font-size: 20px;
        color: var(--text12);
        width: 50px;
        height: 100%;
        white-space: nowrap;
        cursor: pointer;
    }

    .articleAction:hover{
        color: var(--text2);
        background-color: var(--text11);
    }

    .articleAction:active{
        background-color: var(--text14);
    }

    .selectorQuantity .articleQuantity{
        font-size: 20px;
        color: var(--text12);
        border-top: 4px solid var(--sexagenary);
        border-bottom: 4px solid var(--sexagenary);
        height: 45px;
        white-space: nowrap;
    }

    .selectorQuantityLarge .articleQuantity{
        font-size: 20px;
        color: var(--text12);
        border-left: 2px solid var(--sexagenary);
        border-right: 2px solid var(--sexagenary);
        height: 100%;
        width: 50px;
        white-space: nowrap;
    }

    .articleName{
        font-size: 19px;
        padding-top: 4px;
        color: var(--text24);
        font-weight: bold;
    }

    .articlePrice{
        padding-top: 2px;
        font-size: 20px;
        color: var(--text7);
        flex: 1;
    }

    .articleOptions{
        font-size: 17px;
        color: var(--text17);
    }

    .articleErase{
        padding-top: 7px;
        font-size: 14px;
        color: var(--text16);
        width: 80px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .articleErase:hover{
        color: var(--text7);
        filter: contrast(0.9);
    }

    .summaryPricePre{
        line-height: 1;
        padding-top: 2px;
        color: var(--text4);
    }
    .summaryPricePre.normal{
        color: var(--text4);
        font-size: 35px;
    }
    .summaryPricePre.remove{
        color: var(--cuadecagenary);
        font-size: 35px;
        text-decoration: line-through;
    }
    .summaryPriceTotal{
        line-height: 1;
        padding-top: 2px;
        font-size: 35px;
        color: var(--text4);
    }

    .buttonCart{
        font-size: 25px;
        background-color: var(--tertiary);
        color:var(--text3);
        border: 2px solid var(--text12);
        outline: none;
        border-radius: 15px;
        line-height: 1.2;
        pointer-events: none;
    }

    .buttonCart.off{
        color:var(--text12);
    }

    .buttonCart.pushable{
        pointer-events: all;
        cursor: pointer;
    }

    .buttonCart.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
    }

    .buttonCart.pushable:hover{
        filter: contrast(0.9)
    }

    .imageRectangle{
        border-radius: 10px;
        overflow:hidden;
    }

    .imageRectangle.placeholder {
        background: #ddd;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        background-size: 800px 104px;
    }
    
    .imageRectangle.placeholder {
        animation: placeholderer 1s infinite;
    }

    .item-image{
        width: 100%;
        height: 120px;
        object-fit: cover;
        border: 1px dotted rgba(204, 204, 204, 0.5);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
        pointer-events: none;
    }

    .articleEdit{
        cursor: pointer;
        font-size: 18px;
        color: var(--text12);
    }

    .articleEdit:hover{
        color: var(--primary);
    }

    .promoBar{
        line-height: 1.3;
        font-size: 20px;
        color: var(--text16);
    }

    .promoBar svg{
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        padding-top: 2px;
    }

    ::v-deep(.promoBar svg path:nth-child(2)){
        fill:var(--primary);
    }

    .imgHidden {
        visibility: hidden;
    }

    .titlePromo{
        font-weight: 600;
    }
    

</style>