<template>

    <div class="home d-flex flex-wrap justify-content-center">

        
        <city-selector :cities="cities" :cityMenu="cityMenu" :lang="lang" v-if="citySelectModal" @close="closeSelectCity()"></city-selector>
        <portraits :con="con" :cityMenu="cityMenu" :lang="lang" :transfer="transfer" :user="user" ></portraits>
        <home-articles :categories="categories" :socket="socket" :cities="cities" :cityMenu="cityMenu" :user="user" :transfer="transfer" :con="con" :lang="lang" @changeCity="changeCity()" :lastPosition="lastPosition"></home-articles>

    </div>

</template>

<script>
import portraits from "@/components/layout/portrait.vue";
import HomeArticles from "@/components/responsive/homeArticles.vue";
import citySelector from "@/components/responsive/citySelector.vue";
export default {
    name: "Home",
    emits:['event'],
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        view:{
            default:"responsive",
            type: String
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        car:{
            default:{},
            type:Object
        }
    },
    data(){
        return{
            categories:[],
            citySelectModal:false,
        }
    },
    components:{
        portraits,
        citySelector,
        HomeArticles
    },
    methods:{
        comprobateUserDir(){
            if(this.user.data != null && this.user.token != null){
                let homes = Object.keys(this.user.data.homes);
                if(homes.length == 1){
                    return {token:this.user.token, store:null}
                }
            }
            return {token:this.cityMenu.token, store:this.cityMenu.storeNumber}
        },
        changeCity(){
            this.citySelectModal = true;
        },
        closeSelectCity(){
            this.citySelectModal = false;
        },
        getCategories(){
            return new Promise((resolve, reject) => {
                this.con.getCategories({token:this.cityMenu.token}).then((res)=>{
                    this.categories = res.data;
                    resolve(true)
                }).catch((err)=>{
                    reject(err)
                })
            })
            
        },
    },
    mounted(){
        this.getCategories();
    },
    activated(){

    },
};
</script>

<style>
    .home{
        width: 100%;
        height: auto;
    }
</style>
