<template>
    <template v-for="(option, index) in options" :key="index">
        <template v-if="hasSelectedInfo(option, index)">
            <div class="optionSelected w-100 ps-3 d-flex py-2 flex-wrap align-items-stretch align-content-start"
                v-if="option.type == 'item'">
                <span class="w-100 titleOption font-7">+&nbsp;{{ formatOptionName(option.sysName || option.description || 'Opción seleccionada') }} <div v-if="option.price != 0 && option.price != null && option.price != undefined && option.price != '0' && option.price != ''">&nbsp; {{$filters.cash4(Number(option.price ))}}</div></span>
            </div>
        </template>
        <template v-if="hasNestedOptions(option)">
            <price-box :options="option" :nivel="nivel + 1"  />
        </template>
    </template>
</template>
  
<script>
export default {
    name: "priceBox",
    props: {
        options: {
        },
        nivel: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            total: 0,
        };
    },
    methods: {
        formatOptionName(name=""){

            if (name.trim() === '') {
                return '';
            }

            const formated = name.trim().toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
            return formated;
        },
        hasSelectedInfo(option, name = "") {
            if (typeof option === 'object' && name == 'info') {
                if (option.selected === 1) {
                    return true;
                } else if (option.selected === 0) {
                    return false;
                }
            }
            return false;
        },
        hasNestedOptions(option) {
            return typeof option === 'object' && option !== null && (Array.isArray(option) || !Array.isArray(option));
        },
    },
};
</script>
  
<style scoped>
.priceOption {
    color: var(--text11);
    font-size: 21px;
}

.titleOption {
    font-size: 17px;
    color: var(--text17);
}

.optionSelected:not(:last-of-type) {
    /*border-bottom: 1px solid var(--vigenary);*/
}
</style>
  