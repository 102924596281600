<template lang="html">
    <div id="citiesList">
        <div class="rel d-flex justify-content-center align-items-start align-content-start">
            <div class="floatRectangle mt-5">
                <div class="rel d-flex flex-wrap justify-content-start align-items-center align-content-center px-4 px-md-5 py-5">
                    <span class="w-100 font-3 titleSelect text-start ts-11">{{phrases.f1[lang]}}</span>
                    <div class="selectSpace mt-3 px-0 py-0 d-flex">
                        <span class="align-items-center align-content-center justify-content-center"><img :src="a11" v-svg-inline ></span>
                        <div class="custom-select flex-fill">
                            <div class="selected-option h-100 ps-3 font-2 ts-12 py-2 text-start d-flex align-items-center align-content-center" :class="selectedOption != null ? 'selected' : ''" @click="toggleDropdown" v-text="selectedOption != null ? cities[selectedOption].friendlyName : phrases.f3[lang] ">
                            </div>
                        </div>
                        <button class="selectButton d-none d-md-flex ts-12 d-flex align-items-center align-content-center" @click="changeCity()">{{phrases.f2[lang]}}</button>
                    </div>
                    <div class="w-100 px-0 py-0 d-flex position-relative">
                        <div v-if="isCustomDropdownOpen" class="custom-dropdown">
                            <div v-for="(city, cityPos) in cities" :key="cityPos" @click="selectOption(cityPos)" class="text-start ps-icon py-3 font-5 ">
                              {{ city.friendlyName }}
                            </div>
                          </div>
                    </div>
                    <button class="selectButton d-flex d-md-none ts-12 mt-4 rounded mb-3 align-items-center align-content-center" @click="changeCity()">{{phrases.f2[lang]}}</button>
                   
                    <div @click="close()" class="closeCities font-7 d-flex align-items-center align-content-center justify-content-center" role="button">
                        <img v :src="a10" v-svg-inline>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {    
    name:"citySelector",
    emits:['close', 'event'],
    props:{
        lang:{
            default:"es",
            type: String
        },
        cities:{
            default:[],
            type: Array
        },
        cityMenu:{
            default:{},
            type: Object
        },
    },
    methods:{
        toggleDropdown() {
            this.isCustomDropdownOpen = !this.isCustomDropdownOpen;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.isCustomDropdownOpen = false;
        },
        close(){
            this.$emit('close')
        },
        changeCity(){
            if(this.selectedOption != null){
                this.$parent.$emit('event', {type:'changeCity', city:this.cities[this.selectedOption]})
                this.$emit('close')
            }else{
                this.close()
            }
        },
    },
    data(){
        return{
            isCustomDropdownOpen: false,
            selectedOption: null,
            a10:require('@/assets/images/a10.svg'),
            a11:require('@/assets/images/a11.svg'),
            phrases:{
                f1:{
                    es:"Selecciona tu ciudad",
                    en:"Select your city"
                },
                f2:{
                    es:"Continuar",
                    en:"Continue"
                },
                f3:{
                    es:"Ciudad de entrega",
                    en:"City of service"
                }
            }
        }
    }
}
</script>
<style lang="css" scoped>
    #citiesList{
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(0px);
        z-index: 4000;
        position: fixed;
        top:0;
        left:0;
        animation:appear 0.2s linear 1 forwards;
    }
    .rel{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .floatRectangle{
        width: 700px;
        max-width: 90%;
        min-height: 300px;
        background-color: #fff;
        position: absolute;
        align-content: center;
        align-items:center;
        animation:append 0.4s linear 1 forwards;
    }
    .titleSelect{
        color:var(--primary);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .selectSpace{
        width: 100vw;
        height: 70px;
        border:3px solid var(--text10);
        box-sizing: content-box;
    }
    .selectSpace svg{
        width: 30px;
    }
    .ps-icon{
        padding-left: calc( 80px + 1rem);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .selectSpace .custom-select{
        height: 100%;
        background-color: transparent;
        padding: 10px;
        color: var(--text10);
    }
    .selectSpace .custom-select .selected-option.selected{
        color: var(--primary) !important;
    }
    .selectSpace span{
        display: flex;
        width: 70px;
        height: 70px;
        color: var(--text10);
    }
    .selectButton{
        padding: 4px 20px 7px 20px;
        background: var(--primary);
        color:var(--text2);
        font-weight: bold;
        border: 0;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .selectButton:hover{
        opacity: 0.7;
    }
    .closeCities{
        position: absolute;
        width: 50px;
        background-color: transparent;
        height: 50px;
        right: 10px;
        padding-bottom: 10px;
        font-size: 40px;
        color:var(--text10);
        top: 10px;
    }
    .closeCities svg{
        height: 25px;
        pointer-events: none
    }
    @keyframes append {
        0%{
            transform: translateY(-150%);
            opacity: 0.3;
        }
        90%{
            transform: translateY(20%);
        }
        100%{
            transform: translateY(0%);
            opacity: 1;
        }
    }
    @keyframes appear {
        0%{
            backdrop-filter: blur(0px);
        }
        100%{
            backdrop-filter: blur(8px);
        }
    }


    .custom-select {
        position: relative;
        width: 200px; /* Ajusta según tus necesidades */
    }
    
    .selected-option {
        font-size: 18px;
        border: 0;
        cursor: pointer;
    }
    
    .custom-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border-bottom:3px solid var(--text10);
        border-left:3px solid var(--text10);
        border-right:3px solid var(--text10);
    }
    
    .custom-dropdown div {
        cursor: pointer;
        color: var(--primary);
        background-color: var(--octagenary);
        border-bottom: 3px solid var(--text10);
    }
    
    .custom-dropdown div:last-child {
        border-bottom: none;
    }
    
    .custom-dropdown div:hover {
        background-color: #ddd;
    }
</style>