<template>
    <div>
        <div ref="paypalButtonContainer"></div> <!-- Usamos ref en lugar de id -->
    </div>
</template>

<script>
export default {
    name: 'PayPalButton',
    emits:['payCompleted', 'payNoCompleted'],
    props: {
        amount: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.renderPayPalButton();
    },
    watch: {
        // Vuelve a renderizar el botón si cambia el valor de `amount`
        amount() {
            this.renderPayPalButton();
        }
    },
    methods: {
        renderPayPalButton() {
            try{
                // Limpiar el contenedor antes de renderizar el nuevo botón
                this.$refs.paypalButtonContainer.innerHTML = '';

                window.paypal.Buttons({
                    fundingSource: window.paypal.FUNDING.PAYPAL, // Solo el botón de PayPal

                    style: {
                        layout: 'vertical',  // 'vertical' o 'horizontal'
                        color: 'blue',       // Colores: 'gold', 'blue', 'silver', 'black'
                        shape: 'rect',       // Formas: 'rect' o 'pill'
                        label: 'paypal',     // Texto del botón: 'checkout', 'pay', 'buynow', 'paypal'
                        height: 55           // Tamaño del botón en píxeles
                    },
            
                    createOrder: (data, actions) => {
                        // Crea la orden de pago con el monto dinámico
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: this.amount, // El valor dinámico pasado por props
                                    currency_code: 'MXN' // Define la moneda como Pesos Mexicanos
                                }
                            }],
                            application_context: {
                                shipping_preference: 'NO_SHIPPING' // Desactiva la opción de envío
                            }
                        });
                    },
                    onApprove: (data, actions) => {
                        return actions.order.capture().then((details) => {
                            this.$emit('payCompleted', details.id)
                        });
                    },
                    onError: (err) => {
                        this.$parent.$emit('payNoCompleted', err.toString())
                    }
                }).render(this.$refs.paypalButtonContainer); // Renderizamos en el ref
            }catch(err){}
        }
    }
};
</script>

<style scoped>
/* Estilos opcionales */
#paypal-button-container {
    margin: 20px 0;
}
</style>