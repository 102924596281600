<template lang="html">
    <div class="w-100 py-2 d-flex justify-content-center align-items-center align-content-center stick-notify">

        <div class="order-letter d-flex align-items-stretch align-content-stretch mt-4 mt-lg-0 mb-lg-1" v-if="orders.length > 0" @click="toTracks()">

            <template v-if="orders.length == 1">
                <div class="col-lg-8 col-9 d-flex flex-wrap align-items-center align-content-center" :class="orders[0].orderType == 'placed' || (orders[0].orderType == 'scheduled' &&  orders[0].status >= 2) ? 'pb-specific' : ''" title="ver mis ordenes">

                    <div class="w-100 text-start ps-lg-4 ps-3 label-1" v-if="!waitingRemount">
                        <template v-if="orders[0].orderType == 'placed' && orders[0].status == 1">
                            {{ phrases.f4[lang] }}&nbsp;{{ phrases.f8[lang] }}
                        </template>
                        <template v-else-if="orders[0].orderType == 'scheduled' && orders[0].status == 1">
                            {{ phrases.f4[lang] }}&nbsp;{{ phrases.f2[lang] }}
                        </template>
                        <template v-else-if="orders[0].status == 2">
                            {{ phrases.f4[lang] }}&nbsp;{{ phrases.f1[lang] }}
                        </template>
                        <template v-else-if="orders[0].status == 3 && itsGoTime(orders[0].go, Number(orders[0].status)) == 1">
                            {{ phrases.f4[lang] }}&nbsp;{{ phrases.f5[lang] }}
                        </template>
                        <template v-else-if="orders[0].status == 3 && itsGoTime(orders[0].go, Number(orders[0].status)) == 2">
                            {{ phrases.f6[lang] }}
                        </template>
                        <template v-else-if="orders[0].status == 4">
                            {{ phrases.f4[lang] }}&nbsp;{{ phrases.f7[lang] }}
                        </template>
                    </div>

                    <div class="w-100 text-start ps-lg-5 ps-4 label-2">
                        
                        <template v-if="orders[0].orderType == 'placed'">
                            {{getEstimatedTime(orders[0].deliveryType, orders[0].placement, orders[0].orderType, orders[0].status)}}
                        </template>
                        <template v-else>
                            {{phrases.f9[lang]}}&nbsp;{{formatDate(orders[0].sent_at)}}
                        </template>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-3 d-flex align-items-center align-content-center justify-content-end pe-lg-4 pe-4 pe-2">
                    <div class="divisor"></div><img v-svg-inline :src="a50" class="icon-alert">
                </div>
                <div class="line-anim" v-if="orders[0].orderType == 'placed' || (orders[0].orderType == 'scheduled' &&  orders[0].status >= 2)">
                    <div class="line" v-if="!waitingRemount">
                        <div class="rectangle expand" :class="orders[0].status >= 2 ? (orders[0].status < 3 ? 'active last' : 'active') : 'pending'"></div>
                        <div class="rectangle" :class="orders[0].status >= 3 && itsGoTime(orders[0].go, Number(orders[0].status)) > 0  ? (itsGoTime(orders[0].go, Number(orders[0].status)) == 1 ? 'active last' : 'active') : 'pending'"></div>
                        <div class="rectangle" :class="orders[0].status >= 3 && itsGoTime(orders[0].go, Number(orders[0].status)) > 1  ? (orders[0].status < 4 ? 'active last' : 'active') : 'pending'"></div>
                        <div class="rectangle" :class="orders[0].status >=4 ? 'active' : 'pending'"></div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col-lg-8 col-9 d-flex flex-wrap align-items-center align-content-center" title="ver mis ordenes">
                    <div class="w-100 text-start ps-lg-4 ps-3 label-1">
                        {{ phrases.f10[lang] }}&nbsp;{{ orders.length }}
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>
<script>
export default {
    name:"orderSign",
    props:{
        lang:{
            default:"es",
            type: String
        },
        socket:{
            default:{},
            type: Object
        },
    },
    mounted(){
        if(this.socket.wsOn){
            this.updateOrders();
        }else{
            this.orders = [];
        }
    },
    watch:{
        'socket.tray':{
            handler(newValue) {
                if(this.socket.tray.destiny.includes(this.$options.name)){
                    this.filterEvent(newValue);
                }
            },
            deep: true // Para observar cambios profundos en la instancia
        },
    },
    data(){
        return{
            waitingRemount:false,
            remountTimer : setTimeout(()=>{}, 100),
            remountTimerRunned: false,
            a50:require('@/assets/images/a50.svg'),
            orders:[],
            phrases:{
                f1:{
                    es:"en Preparación",
                    en:"in Preparation"
                },
                f2:{
                    es:"Programada",
                    en:"Scheduled"
                },
                f3:{
                    es:"Calculando Tiempo...",
                    en:"Calculating Time..."
                },
                f4:{
                    es:"Orden",
                    en:"Order"
                },
                f5:{
                    es:"en Empaque",
                    en:"in Packaging"
                },
                f6:{
                    es:"Proceso de Entrega",
                    en:"Delivery Process"
                },
                f7:{
                    es:"Completada",
                    en:"Completed"
                },
                f8:{
                    es:"Recibida",
                    en:"Received"
                },
                f9:{
                    es:"Llegando",
                    en:"Arriving"
                },
                f10:{
                    es:"Ordenes Enviadas",
                    en:"Orders Sended"
                },
                f11:{
                    es:"Finalizando",
                    en:"Finishing"
                }
            }
        }
    },
    methods:{
        toTracks(){
            this.$router.push('/track')
        },
        filterEvent(event){
            switch(event.name){
                case 'updateOrders':
                    this.updateOrders();
                    break;
                case 'closedWS':
                    if(this.socket.wsOn){
                        this.updateOrders();
                    }else{
                        this.orders = [];
                    }
                    break;
            }
        },
        updateOrders(){
            if(this.socket.userData.hasOwnProperty('orders')){
                this.orders = this.socket.userData.orders;
            }
        },
        formatDate(date){
            if(!this.validateDate(date)){
                return date
            }else{
                let dated = new Date(date);
                return dated.toLocaleString('es-MX', {weekday:"long", day:"2-digit", hour: '2-digit', minute:'2-digit'});
            }
        },
        itsGoTime(date, status){
            if(!this.validateDate(date)){
                return 0
            }
            if(status > 3){
                return 2
            }
            let dated = new Date(date);
            const now = new Date().getTime();
            const passedTime = dated.getTime();
            const difference = now - passedTime;
            const minutesPassed = Math.floor(difference / (1000 * 60));
            if(minutesPassed >= 5){
                return 2
            }else{
                this.remount((5 - minutesPassed) * 60000)
                return 1
            }
        },
        remount(time){     
            if(!this.remountTimerRunned){
                this.remountTimerRunned = true;
                clearTimeout(this.remountTimer)
                this.remountTimer = setTimeout(()=>{
                    this.remountTimerRunned = false;
                    this.waitingRemount = true;
                    setTimeout(()=>{
                        this.waitingRemount = false;
                    }, 300)
                }, time)
            }
        },
        validateDate(date){
            let dated = new Date(date);
            if (isNaN(dated)) {
                return false;
            }
            return true
        },
        getEstimatedTime(type, value, form, status=0){
            try{
                let dated = new Date(value);

                if (!this.validateDate(value)) {
                    return this.phrases.f3[this.lang];
                }

                if(form == 'placed' && status != 4){

                    switch(type){
                        case "home":
                            dated.setMinutes(dated.getMinutes() + 40);
                            return this.phrases.f9[this.lang]+' '+dated.toLocaleString('es-MX', {weekday:"long", day:"2-digit", hour: '2-digit', minute:'2-digit'});
                        case "pickup":
                            dated.setMinutes(dated.getMinutes() + 15);
                            return this.phrases.f11[this.lang]+' '+dated.toLocaleString('es-MX', {weekday:"long", day:"2-digit", hour: '2-digit', minute:'2-digit'});
                            break;
                    }
                }else{
                    return ''
                }
            }catch(err){}
        }
    }
}
</script>
<style lang="css" scoped>
    .order-letter{
        width:94%;
        background-color:var(--primary); 
        height:90px;
        border-radius: 25px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .order-letter:hover{
        background-color: var(--septagenary);
    }
    .order-letter:hover .divisor::after{
        border-right:2px solid var(--tertiary);
    }
    .label-1{
        color: var(--tertiary);
        font-size: 23px;
        font-weight: 700;
        line-height: 1.2;
    }
    .label-3{
        color: var(--tertiary);
        font-size: 30px;
        line-height: 1.2;
    }
    .label-2{
        color: var(--tertiary);
        font-size: 20 px;
        text-transform: capitalize;
        line-height: 1.8;
        white-space: nowrap;
    }
    .pb-specific{
        padding-bottom: 0.7em;
    }
    ::v-deep(.icon-alert){
        height: 40%;
        width: auto;
        object-fit: contain;
        object-position: right center;
        pointer-events: none;
    }
    ::v-deep(.icon-alert path){
        fill: var(--tertiary);
    }
    .divisor{
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .divisor::after{
        content:"";
        display: flex;
        width: 1px;
        height: 40%;
        border-right:2px solid var(--vigequinary);
    }
    .line-anim {
        width: 100%;
        height: 1px;
        display: flex;
        position: absolute;
        bottom: 12px;
        left: 0;
        overflow: hidden;
      }

      .line {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        overflow: hidden;
        justify-content: space-around;
      }
      
      
      .rectangle {
        width: 15%; /* Ajusta según tu necesidad */
        height: 100%;
        position: relative;
      }

      .rectangle.active{
        background-color: var(--tertiary);
      }

      .rectangle.last{
        animation: focus 1s linear infinite;
      }

      .rectangle.pending{
        background-color: var(--vigequinary);
      }

      .rectangle:nth-child(1) {
        z-index: 5;
      }
      .rectangle:nth-child(2) {
        z-index: 4;
      }
      .rectangle:nth-child(3) {
        z-index: 3;
      }
      .rectangle:nth-child(4) {
        z-index: 2;
      }
      .rectangle:nth-child(5) {
        z-index: 1;
      }
      


      @keyframes focus {
        0%{
            background-color: var(--vigequinary);
        }
        50%{
            background-color: var(--tertiary);
        }
        100%{
            background-color: var(--vigequinary);
        }
      }

      
    
</style>