<template lang="html">
    <div ref="successModal" class="successModal modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg px-md-0 px-3">
            <div class="modal-content d-flex justify-content-center align-items-center align-content-center" v-if="showing">
                <svg class="checkCover" viewBox="0 0 144 144">
                    <circle class="circleGreen" cx="72" cy="72" r="49" 
                        stroke-width="3" fill="transparent">
                        <animate attributeName="stroke-dasharray" values="0, 720; 720, 0" dur="2s" keyTimes="0; 1" repeatCount="1"/>
                    </circle>
                
                    <!-- Círculo blanco encima -->
                    <circle cx="72" cy="72" r="46" fill="transparent" class="circleInner">
                        <animate attributeName="r" values="46; 0" dur="0.3s" keyTimes="0; 1" begin="0.7s" repeatCount="1" fill="freeze"/>
                    </circle>
                
                    <!-- Símbolo de check mejorado -->
                    <path d="M45 72 L65 92 L100 60" stroke="white" stroke-width="8" fill="transparent" class="check">
                    </path>
                </svg>
                <div class="modal-body w-100 d-flex flex-wrap justify-content-center">
                    <div class="w-100 font-7 text-center d-flex justify-content-center mb-3 titled">
                        {{ title }}
                    </div>
                    <div class="w-100 contented font-9 px-md-5 d-flex justify-content-center flex-wrap" v-html="mess">
                    </div>
                    <button class="buttonAction mt-5 pushable active font-7 px-4 py-1" @click="hideModal()">{{button}}</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { Modal } from "bootstrap";

export default {
    emits:['close'],
    name:"successModal",
    props:{
        lang:{
            default:"es",
            type: String
        },
        active:{
            default:true,
            type:Boolean
        },
        showIncrement:{
            default:0,
            type:Number
        },
        title:{
            default:'',
            type:String
        },
        button:{
            default:'OK',
            type:String
        },
        mess:{
            default:'',
            type:String
        },
        successEvent:{
            default:0,
            type:Number
        }
    },
    watch:{
        showIncrement(){
            this.modal.show();
            this.showing = true;
        },
        active(){
            if(this.active){
                this.modal.show();
                this.showing = true;
            }else{
                this.modal.hide()
                this.showing = false;
            }
        }
    },
    beforeUnmount(){
        this.modal.hide();
        this.showing = false;
    },
    mounted(){
        this.modal = new Modal(this.$refs.successModal, {
            backdrop: "static",
            keyboard: false,
        });
        if(this.active){
            this.modal.show();
            this.showing = true;
        }else{
            this.modal.hide();
            this.showing = false;
        }
    },
    data(){
        return{
            modal:null,
            showing:false,
            phrases:{
                f1:{
                    es:"Ok, enterado",
                    en:"Ok"
                }
            }
        }
    },
    methods:{
        hideModal(){
            this.$emit('close');
            this.modal.hide();
            this.showing = false;
            if(this.successEvent != 0){
                switch(this.successEvent){
                    case 1:
                        this.$router.replace({ path: '/track' })
                        break;
                    default:
                        break;
                }
            }
        }
    }
}
</script>
<style lang="css">

    .successModal{
        z-index:5000
    }

    .modal-content{
        border: 0 !important;
        background-color: transparent !important;
    }

    .titled{
        font-size: 28px;
        color:var(--text6)
    }

    .contented{
        font-size: 18px;
        color:var(--text17)
    }

    .modal-body{
        padding-top: 240px !important;
        padding-bottom: 50px !important;
        background-color: #fff;
        opacity: 0;
        animation: append 1s 1 1.5s forwards;
        border-radius: 0.5rem;
    }
    

    .successModal .sucessButton{
        width: 50%;
        border: 1px solid var(--undecagenary);
        outline: none;
        background-color: #fff;
        border-radius: 10px;
    }

    .successModal .sucessButton:hover{
        background-color: var(--undecagenary);
        color:#fff;
    }

    .check {
        transform: scale(0);
        transform-origin: 50% 50%;
        animation: expansing 0.2s linear 1s 1 forwards;
    }

    .checkCover {
        position:absolute;
        z-index: 1000;
        height: 180px;
        margin: auto;
        top:calc(50% - 100px);
        width: 180px;
        transform: scale(2);
        animation: translate 0.5s ease-out 0.5s 1 forwards;
    }

    .circleGreen {
        transform: rotate(0deg);
        stroke: var(--septadecagenary);
        animation: rotate 5s linear infinite, setColor 0.5s 1 0.7s forwards;
        transform-origin: 50% 50%; /* Añadido para mantener el centro */
    }

    .circleInner{
        animation: setColor2 0.5s 1 0.6s forwards; 
    }

    .buttonAction{
        font-size: 22px;
        background-color: var(--tertiary);
        color:var(--text3);
        border: 2px solid var(--text12);
        outline: none;
        border-radius: 12px;
        pointer-events: none;
        min-width: 250px;
    }

    .buttonAction.off{
        color:var(--text12);
    }

    .buttonAction.pushable{
        pointer-events: all;
        cursor: pointer;
    }

    .buttonAction.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
    }

    .buttonAction.pushable:hover{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
        opacity: 0.7;
    }

    
</style>