<template lang="html">
    <div id="tracking" class="w-100 d-flex flex-wrap justify-content-center align-items-start align-content-start">
        
        <div class="trackingInner w-100 h-100 d-flex flex-wrap justify-content-center align-items-start align-content-start fitWidth overflow-hidden position-relative"> 

            <div class="w-100 d-flex justify-content-center text-start titled ts-2 font-4 fw-bold mt-5 mb-2" v-if="orders.length <= 0">
                <div class="col-xxl-7 col-lg-10 col-11 d-flex flex-wrap justify-content-center mt-5 px-4">
                    <span class="col-12 col-md-9 text-start d-block mt-5" v-html="phrases.f19[lang]"></span>

                    <button class="commonPageButton buttonTrack pushable active font-7 px-5 py-3 ms-md-5 ms-3 mt-5" @click="goToMenu()">{{phrases.f20[lang]}}</button>
                </div>
            </div>

            <div class="col-12 px-3 px-md-0 titled ts-2 font-7 text-start mt-md-5 mt-4 d-flex flex-wrap justify-content-center" v-else>
                <span class="col-12 col-xl-10 text-start font-4 fw-bold"  v-html="phrases.f15[lang]"></span>
            </div>

            <div class="col-xl-10 col-md-11 col-12 px-3 px-md-0 d-flex flex-wrap mt-md-5 mt-4" v-for="(ordertrack, odix) in orders" :key="odix">
                <div class="w-100 py-4 font-7 headerOrder text-start ps-md-5 ps-3">{{phrases.f1[lang]}}</div>
                <div class="col-md-6 col-12 d-flex flex-wrap align-content-start align-items-start">
                    <div class="w-100 py-4 font-7 subtitle-s1 text-start ps-xl-5 ps-3">{{phrases.f16[lang]}}</div>
                    <div class="w-100 d-flex status justify-content-center align-items-center align-content-center" style="z-index: 10;">
                        <div class="col-10 col-xl-8 d-flex h-100 align-items-center align-content-center position-relative text-start">
                            <div class="bar" :class="ordertrack.status >= 1 ? (ordertrack.status < 2 ? 'active last' : 'active') : 'pending'"><div class="inner"><span></span></div></div>
                            <div class="circle" :class="ordertrack.status >= 1 ? 'active' : 'pending'">
                                <img v-svg-inline :src="a31">
                            </div>
                            <span class="font-8 content-s1 ps-md-5 ps-3">{{phrases.f6[lang]}}</span>
                        </div>
                    </div>
                    <div class="w-100 d-flex status justify-content-center align-items-center align-content-center" style="z-index: 9;">
                        <div class="col-10 col-xl-8 d-flex h-100 align-items-center align-content-center position-relative text-start">
                            <div class="bar" :class="ordertrack.status >= 2 ? (ordertrack.status < 3 ? 'active last' : 'active') : 'pending'"><div class="inner"><span></span></div></div>
                            <div class="circle" :class="ordertrack.status >= 2 ? 'active' : 'pending'">
                                <img v-svg-inline :src="a31">
                            </div>
                            <span class="font-8 content-s1 ps-md-5 ps-3">{{phrases.f7[lang]}}</span>
                        </div>
                    </div>
                    <div class="w-100 d-flex status justify-content-center align-items-center align-content-center" style="z-index: 8;" v-if="!waitingRemount">
                        <div class="col-10 col-xl-8 d-flex h-100 align-items-center align-content-center position-relative text-start">
                            <div class="bar" :class="ordertrack.status >= 3 && itsGoTime(ordertrack.go, Number(ordertrack.status)) > 0  ? (itsGoTime(ordertrack.go, Number(ordertrack.status)) == 1 ? 'active last' : 'active') : 'pending'"><div class="inner"><span></span></div></div>
                            <div class="circle" :class="ordertrack.status >= 3 && itsGoTime(ordertrack.go, Number(ordertrack.status)) > 0   ? 'active' : 'pending'">
                                <img v-svg-inline :src="a31">
                            </div>
                            <span class="font-8 content-s1 ps-md-5 ps-3">{{phrases.f8[lang]}}</span>
                        </div>
                    </div>
                    <div class="w-100 d-flex status justify-content-center align-items-center align-content-center" style="z-index: 7;" v-if="!waitingRemount">
                        <div class="col-10 col-xl-8 d-flex h-100 align-items-center align-content-center position-relative text-start">
                            <div class="bar" :class="ordertrack.status >= 3 && itsGoTime(ordertrack.go, Number(ordertrack.status)) > 1  ? (ordertrack.status < 4 ? 'active last' : 'active') : 'pending'"><div class="inner"><span></span></div></div>
                            <div class="circle" :class="ordertrack.status >= 3 && itsGoTime(ordertrack.go, Number(ordertrack.status)) > 1  ? 'active' : 'pending'">
                                <img v-svg-inline :src="a31">
                            </div>
                            <span class="font-8 content-s1 ps-md-5 ps-3" v-if="ordertrack.deliveryType == 'home'">{{phrases.f9[lang]}}</span>
                            <span class="font-8 content-s1 ps-md-5 ps-3" v-else>{{phrases.f10[lang]}}</span>
                        </div>
                    </div>
                    <div class="w-100 d-flex status justify-content-center align-items-center align-content-center" style="z-index: 6;">
                        <div class="col-10 col-xl-8 d-flex h-100 align-items-center align-content-center position-relative text-start">
                            <div class="bar" :class="ordertrack.status >=4 ? 'active' : 'pending'"><div class="inner"><span></span></div></div>
                            <div class="circle" :class="ordertrack.status >=4 ? 'active' : 'pending'">
                                <img v-svg-inline :src="a31">
                            </div>

                            <span class="font-8 content-s1 ps-md-5 ps-3">{{phrases.f11[lang]}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex flex-wrap pe-md-4 align-items-start align-content-start">
                    <div class="w-100 py-4 d-none d-md-flex"></div>
                    <div class="w-100 text-start d-flex align-items-center align-content-center ps-2 py-3 pe-2 ps-md-4 py-md-5 pe-md-4  dataSpace">
                        <div class="col-6 subtitle d-flex flex-wrap text-start">
                            <div class="font-14 subtitle-s3 w-100 text-start"><img :src="ordertrack.deliveryType == 'pickup' ? a36 : a32"></div>
                            <div class="font-9 content-s3 w-100 text-start mt-2" v-html="getDestiny(ordertrack.deliveryType, ordertrack.destiny||'casa', ordertrack.orderType||'placed', ordertrack.colony || '')"></div>
                        </div>
                        <div class="col-6 d-flex flex-wrap align-items-center align-content-center">
                            <template v-if="ordertrack.deliveryType == 'home'">
                                <template v-if="ordertrack.orderType == 'placed'">
                                    <div class="font-14 subtitle-s2 w-100 text-end">{{getEstimatedTime(ordertrack.deliveryType, ordertrack.placement)}}</div>
                                </template>
                                <template v-else> <!-- scheduled -->
                                    <div class="font-14 subtitle-s2 w-100 text-end">{{ordertrack.sent_at}}</div>
                                </template>
                                <div class="font-9 content-s2 w-100 text-end">{{phrases.f2[lang]}}</div>
                            </template>
                            <template v-else-if="ordertrack.deliveryType == 'pickup'">
                                <template v-if="ordertrack.orderType == 'placed'">
                                    <div class="font-14 subtitle-s2 w-100 text-end">{{getEstimatedTime(ordertrack.deliveryType, ordertrack.placement)}}</div>
                                </template>
                                <template v-else> <!-- scheduled -->
                                    <div class="font-14 subtitle-s2 w-100 text-end">{{ordertrack.sent_at}}</div>
                                </template>
                                <div class="font-9 content-s2 w-100 text-end">{{phrases.f21[lang]}}</div>
                            </template>
                        </div>
                    </div>
                    <div class="w-100 text-start d-flex align-items-center align-content-center ps-2 py-3 pe-2 ps-md-4 py-md-5 pe-md-4  dataSpace">
                        <div class="col-6 subtitle d-flex flex-wrap text-start">
                            <template v-if="ordertrack.ticket != '' && ordertrack.ticket != undefined">
                                <div class="font-14 subtitle-s3 w-100 text-start">#{{ordertrack.ticket}}</div>
                                <div class="font-9 content-s3 w-100 text-start">{{phrases.f12[lang]}}</div>
                            </template>
                        </div>
                        <div class="col-6 d-flex flex-wrap align-items-center align-content-center">
                            <div class="font-14 subtitle-s2 w-100 text-end">{{ordertrack.store}}</div>
                            <div class="font-9 content-s2 w-100 text-end">{{phrases.f4[lang]}}</div>
                        </div>
                    </div>
                    <div class="w-100 text-start d-flex align-items-center align-content-center ps-2 py-3 pe-2 ps-md-4 py-md-5 pe-md-4 dataSpace">
                        <div class="col-6 font-8 subtitle-s3 d-flex text-start">
                            {{phrases.f5[lang]}}
                        </div>
                        <div class="col-6 d-flex flex-wrap align-items-center align-content-center">
                            <div class="font-14 subtitle-s2 w-100 text-end">{{phrases.f13[lang]}}</div>
                            <div class="font-9 content-s2  w-100 text-end">{{phrases.f14[lang]}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center ps-5">
                    <a :href="generateRoute(ordertrack.lat, ordertrack.lon)" class="commonPageButton routeButton ms-md-5 py-3 px-2 pushable active" v-if="ordertrack.deliveryType == 'pickup'" target="_blank">{{phrases.f18[lang]}}</a>
                </div>
                <div v-if="ordertrack.status > 1 && !waitingRemount && ordertrack.status < 4" class="col-12 d-flex flex-wrap flex-md-wrap mt-2 mt-md-5 mb-5 justify-content-md-between justify-content-center align-items-center align-content-center pe-md-4">
                    <div class="col-md-3 col-12 botarg d-flex justify-content-md-end justify-content-center align-items-center align-content-center order-3 order-md-1 mt-4 mt-md-0">
                        <img :src="a33">
                    </div>
                    <div class="col-md-8 col-12 order-1 order-md-2 mt-4 mt-md-0">
                        <map-google :unique="ordertrack.name" :status="returnStatusForMap(ordertrack)" :userLocation="getDestiny(ordertrack.deliveryType, ordertrack.destiny||'casa', ordertrack.type||'placed', ordertrack.colony || '', ordertrack.zip || '', true)" :con="con" :type="ordertrack.deliveryType" :storeCords="[ordertrack.lat, ordertrack.lon]"></map-google>
                    </div>
                    <div class="col-md-3 col-12 order-4 order-md-3"></div>
                    <div class="col-md-8 col-12 order-2 order-md-4" v-if="lastOrder">
                        <resumen :car="lastOrder" :lang="lang" :order="ordertrack"></resumen>
                    </div>
                </div>
                <div v-else class="col-12 d-flex flex-wrap flex-md-nowrap mt-2 mb-5 justify-content-md-end justify-content-center align-items-start align-content-start pe-md-4">
                    <div class="col-md-3 col-12 botarg d-flex justify-content-md-end justify-content-center align-items-center align-content-center order-2 order-md-1 mt-4 mt-md-5">
                        <img :src="a33" class="mt-md-3">
                    </div>
                    <div class="col-md-8 col-12 order-1 order-md-2" v-if="lastOrder">
                        <resumen :car="lastOrder" :lang="lang" :order="ordertrack"></resumen>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import resumen from '@/components/common/orderResumen.vue'
import MapGoogle from '@/components/common/GoogleMap.vue'
export default {
    name:"tracking",
    components:{
        MapGoogle,
        resumen
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        car:{
            default:{},
            type:Object
        }
    },
    watch:{
        'socket.tray':{
            handler(newValue) {
                if(this.socket.tray.destiny.includes(this.$options.name)){
                    //this.remount(500);
                    this.filterEvent(newValue);
                }
            },
            deep: true // Para observar cambios profundos en la instancia
        },
    },
    mounted(){
        if(this.socket.wsOn){
            this.updateOrders();
        }
    },
    methods:{
        goToMenu(){
            if(this.$route.name != 'menu'){
                this.$router.push({ path: '/menu' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
        generateRoute(lat, lon){
            return"https://www.google.com/maps/place/" + lat + "," + lon;
        },
        remount(time){     
            if(!this.remountTimerRunned){
                this.remountTimerRunned = true;
                clearTimeout(this.remountTimer)
                this.remountTimer = setTimeout(()=>{
                    this.remountTimerRunned = false;
                    this.waitingRemount = true;
                    setTimeout(()=>{
                        this.waitingRemount = false;
                    }, 300)
                }, time)
            }
        },
        returnStatusForMap(order){
            switch(Number(order.status)){
                case 1:
                    return 1 //recibido en sucursal
                case 2:
                    return 2 //en preparacion
                case 3:
                    let go = this.itsGoTime(order.go, Number(order.status))
                    if(go == 1)   {
                        return 3 //en empaque
                    }else if(go == 2){
                        return 4 //en transito
                    }else{
                        return 2 // en preparacion
                    }
                case 4:
                    return 5 //finalizado
                default:
                    return 1
            }
        },
        itsGoTime(date, status){
            if(!this.validateDate(date)){
                return 0
            }
            if(status > 3){
                return 2
            }
            let dated = new Date(date);
            const now = new Date().getTime();
            const passedTime = dated.getTime();
            const difference = now - passedTime;
            const minutesPassed = Math.floor(difference / (1000 * 60));
            if(minutesPassed >= 5){
                return 2
            }else{
                this.remount((5 - minutesPassed) * 60000)
                return 1
            }
        },
        validateDate(date){
            let dated = new Date(date);
            if(date == '0000-00-00' || date == '00-00-0000'){
                return false;
            }
            if (isNaN(dated)) {
                return false;
            }
            return true
        },
        getEstimatedTime(type, value){
            try{
                let dated = new Date(value);

                if (!this.validateDate(value)) {
                    return this.phrases.f22[this.lang];
                }

                switch(type){
                    case "home":
                        dated.setMinutes(dated.getMinutes() + 40);
                        return dated.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
                    case "pickup":
                        dated.setMinutes(dated.getMinutes() + 15);
                        return dated.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
                        break;
                }
            }catch(err){}
        },
        getDestiny(type, value, orderType, colony='', zip='', maps=false){
            switch(type){
                case "home":
                    if(this.user.dataToken != null){
                        return colony + ' ' + zip;
                    }else{
                        return ''
                    }
                case "pickup":
                    return this.phrases.f17[this.lang];
            }
        },
        filterEvent(event){
            switch(event.name){
                case 'updateOrders':
                    this.updateOrders();
                    break;
            }
        },
        updateOrders(){
            if(this.socket.userData.hasOwnProperty('orders')){
                this.orders = this.socket.userData.orders;
                if(this.orders.length >= 1){
                    if(localStorage.hasOwnProperty(this.orders[0].name)){
                        this.lastOrder = JSON.parse(localStorage[this.orders[0].name])
                    }
                }
            }
        }
    },
    data(){
        return{
            lastOrder:false,
            orders:[],
            waitingRemount:false,
            remountTimer : setTimeout(()=>{}, 100),
            remountTimerRunned: false,
            a31:require('@/assets/images/a31.svg'),
            a32:require('@/assets/images/a32.svg'),
            a33:require('@/assets/images/a33.png'),
            a34:require('@/assets/images/a34.svg'),
            a36:require('@/assets/images/a34.png'),
            a35:require('@/assets/images/a35.svg'),
            phrases:{
                f1:{
                    es:"Información",
                    en:"Information"
                },
                f2:{
                    es:"LLegada est",
                    en:"Arrival est"
                },
                f3:{
                    es:"TIEMPO ESTIMADO:",
                    en:"ESTIMATED TIME"
                },
                f4:{
                    es:"Sucursal",
                    en:"Store"
                },
                f5:{
                    es:"Asistencia",
                    en:"Assistant"
                },
                f6:{
                    es:"Recibida por Sucursal",
                    en:"Received by Store"
                },
                f7:{
                    es:"En Preparación",
                    en:"In Preparation"
                },
                f8:{
                    es:"Proceso de Empaque",
                    en:"Packaging Process"
                },
                f9:{
                    es:"Camino a tu Domicilio",
                    en:"On the way to your Address"
                },
                f10:{
                    es:"Listo para Recoger",
                    en:"Ready to Pickup"
                },
                f11:{
                    es:"Entregado",
                    en:"Fulfilled"
                },
                f12:{
                    es:"Pedido",
                    en:"Order"
                },
                f13:{
                    es:"800 BOSTON'S",
                    en:"800 BOSTON'S"
                },
                f14:{
                    es:"800 - 267 86 67",
                    en:"800 - 267 86 67"
                },
                f15:{
                    es:"Tus Pedidos",
                    en:"Your Orders"
                },
                f16:{
                    es:"Status del pedido",
                    en:"Order status"
                },
                f17:{
                    es:"Recoger En Sucursal",
                    en:"Store Pickup"
                },
                f18:{
                    es:"Ruta a la sucursal",
                    en:"Route to the store"
                },
                f19:{
                    es:"Aún no tienes ordenes",
                    en:"You don't have any orders yet"
                },
                f20:{
                    es:"Ir al Menu",
                    en:"Go to Menu"
                },
                f21:{
                    es:"Finalizacion est",
                    en:"Finish est"
                },
                f22:{
                    es:"Calculando tiempo...",
                    en:"Calculating time..."
                },
            }
        }
    }
}
</script>
<style lang="css" scoped>
    #tracking{
        min-height: calc(100vh - 118px);
        min-width: 100vw;
        position: relative;
    }
    .cover{
        width: 100%;
        height: auto;
    }
    .headerOrder{
        background-color: var(--primary);
        color:var(--text2);
        line-height: 1.2;
        font-size: 30px;
        border-radius: 10px 10px;
    }
    .titled{
        color: var(--primary);
        line-height: 1.2;
    }
    .subtitle-s1{
        color: var(--primary);
        line-height: 1.2;
        font-size: 32px;
    }
    .subtitle-s2{
        color: var(--primary);
        line-height: 1.2;
        font-size: 30px;
    }
    .subtitle-s3{
        color: var(--vigetertiary);
        line-height: 1.2;
        font-size: 28px;
    }
    .content-s1{
        color: var(--vigetertiary);
        line-height: 1.2;
        font-size:27px;
    }
    .content-s2{
        color: var(--vigetertiary);
        line-height: 1.2;
        font-size:18px;
    }
    .content-s3{
        color: var(--vigetertiary);
        line-height: 1.2;
        font-size:16px;
    }
    .infoBlock{
        border-radius: 50px;
        background-color: var(--vigesecondary);
        border: 2px solid var(--vigeprimary);
    }

    .status{
        height: 105px;
    }

    .bar{
        position: absolute;
        height: 110%;
        width: 20px;
        left: 15px;
        top: 0;
        z-index: 3;
    }

    .bar .inner{
        width: 100%;
        height: 100%;
        position:relative;
    }

    .bar.last .inner span{
        display: block;
        top:50%;
        display: block;
        position: absolute;
        content:"";
        width: 100%;
        height: 50%;
        z-index: 2;
        border-left: 2px solid var(--vigeprimary);
        border-right: 2px solid var(--vigeprimary);
    }

    .bar .inner::after{
        display: block;
        position: absolute;
        content:"";
        width: 100%;
        height: 50%;
        top:50%;
        background-color: var(--tertiary);
        z-index: 1;
        border-left: 2px solid var(--vigeprimary);
        border-right: 2px solid var(--vigeprimary);
    }
    .status:nth-child(2) .bar.last .inner span{
        height: 100% !important;
        top:0% !important;
    }
    .status:nth-child(2) .bar.last .inner::before{
        display: none !important;
    }
    .status:nth-child(2) .bar.last .inner::after{
        height: 60%;
        top:40%;
    }
    .bar .inner::before{
        top:0;
        display: block;
        position: absolute;
        content:"";
        width: 100%;
        height: 50%;
        background-color: var(--tertiary);
        z-index: 2;
        border-left: 2px solid var(--vigeprimary);
        border-right: 2px solid var(--vigeprimary);
    }

    .bar.active .inner::after{
        background-color: var(--primary);
        border-left: 2px solid var(--primary);
        border-right: 2px solid var(--primary);
    }
    .bar.active .inner::before{
        background-color: var(--primary);
        border-left: 2px solid var(--primary);
        border-right: 2px solid var(--primary);
    }

    .bar.last .inner::after{
        border-radius: 0 0 50px 50px;
        background-color: var(--secondary);
        border:none !important;
        animation: progress 3s linear infinite;
    }

    @keyframes progress {
        0%{
            height: 15%;
        }
        100%{
            height: 50%
        }
    }

    .status:nth-child(2) .bar{
        height: 50%;
        top: 50%;
    }

    .status:last-of-type .bar{
        height: 50%;
        top: 0%;
    }

    .circle{
        flex-shrink:0;
        height: 50px;
        width: 50px;
        background-color: var(--tertiary);
        border-radius: 50%;
        z-index: 4;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 2px solid var(--vigeprimary);
    }

    .circle.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
    }

    .circle ::v-deep(svg) path{
        fill: #fff
    }

    .circle svg{
        display: none;
        width: 25px;
        height: 30px;
    }

    .circle.active svg{
        display:flex;
    }

    
    .dataSpace:not(:last-of-type){
        border-bottom: 2px solid var(--vigeprimary);
    }

    .botarg{
        min-height: 350px;
    }

    .botarg img{
        width: 100%;
        height: 100%;
        max-height: 350px;
        object-fit: contain;
    }

    .routeButton{
        text-decoration: none;
    }

    .buttonTrack{
        font-size: 25px
    }
</style>