<template lang="html">
    <component :is="accountComponent" :lang="lang" :transfer="transfer" :socket="socket" :con="con" :cityMenu="cityMenu" :cities="cities" :user="user"/>
</template>
<script>
import account from "@/components/responsive/account.vue";
import loginWizard from "@/components/responsive/loginLevels.vue";
export default {
    name:"AccountDesk",
    emits:['event'],
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        view:{
            default:"responsive",
            type: String
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        car:{
            default:{},
            type:Object
        }
    },
    components:{
        account,
        loginWizard
    },
    mounted(){
        this.$scrollInTop();
    },
    computed:{
        accountComponent(){
            if(this.user.dataToken != null){
                if(this.user.dataToken.data.level == 2){
                    return account
                }
            }
            return loginWizard
        }
    },
}
</script>
<style lang="css">
    
</style>