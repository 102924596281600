<template lang="html">
    <div id="error" class="d-flex flex-wrap justify-content-center align-items-start align-content-start">
        <div class="errorLabel col-10 text-start font-8 color-1 fw-bold mt-4">
            {{phrases.f1[lang]}}
        </div>
        <div class="errorDescription col-11 col-md-10 ps-3 font-7 text-start">
            {{phrases.f2[lang]}}
        </div>
        <div class="imgError mt-5 mt-md-2 d-flex align-items-center align-content-center pe-none"><img v-svg-inline :src="a46" class="imgErr"></div>
        <button class="commonPageButton errorButtons mt-5 mb-md-5 pushable active font-7 px-5 py-3" style="font-size:30px !important;max-width:100%" @click="goToMenu()">{{phrases.f3[lang]}}</button>
    </div>
</template>
<script>
export default {
    name:"NotFound",
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        view:{
            default:"responsive",
            type: String
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        car:{
            default:{},
            type:Object
        }
    },
    mounted(){
        this.$scrollInTop();
    },
    data(){
        return{
            a46:require('@/assets/images/a46.svg'),
            phrases:{
                f1:{
                    es:"Error 404",
                    en:"Error 404"
                },
                f2:{
                    es:"Página no encontrada",
                    en:"Page not found"
                },
                f3:{
                    es:"Continuar al Menú",
                    en:"Go to Menu"
                }
            }
        }
    },
    methods:{
        goToMenu(){
            if(this.$route.name != 'menu'){
                this.$router.push({ path: '/menu' })
            }else{
                this.$scrollToTop();
            }
            return false
        },
    }
}
</script>
<style lang="css">
    #error{
        width: 100vw;
        min-height: calc(100vh - 100px);
    }
    .imgErr{
        height: auto;
        width: 100%;
        transform:scale(1.1);
        margin-bottom: 30px;
        opacity: 0.5;
        animation: errorAppend 0.2s linear 0.5s 1 forwards;
    }
    .imgErr path{
        fill:transparent;
        stroke:#3D4451;
    }
    .imgError{
        width:100%;
    }
    .errorLabel{
       font-size:100px;
       line-height:1
    }
    .errorDescription{
        font-size:30px;
     }
    @keyframes errorAppend {
        0%{
            opacity: 0;
            transform: scale(10);
        }
        100%{
            opacity: 0.5;
            transform: scale(1.1);
        }
    }
</style>