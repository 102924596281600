<template lang="html">
    <div id="loginLevels" class="w-100 d-flex justify-content-center align-items-start align-content-start">

        <loader :active="loading"></loader>
        <loader-in :active="enter"></loader-in>
        <modal-o-k :active="false" :mess="mess" :title="successTitle" :showIncrement="modalSuccess" :lang="lang" @close="getEvent()"></modal-o-k>
        <modal-err :active="false" :mess="mess" :title="errorTitle" :showIncrement="modalError" :lang="lang"></modal-err>

        <div class="loginInner w-100 h-100 d-flex flex-wrap justify-content-center align-items-start align-content-start fitWidth overflow-hidden position-relative"> 

            <template v-if="!verified && !lowLevel && !register">
                <div class="w-100 d-flex justify-content-center text-start titled font-4 mt-5 pe-md-5">
                    <div class="col-lg-5 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-10 text-start fw-bold" v-html="phrases.f1[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start subtitled font-7 mt-5">
                    <div class="col-lg-4 col-md-5 col-8 subtitled d-flex justify-content-start justify-content-md-center">
                        <span class="col-12 col-md-10 text-start">{{phrases.f2[lang]}}</span>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start mt-4">
                    <div class="col-lg-4 col-md-5 col-8 d-flex justify-content-start justify-content-md-center">
                        <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" :placeholder="phrases.f3[lang]" id="numberToRegister" class="inputLogin number col-12 col-md-9 px-3 font-7" maxlength="10" type="text" :type="(mobile == true) ? 'number' : 'text'" @keyup.enter="preLogin()" @paste="pasteFilterNumber($event)" @copy="disableCopy" @keydown="onlyNumbers($event)" v-model="number">
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start mt-4">
                    <div class="col-lg-4 col-md-5 col-8 d-flex justify-content-center">
                        <div class="w-75 d-flex justify-content-center">
                            <button ref="logEnterButton" class="pushable active loginButton font-7 px-5 py-2" @click="preLogin()">{{phrases.f4[lang]}}</button >
                        </div>
                    </div>
                </div>
            </template>
            
            <template v-else-if="((!verified && lowLevel) || (!verified && register)) && !codeSended">
                <div class="w-100 d-flex justify-content-center text-start titled font-4 mt-5 pe-md-5">
                    <div class="col-lg-5 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-10 text-start fw-bold" v-html="phrases.f31[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start font-7 mt-5">
                    <div class="col-lg-5 col-md-5 col-10 description d-flex justify-content-start justify-content-md-center">
                        <span class="col-12 col-md-10 text-start" v-html="phrases.f30[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start font-7 mt-5">
                    <div class="col-lg-4 col-md-5 col-8 subtitled d-flex justify-content-start justify-content-md-center">
                        <span class="col-12 col-md-10 text-start">{{phrases.f34[lang]}}&nbsp;<span class="color-1">{{number[0]+number[1]+number[2]}}{{number[3]+number[4]+number[5]}}{{number[6]+number[7]+number[8]+number[9]}}</span></span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center mt-5">
                    <button class="loginButtonReset active pushable px-4 py-3 font-7" @click="resendCode()">{{phrases.f32[lang]}}</button>
                </div>
                <div class="w-100 d-flex justify-content-center mt-3">
                    <button class="loginButtonReset px-4 py-3 font-7" @click="closeSession()">{{phrases.f33[lang]}}</button>
                </div>
            </template>

            <template v-else-if="((!verified && lowLevel) || (!verified && register)) && codeSended">
                <div class="w-100 d-flex justify-content-center text-start titled font-4 mt-5 pe-md-5">
                    <div class="col-lg-4 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-10 text-start fw-bold" v-html="phrases.f6[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start font-7 mt-5">
                    <div class="col-lg-4 col-md-5 col-8 subtitled d-flex justify-content-start justify-content-md-center">
                        <span class="col-12 col-md-10 text-start">{{phrases.f7[lang]}}&nbsp;<span class="color-1">{{number[0]+number[1]+number[2]}}&nbsp;-&nbsp;{{number[3]+number[4]+number[5]}}&nbsp;-&nbsp;{{number[6]+number[7]+number[8]+number[9]}}</span></span>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start mt-4">
                    <div class="col-lg-4 col-md-5 col-8 overflow-hidden d-flex justify-content-start justify-content-md-center font-3">
                        <div id="confirmationCode" class="col-12 col-md-10 d-flex justify-content-center font-3">
                            <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  ref="firstCode" @click="checkLastInput($event)" @paste="pasteFilter($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 0)"  @selectstart="disableCopy" @keydown="focusNext($event, 0)" v-model="code[0]" :type="(mobile == true) ? 'number' : 'text'" class="inputCode" maxlength="1" type="text" value="" required="required">
                            <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  @click="checkLastInput($event)" @paste="pasteFilter($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 1)" @selectstart="disableCopy" @keydown="focusNext($event, 1)" v-model="code[1]" :type="(mobile == true) ? 'number' : 'text'" class="inputCode ms-3" maxlength="1" type="text" value="" required="required">
                            <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  @click="checkLastInput($event)" @paste="pasteFilter($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 2)" @selectstart="disableCopy" @keydown="focusNext($event, 2)" v-model="code[2]" :type="(mobile == true) ? 'number' : 'text'" class="inputCode ms-3" maxlength="1" type="text" value="" required="required">
                            <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  @click="checkLastInput($event)" @paste="pasteFilter($event)" @copy="disableCopy" @keyup="focusNextChrome($event, 3)" @selectstart="disableCopy" @keydown="focusNext($event, 3)" v-model="code[3]" :type="(mobile == true) ? 'number' : 'text'" class="inputCode ms-3" maxlength="1" type="text" value="" required="required">
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex flex-wrap justify-content-center text-start mt-4 mb-4 font-9 errMess">

                    <span v-if="codeError">{{phrases.f8[lang]}}</span>
                    <div class="w-100 mb-2"></div>

                    <button :class="timer<=0 ? 'active' : 'inactive' " class="loginButtonReset px-4 py-3 font-7" @click="resendCode()">{{phrases.f18[lang]}}</button>

                    <div v-if="timer > 0" class="w-100 d-flex justify-content-center counter mt-4">
                        {{phrases.f16[lang]}}&nbsp;<span>{{Math.round(timer/1000)}}</span>s {{phrases.f17[lang]}}
                    </div>
                    
                </div>

                <div class="w-100 d-flex flex-wrap justify-content-center text-start mt-4 mb-4 " v-if="!register">
                    <div class="col-lg-4 col-md-5 col-8 d-flex justify-content-start justify-content-md-center">
                        <div class="col-12 col-md-10 d-flex justify-content-start">
                            <button class="circledButton" @click="resetLowLevel()">
                                <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7071 4.29289C12.0976 4.68342 12.0976 5.31658 11.7071 5.70711L6.41421 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.41421L11.7071 18.2929C12.0976 18.6834 12.0976 19.3166 11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071L3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929L10.2929 4.29289C10.6834 3.90237 11.3166 3.90237 11.7071 4.29289Z" fill="#000000"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center mt-5" v-else>
                    <button :class="((Number(timer))<=30000) ? 'active' : 'inactive' " class="loginButtonReset px-4 py-2 font-7" @click="changeNumber()">{{phrases.f26[lang]}}</button>
                </div>

            </template>

            <template v-if="verified && register">
                <div class="w-100 d-flex justify-content-center text-start titled font-4 fw-bold mt-5">
                    <div class="col-lg-7 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-10 text-start" v-html="phrases.f5[lang]"></span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center text-start pointTitle font-9 mt-2">
                    <div class="col-lg-7 col-11 d-flex justify-content-start">
                        <span class="col-12 col-md-10 text-start ps-md-3">{{phrases.f10[lang]}}</span>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center text-start my-5">
                    <div class="col-lg-7 col-11">
                        <div class="col-12 text-start ms-md-3 separator"></div>
                    </div>
                </div>

                <div class="w-100 d-flex flex-wrap justify-content-center">
                    <div class="col-lg-7 col-11 d-flex flex-wrap justify-content-center">
                        <div class="col-12 col-md-5 me-md-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f11[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 pe-3 pe-md-0 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="nameRegister" ref="nameRegister" name="nameRegister"  @input="unMarkError('name')" @change="unMarkError('name')" v-model="form.name.value" :placeholder="phrases.f11[lang]" class="inputLogin w-100 ps-3 font-7" maxlength="50" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3 me-3 me-md-0">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="form.name.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="form.name.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 ms-md-4 mt-4 mt-md-0">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f12[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 pe-3 pe-md-0 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="lastNameRegister" ref="lastNameRegister" name="lastNameRegister"  @input="unMarkError('lastName')"  @change="unMarkError('lastName')" v-model="form.lastName.value" :placeholder="phrases.f12[lang]" class="inputLogin w-100 ps-3 font-7" maxlength="50" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3 me-3 me-md-0">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="form.lastName.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="form.lastName.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 me-md-4 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f14[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 pe-3 pe-md-0 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="emailRegister" ref="emailRegister"  name="emailRegister" @input="unMarkError('email')" @change="unMarkError('email')"  v-model="form.email.value" :placeholder="phrases.f15[lang]" class="inputLogin w-100 ps-3 font-7" maxlength="100" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3 me-3 me-md-0">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="form.email.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="form.email.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 ms-md-4 mt-4">
                            <div class="col-12 subtitled font-7 text-start mb-3">{{phrases.f19[lang]}}*</div>
                            <div class="col-12 text-start d-flex justify-content-end ps-3 pe-3 pe-md-0 position-relative">
                                <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  id="emailRegister2" ref="emailRegister2"  name="emailRegister2" @input="unMarkError('emailConfirm')" @change="unMarkError('emailConfirm')"  v-model="form.emailConfirm.value" :placeholder="phrases.f15[lang]" class="inputLogin w-100 ps-3 font-7" maxlength="100" type="text">
                                <div class="superponIcon d-flex align-items-center align-content-center justify-content-center pe-3 me-3 me-md-0">
                                    <svg class="errorIcon" viewBox="0 0 180 180" width="100" height="100" v-if="form.emailConfirm.error === true">
                                        <circle cx="90" cy="90" r="90" stroke-width="0" />
                                        <line x1="45" y1="45" x2="135" y2="135" stroke-width="12" />
                                        <line x1="45" y1="135" x2="135" y2="45" stroke-width="12" />
                                    </svg>
                                    <svg class="checkIcon" viewBox="0 0 180 180" v-else-if="form.emailConfirm.error === false">
                                        <circle class="circled" cx="90" cy="90" r="90" stroke-width="3">
                                        </circle>
                                        <path d="M50 90 L80 120 L130 70" stroke-width="12" fill="transparent">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 me-md-4 mt-4">
                            <div class="col-12 text-start  optionBox commonPageCheckbox d-flex justify-content-start align-items-center align-content-center ps-3 pe-3 pe-md-0 position-relative">
                                <input id="promoBox" @click="check($event, 'promos')" :checked="promos" type="checkbox">
                                <label for="promoBox" class="me-2 ms-2">
                                <span class="pe-none"><img :src="a27"></span></label>
                                {{phrases.f27[lang]}}
                            </div>
                        </div>
                        <div class="col-12 col-md-5 ms-md-4 mt-4">
                            <div class="col-12 text-start optionBox commonPageCheckbox d-flex justify-content-start align-items-center align-content-center ps-3 pe-3 pe-md-0 position-relative">
                                <input id="termsBox" @click="check($event, 'terms')" :checked="terms" type="checkbox">
                                <label for="termsBox" class="me-2 ms-2">
                                <span class="pe-none"><img :src="a27"></span></label>
                                {{phrases.f28[lang]}}&nbsp;<a class="nounderline" target="_blank" href="https://bostons.com.mx/welcome/privacy">{{phrases.f29[lang]}}</a>
                            </div>
                        </div>

                        <div class="col-12 col-md-5 ms-md-4 mt-4">
                            <div class="col-12 text-start d-flex justify-content-end ps-3">
                            </div>
                        </div>
                        <div class="col-12 col-md-5 ms-md-4 mt-5">
                            <div class="col-12 text-start d-flex justify-content-end pe-3 pe-md-0 ps-3">
                                <button :class="terms ? 'pushable active' : ''" class="loginButton w-100 font-7 px-4 py-3" @click="saveNewRegister()">{{phrases.f4[lang]}}</button >
                            </div>
                        </div>
                    </div>
                </div>


            </template>

        </div>
    </div> 
</template>
<script>
import auth from '@/assets/js/auth.js';
import loader from "@/components/common/loader.vue";
import loaderIn from "@/components/common/loaderIn.vue";
import modalOK from "@/components/common/successModal.vue";
import modalErr from "@/components/common/errorModal.vue";
export default {
    name:"loginLevels",
    emits:['event'],
    components:{
        loader,
        loaderIn,
        modalOK,
        modalErr,
        auth
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        user:{
            type:Object,
            default:{}
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
    },
    data(){
        return{
            a27:require('@/assets/images/a27.svg'),
            enter:0,
            mobile:false,
            verified:false,
            register:false,
            lowLevel:false,
            codeError:false,
            lastCodeCache:"",
            loading:false,
            codeSended:false,
            registerNumber:"",
            lastPageVisited:window.history.state.back,
            timer:localStorage.crmTimeout ? Number(localStorage.crmTimeout) : 0,
            timerRunned:false,
            number:"",
            form:{
                name:{
                    value:"",
                    error:""
                },
                lastName:{
                    value:"",
                    error:""
                },
                email:{
                    value:"",
                    error:""
                },
                emailConfirm:{
                    value:"",
                    error:""
                },
            },
            tokenPreRegister:null,
            code:[null, null, null, null],
            phrases:{
                f1:{
                    es:"Ingresa tu número de teléfono",
                    en:"Enter your phone<br>number"
                },
                f2:{
                    es:"Número de celular",
                    en:"Cellphone number"
                },
                f3:{
                    es:"10 dígitos",
                    en:"10 digits"
                },
                f4:{
                    es:"Continuar",
                    en:"Continue"
                },
                f5:{
                    es:"Información personal",
                    en:"Personal data"
                },
                f6:{
                    es:"Ingresa el código de 4 dígitos",
                    en:"Enter the 4 digits<br>code"
                },
                f7:{
                    es:"Enviado por Whatsapp al",
                    en:"Sent via Whatsapp to"
                },
                f8:{
                    es:"El código ingresado es incorrecto",
                    en:"The entered code is incorrect"
                },
                f9:{
                    es:"No recibí el código",
                    en:"Code not received"
                },
                f10:{
                    es:"Campos obligatorios marcados con *",
                    en:"Personal data"
                },
                f11:{
                    es:"Nombre",
                    en:"Name"
                },
                f12:{
                    es:"Apellido",
                    en:"LastName"
                },
                f13:{
                    es:"Celular",
                    en:"Cellphone"
                },
                f14:{
                    es:"Correo electrónico",
                    en:"Email"
                },
                f15:{
                    es:"correo@email.com",
                    en:"mail@email.com"
                },
                f16:{
                    es:"Espere",
                    en:"Wait"
                },
                f17:{
                    es:"para reenviar otro código",
                    en:"for another code"
                },
                f18:{
                    es:"Reenviar código",
                    en:"Resend code"
                },
                f19:{
                    es:"Confirma Correo",
                    en:"Confirm email"
                },
                f20:{
                    es:"Ingreso Fallido",
                    en:"Failed login"
                },
                f21:{
                    es:"No ha sido posible enlazar su cuenta, comuníquese con la linea BPT para recibir ayuda sobre el problema (800 - 267 86 67)",
                    en:"It has not been possible to link your account. Please contact BPT helpline for assistance with the issue at (800 - 267 86 67)"
                },
                f22:{
                    es:"Cuenta creada",
                    en:"Account created"
                },
                f23:{
                    es:"Su cuenta ha sido creada exitósamente",
                    en:"Your account has been created successfully"
                },
                f24:{
                    es:"Error en registro",
                    en:"Register failed"
                },
                f25:{
                    es:"Error al solicitar registro",
                    en:"Error requesting registration"
                },
                f26:{
                    es:"Cambiar número celular",
                    en:"Change cellphone"
                },
                f27:{
                    es:"Suscribirme a cupones y promociones",
                    en:"Change cellphone"  
                },
                f28:{
                    es:"Aceptar los",
                    en:"Accept the"  
                },
                f29:{
                    es:"Términos y Condiciones",
                    en:"Terms of Service"  
                },
                f30:{
                    es:"Para proteger tus datos, recibirás un código de confirmación para asegurar que eres tú y puedas visualizar y editar tu información personal. <br><br> Si es un número fijo deberás crear una nueva cuenta con WhatsApp",
                    en:"To protect your data, you will receive a confirmation code to ensure that it's you and to allow you to view and edit your personal information. <br><br> If it's a landline number, you will need to create a new account with WhatsApp."  
                },
                f31:{
                    es:"Ingresa a Tu Cuenta",
                    en:"Access to Your Account"  
                },
                f32:{
                    es:"Solicitar Código",
                    en:"Request Code"  
                },
                f33:{
                    es:"Cerrar Sesión",
                    en:"Close Session "  
                },
                f34:{
                    es:"Enviar Código por WhatsApp al",
                    en:"Send Code via WhatsApp to"
                },
            },
            regEmail:new RegExp(/^[a-zA-Z0-9_.]{3,64}@[a-z0-9]{3,64}\.[a-z0-9]{2,10}(?:\.[a-z]{2,10}|)$/g),
            regNames:new RegExp(/^[a-zA-ZÀ-úñ ]{3,50}$/g),
            regComment:new RegExp(/^[a-zA-ZÀ-úñ0-9()#., ]{5,150}$/g),

            mess:'',
            successTitle:"",
            modalSuccess:0,
            errorTitle:"",
            modalError:0,

            exit:false,
            terms:false,
            promos:false
        }
    },
    watch:{
        transfer(){
            if(this.transfer.to.includes(this.$options.name)){
                if(this.loading == true){this.loading = false;}
                if(this.enter == 1){this.enter = 2}
                if(this.user.dataToken.data.level == 1){
                    if(this.lastPageVisited != null && this.lastPageVisited != ''){
                        this.$router.replace({
                            path: this.lastPageVisited,
                            query: { fromLogin: true },
                        });
                    }else{
                        this.$router.replace({ path: '/' })
                    }
                    
                }
                setTimeout(()=>{
                    this.enter = 0;
                }, 1500)
            }
        }
    },
    beforeUnmount(){
        this.exit = true;
    },
    mounted(){
        this.mobile = this.isMobile();
        if(this.user.dataToken != null){ // por si existe token
            if(this.user.dataToken.data.level == 1){ // por si el token es de nivel 1
                this.number = this.user.dataToken.data.phone1;
                if(this.timer > 0){
                    this.codeSended = true;
                    this.countTime();
                }
                this.lowLevel = true;
            }else if(this.user.dataToken.data.level == 2){
                this.verified = 1;
            }
        }else{
            if(this.$route.query.login){
                this.number = (this.$route.query.login).replace(/\D/g,'');
                if(this.number.length >= 7){
                    this.$refs.logEnterButton.click()
                }
            }
        }
        if(Object.keys(this.user).length === 0){
            if(this.user.data != '' && this.user.data != null){
                this.verified = this.user.data.verified;
            }
        }
    },
    methods:{
        checkInputValue(array, key, reg='regText', results=[0]){
            if( results.includes(this.verify(this[reg], this[array][key].value)) ) {
                this[array][key].error = true;
            }else{
                this[array][key].error = false;
            }
        },
        check(e, box){
            switch(box){
                case "promos":
                    this.promos = e.target.checked;
                    break;
                case "terms":
                    this.terms = e.target.checked;
                    break;
                default:
                    break;
            }
        },
        resetLowLevel(){
            if(this.user.dataToken != null){ // por si existe token
                if(this.user.dataToken.data.level == 1){
                    /*this.register = false;
                    this.verified = false;
                    this.lowLevel = false;
                    localStorage.removeItem('crmAuthService');
                    this.$parent.$emit('event', {type:'erasePreNumber', time:0})*/
                    this.$router.go(-1)
                }else{
                    this.$router.go(-1)
                }
            }
            
        },
        closeSession(){
            this.$parent.$emit('event', {type:'closeSession'});
        },
        changeNumber(){
            this.lowLevel = false;
            this.register = false;
            this.verified = false;
            this.codeSended = false;
            this.timer = 0;
            localStorage.setItem('crmTimeout', 0);
        },
        getEvent(){
            if(this.mess == this.phrases.f23[this.lang]){
                this.$parent.$emit('event', {type:'updateSession', time:0})
            }
        },
        saveNewRegister(){
            this.matchFormRegister().then((res)=>{
                if(res===true){
                    this.loading = true;
                    let request = {
                        token:this.tokenPreRegister,
                        data:{
                            name:this.form.name.value,
                            lastName:this.form.lastName.value,
                            phone:this.number.value,
                            email:this.form.email.value,
                            promos:this.promos,
                        }
                    }
                    this.con.registerUser(request).then((res)=>{
                        if(res.code==200){
                            this.loading = false;
                            
                            this.successTitle = this.phrases.f22[this.lang];
                            this.mess = this.phrases.f23[this.lang];
                            this.modalSuccess += 1;
                            localStorage.setItem('crmAuthService', res.data);

                        }else{
                            this.mess = this.phrases.f24[this.lang] + (res.msj || '');
                            this.errorTitle = this.phrases.f25[this.lang];
                            this.modalError++;
                        }
                    }).catch((err)=>{
                        this.loading = false;
                        this.mess = this.phrases.f24[this.lang];
                        this.errorTitle = this.phrases.f25[this.lang];
                        this.modalError++;
                    })
                }
            }).catch((err)=>{})
        },

        formValid(){
            if(this.form.name.value.length > 2 && this.form.lastName.value.length > 2 && this.form.email.value.length > 10){
                return true
            }
            return false
        },        

        matchFormRegister(){

            return new Promise((resolve)=>{

                let val = true;

                if(!this.formValid()){
                    val = false;
                }

                if(this.verify(this.regNames, this.form.name.value) == 0 || this.verify(this.regNames, this.form.name.value) == 2) {
                    this.form.name.error = true;
                    val = false;
                }
                if(this.verify(this.regNames, this.form.lastName.value) == 0 || this.verify(this.regNames, this.form.lastName.value) == 2) {
                    this.form.lastName.error = true;
                    val = false;
                }
                if(this.verify(this.regEmail, this.form.email.value) == 0 || this.verify(this.regEmail, this.form.email.value) == 2) {
                    this.form.email.error = true;
                    this.form.emailConfirm.error = true;
                    val = false;
                }

                if(this.form.email.value !== this.form.emailConfirm.value) {
                    this.form.email.error = true;
                    this.form.emailConfirm.error = true;
                    val = false;
                }

                if(!val){
                    resolve(val)
                }else{
                    resolve(val)
                }
            }).catch((err)=>{})         
        },

        countTime(internRun=false){
            if(!this.timerRunned || internRun){
                this.timerRunned = true;
                setTimeout(()=>{
                    if(!this.exit){
                        if(this.timer > 0){
                            this.timer -= 1000;
                            localStorage.setItem('crmTimeout', this.timer);
                            this.countTime(true);
                        }else{
                            this.timerRunned = false;
                        }
                    }
                }, 1000)
            }
        },

        back(){
            this.verified = false;
            this.register = false;
        },

        resendCode(){
            //enviar codigo otra vez
            this.codeSended = true;
            this.loading = true;
            this.codeError = false;
            this.code = [null, null, null, null];
            setTimeout(()=>{
                try{
                    this.$refs.firstCode.click();
                }catch(err){}
            }, 100)
            this.con.getLogin({
                token:this.cityMenu.token, 
                data:{
                    "phone": this.number,
                    "type": "full"
                }
            }).then((res)=>{
                this.loading = false;
                if(res.code == 404 || res.code == 200){
                    this.timer = 60000;
                    localStorage.setItem('crmTimeout', 60000)
                    this.countTime();
                }
            }).catch((err)=>{this.loading = false;})
        },

        preLogin(){
            this.number = this.number.replace(/[^0-9.]/g, '');
            if(this.number.length == 10){
                this.loading = true;
                setTimeout(()=>{
                    this.con.getLogin({
                        token:this.cityMenu.token, 
                        data:{
                            "phone": this.number,
                            "type": "simple"
                        }
                    }).then((res)=>{
                        this.code = [null, null, null, null];
                        this.loading = false;
                        if(res.code == 404){ //envio de codigo para registro nuevo
                            this.register = true;
                            this.resendCode();
                            setTimeout(()=>{
                                this.$refs.firstCode.click();
                            }, 500)
                            this.timer = 60000;
                            this.$parent.$emit('event', {type:'loginReplaceNew'});
                            localStorage.setItem('crmTimeout', 60000)
                            this.countTime();
                        }else if(res.code == 200){ //login primordial
                            this.exit = true;
                            localStorage.setItem('crmAuthService', res.data);
                            localStorage.removeItem('crmTimeout');
                            this.$parent.$emit('event', {type:'updateSession', time:1000});
                            this.enter = 1;
                        }else if(res.code == 403){ //error login
                            this.mess = this.phrases.f21[this.lang];
                            this.errorTitle = this.phrases.f20[this.lang];
                            this.modalError++;
                        }else{
                            this.mess = this.phrases.f24[this.lang] + (res.msj || '');
                            this.errorTitle = this.phrases.f25[this.lang];
                            this.modalError++;
                        }
                    }).catch((err)=>{
                        this.loading = false;
                        this.mess = this.phrases.f24[this.lang];
                        this.errorTitle = this.phrases.f25[this.lang];
                        this.modalError++;
                    })
                }, 200)
            }else{
                this.markRed("numberToRegister");
                this.removeAllReds();
            }
        },

        removeAllReds(){
            setTimeout(()=>{
                document.querySelectorAll('.RedFocus').forEach((elm)=>{
                    elm.classList.remove('RedFocus')
                })
            }, 1500)
        },

        verifiedCode(){
            let code = "";
            for(let i in this.code){
                code += this.code[i]
            }
            code = code.trim();

            if(code == this.lastCodeCache){
                return false;
            }

            this.lastCodeCache = code;

            if(code.length == 4){
                this.loading = true;
                this.con.checkCodeValidation({
                    token:this.cityMenu.token, 
                    data:{
                        "phone": this.number,
                        "code": code
                    }
                }).then((res)=>{
                    setTimeout(()=>{
                        if(res.code==200){ // enrutamiento correcto a cuenta
                            this.loading = false;
                            localStorage.setItem('crmAuthService', res.data);
                            this.$parent.$emit('event', {type:'updateSession', time:1000});
                        }else if(res.code == 307){ // registro de nueva cuenta
                            setTimeout(()=>{this.loading = false;}, 200)
                            this.tokenPreRegister = res.data;
                            this.registerNumber = this.number;
                            this.verified = true;
                            setTimeout(()=>{
                                this.$refs.nameRegister.focus();
                            }, 500)
                        }else if(res.code == 400){ // error de codigo
                            setTimeout(()=>{this.loading = false;}, 200)

                            this.codeError = true;
                            this.code = [null, null, null, null];
                            this.$refs.firstCode.click();
                            document.querySelectorAll('.inputCode').forEach((e)=>{
                                e.classList.add('RedFocus');
                            })
                            this.removeAllReds();
                            setTimeout(()=>{
                                this.$refs.firstCode.click();
                            }, 1500)
                            
                        }
                    }, 500)
                    
                }).catch((err)=>{setTimeout(()=>{
                    this.loading = false;
                }, 1500);})
            }
        },

        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },

        checkLastInput(e){
            let codes = document.getElementById('confirmationCode');
            let start = null;
            for(let i=0; i <= 3; i++){
                if(codes.childNodes[i].value == null || codes.childNodes[i].value == undefined || codes.childNodes[i].value == ''){
                    start = i;
                    break;
                }
            }
            if(start != null){
                codes.childNodes[start].focus()
            }
        },

        onlyNumbers(e){
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (!e.ctrlKey){
                if (!/\d/.test(e.key) && keyCode != 8){
                    e.preventDefault();
                }
            }else{
                if(e.key != 'v'){
                    e.preventDefault();
                }
            }
        },

        checkBrowser(){
            let ua = navigator.userAgent
            if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) && (/Chrome/i.test(ua))){
                return false
            }else{
                return true
            }
        },

        focusNextChrome(e, i){
            if(this.loading || e.target.classList.contains('RedFocus')){
                e.preventDefault();
                return false;
            }
            if(!this.checkBrowser()){
                let val = e.target.value;
                if (/\d/.test(val)){
                    this.code[i] = String(e.target.value[e.target.value.length-1]);
                    e.target.value = e.target.value[e.target.value.length-1];
                    if(e.target.nextSibling !== undefined && e.target.nextSibling !== null){
                        e.target.nextSibling.focus();
                    }
                    this.verifiedCode();
                }else if(e.target.value == '' && i>0){
                    if(e.target.previousSibling !== undefined && e.target.previousSibling !== null){
                        e.target.previousSibling.focus();
                    }
                }else{
                    this.code[i] = '';
                    e.target.value = '';
                }
            }
        },

        focusNext(e, i){
            if(this.loading || e.target.classList.contains('RedFocus')){
                e.preventDefault();
                return false;
            }
            if(this.checkBrowser()){
                if (!e.ctrlKey){
                    e.preventDefault();
                    let keyCode = (e.keyCode ? e.keyCode : e.which);
                    
                    if (/\d/.test(e.key)){
                        this.code[i] = String(e.key)
                        if(e.target.nextSibling !== undefined && e.target.nextSibling !== null){
                            e.target.nextSibling.focus();
                        }
                        this.verifiedCode();
                    }
                    else if (keyCode == 8){
                        if((this.code[i] == null || this.code[i] == '') && i > 0){
                            e.target.previousSibling.focus();
                            this.code[i-1] = null;
                        }else{
                            this.code[i] = null;
                            if(e.target.previousSibling !== undefined && e.target.previousSibling !== null){
                                e.target.previousSibling.focus();
                            }
                        } 
                    }
                }else{
                    if(e.key != 'v'){
                        e.preventDefault();
                    }
                }
                
            }
        },
        pasteFilter(event) {
            event.preventDefault();
            let elm = document.getElementById('confirmationCode');
            let nextCodecode = event.clipboardData.getData('text').replace(/\D+/g, '');
            nextCodecode = nextCodecode.slice(0, 4);
            for(let i=0; i <= 3; i++){
                this.code[i] = null
            }
            for(let i in nextCodecode){
                this.code[i] = this.code[i] = String(nextCodecode[i]);
            }
            setTimeout(()=>{
                elm.lastChild.click();
                if(nextCodecode.length == 4){
                    this.verifiedCode();
                }
            }, 100)
            
        },
        pasteFilterNumber(event) {
            event.preventDefault();
            let nextCodecode = event.clipboardData.getData('text').replace(/\D+/g, '');
            nextCodecode = nextCodecode.slice(0, 10);
            this.number = nextCodecode;
        },
        disableCopy(event) {
            event.preventDefault();
        },
        verify(reg, data){
            data = data.trim();
            if(data == null || data == ""){
                return 2
            }
            if(data.match(reg) == null){
                return 0
            }else{ 
                return 1
            }
        },
        markRed(domId){
            /**
             *
             * marca en rojo un input
             * @param domId: string, id del elemento dom asignarle el estilo
             * @change /
             * @return /
             */

             try{
                document.getElementById(domId).classList.add("RedFocus")
             }
             catch(err){}
        },
        unMarkRed(domId){
            /**
             *
             * desmarca en rojo un input
             * @param domId: string, id del elemento dom retirarle el estilo
             * @change /
             * @return /
             */

             try{
                document.getElementById(domId).classList.remove("RedFocus")
             }
             catch(err){}
        },
        unMarkError(fieldName){
            this.form[fieldName].error = '';
        }
    }
}
</script>
<style lang="css" scoped>
    #loginLevels{
        min-height: calc(100vh - 118px);
        min-width: 100vw;
        position: relative;
    }

    .errMess{
        color: var(--quinary);
    }
    .counter span{
        color: var(--quinary);
    }

    .counter{
        color:var(--text13)
    }

    .loginInner{
        padding-bottom: 150px;
        min-height: calc(100vh - 118px);
    }
    .titled{
        color: var(--primary);
        line-height: 1;
        font-size: 45px;
    }

    .pointTitle{
        color: var(--text16);
        line-height: 1.2;
        font-size: 15px;
    }

    
    .subtitled{
        color: var(--text13);
        line-height: 1.2;
        font-size: 24px;
    }

    .description{
        color: var(--text11);
        line-height: 1.2;
        font-size: 22px;
    }

    @media (min-width: 0px) and (max-width: 768.99px) {  /*xs*/
        .subtitled{
            font-size: 20px !important;
        }
    
        .description{
            font-size: 18px !important;
        }
    }


    .inputLogin.number{
        letter-spacing: 0.15em;
    }

    .inputLogin{
        min-height: 60px;
        border: 2px solid var(--ondecagenary);
        border-radius: 12px;
        padding-right: 70px;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
        -webkit-user-drag: none;
        user-drag: none;
        draggable: false; 
        padding-bottom: 5px;
        outline: none;
    }

    .inputLogin::placeholder{
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: 400;
        color: var(--text15);
        opacity: 0.5;
    }

    .inputLogin:focus{
        border-color:var(--nonadecagenary)
    }
    

    @media (max-width: 1199.99px){
        .inputCode{
            height: 50px !important;
            width: 50px !important;
            margin: 0 5px 0 5px !important;
        }
    }
    .inputCode{
        height: 60px;
        width: 60px;
        margin: 0 0 0 0;
        outline: none;
        border: 2px solid var(--ondecagenary);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        background-color: var(--tertiary);
        align-items: center;
        text-align: center;
        font-size: 25px;
        line-height: 0;
        padding-top: 3px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .inputCode:invalid{
        background-color: var(--sexagenary);
        border: 2px solid var(--sexagenary);
    }

    .inputCode.error{
        background-color: var(--terdecagenary);
        border: 2px solid var(--terdecagenary);
    }

    .inputCode:focus{
        background-color: var(--tertiary);
        border: 2px solid var(--ondecagenary);
    }

    .loginButton{
        font-size: 16px;
        font-weight: bold;
        background-color: var(--tertiary);
        color:var(--text12);
        border: 2px solid var(--text12);
        outline: none;
        border-radius: 12px;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
        transition: all 0.2s ease;
    }

    .loginButton.pushable{
        pointer-events: all;
        cursor: pointer;
    }

    .loginButton.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
    }

    .loginButton.pushable:hover{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--text2);
        filter: contrast(0.9);
    }

    .loginButton.pushable:active{
        transform: scale(1);
        background-color: var(--quinary);
    }

    .loginButtonReset{
        font-size: 15px;
        font-weight: bold;
        min-width: 220px;
        background-color: var(--tertiary);
        color:var(--text12);
        outline: none;
        border: 2px solid var(--text12);
        border-radius: 12px;
    }

    .loginButtonReset.active{
        color:var(--text2);
        border: 2px solid var(--primary);
        background-color: var(--primary);
    }

    .loginButtonReset.inactive{
        color:var(--text12);
        border: 2px solid var(--text12);
        background-color: var(--tertiary);
        pointer-events: none;
        opacity: 0.5;
    }

    .loginButtonReset:hover{
        filter: contrast(0.9);
    }

    .separator{
        border-top:1px solid var(--ondecagenary);
        box-shadow: 0.5px 0.5px 0.5px rgba(200, 200, 200, 0.5);
    }

    .circledButton{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        background-color:var(--sexagenary);
        border:0;
        outline:none;
    }

    .circledButton:hover{
        filter: contrast(.9);
    }

    .circledButton svg{
        height:100%;
        width:100%;
    }

    .circledButton svg path{
        fill: var(--quartenary)
    }

    .optionBox{
        font-size: 14px;
    }
    .optionBox.commonPageCheckbox label{
        transform: scale(0.8);
        overflow: visible;
        background-color: #fff !important;
    }
    .optionBox.commonPageCheckbox img{
        width: 100%;
        height: 100%;
        transform: scaleX(1.6) scaleY(1.6) translateY(-6%);
    }
    .optionBox span{
        background-color: transparent;
    }

    .nounderline{
        text-decoration: none;
        color:var(--primary)
    }

    .checkIcon, .errorIcon{
        width: 30px;
        height: 30px;
    }

    .checkIcon circle{
        fill:var(--septadecagenary)
    }
    .errorIcon circle{
        fill:var(--quinary)
    }
    .checkIcon path{
        stroke:#fff
    }
    .errorIcon line{
        stroke:#fff
    }

    .superponIcon{
        position: absolute;
        right:0;
        top:0;
        height: 100%;
        width: 60px;
        z-index: 100;
        flex-shrink: 0;
    }
    
</style>