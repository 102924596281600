<template lang="html">
    <car-view :lang="lang" :transfer="transfer" :socket="socket" :con="con" :user="user" :car="car" :cityMenu="cityMenu" :cities="cities"></car-view>
</template>
<script>
import carView from "@/components/responsive/cart.vue";
export default {
    name:"orderDesk",
    emits:['event'],
    components:{
        carView
    },
    mounted(){
        this.$scrollInTop();
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        user:{
            type:Object,
            default:{}
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        view:{
            default:"responsive",
            type: String
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        car:{
            default:{},
            type:Object
        }
    },
}
</script>
<style lang="css">
</style>