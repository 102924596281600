import axios from "axios";

class request {

	apiUrl = "";
    defToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJFZmlzZW5zZSIsImF1ZCI6ImVmaXNlbnNlLmNvbSIsInN5c3RlbSI6InBvcnRhbCIsImlhdCI6MTcwNzkwMDE1NywiZXhwIjoxODAyNTA4MTU3LCJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiRWZpc2Vuc2UiLCJsYXN0bmFtZSI6IlNvcG9ydGUiLCJpZExpY2Vuc2UiOiIxIiwidXJpIjoiIiwidG9rZW4iOiJVa3N4YkNNaFZXSlhlVFkwWmpSbE5qWXhNV0ptWkdRMUxqTXhNRFUxT0RRMSIsInN0b3JlcyI6eyI2MDEyIjoiNjAxMiJ9LCJzdG9yZSI6IjYwMTIifQ.UB1_AKmdAW3U91jJVZC6B6_n9cC7K2qYh57RGQb7JYQ";

	settings = {}

	defSettings = {
		"url": "",
		"method": "GET",
		"timeout": 0,
	}

	constructor(api) {
		this.apiUrl = api;
	}

	jsonRequest = (req, parameter = null) => {

		this.settings = {};

		switch (req) {

			case "sendError":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = "https://ap15.efisense.net/apiOnico/index.php/LogEvents/registerEvent";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
				}
				this.settings["data"] = JSON.stringify(parameter);
				break;

			case "getLogin":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Login";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "checkCodeValidation":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Login/confirmCode";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;
				
			case "registerUser":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Client";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "updateUser":
				this.settings = {...this.defSettings};
				this.settings["method"] = "PUT";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Client";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "getUserData":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Client";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter
				}
				break;

			case "getColoniesMatch":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Citie/Colonies/"+parameter.url;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				break;

			case "saveUserAddress":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/ClientAddress";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "placeOrder":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Order";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "getCities":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/efisenseHub/v2/Citie";
				if(parameter != null){
					this.settings["headers"] = { 
						"Content-Type": "application/json",
						"Authorization": "Bearer " + parameter
					}
				}else{
					this.settings["headers"] = { 
						"Content-Type": "application/json",
					}
				}
				break;

			case "getStoreStatus":
					this.settings = {...this.defSettings};
					this.settings["method"] = "GET";
					this.settings["url"] = this.apiUrl + "/apiCRM/efisenseHub/v2/Citie/status/"+parameter.id;
					this.settings["headers"] = { 
						"Content-Type": "application/json",
						"Authorization": "Bearer " + parameter.token
					}
					break;

			case "getStoreStatusAll":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/efisenseHub/v2/Citie/statusAll";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
				}
				break;

			case "getTransitions":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Banner";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter
				}
				break;

			case "getFavorites":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Favorite";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter
				}
				break;

			case "getLastOrders":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Order/last";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter
				}
				break;

			case "getHomeItems":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Menu";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter
				}
				break;

			case "getCategories":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Categorie";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}

				break;
			
			case "getCategory":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/efisenseHub/product/byCategorie/"+parameter;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + this.defToken
				}
				break;

			case "getProductOptions":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Product/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				break;

			case "getProduct":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/efisenseHub/v2/Product/byId/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				break;

			case "saveCar":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Cart";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				if(parameter.storeNumber != null){
					this.settings["headers"]["X-storeNumber"] = parameter.storeNumber;
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "getCar":
				this.settings = {...this.defSettings};	
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Cart"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				if(parameter.hasOwnProperty('storeNumber')){
					this.settings["headers"]["X-storeNumber"] = parameter.storeNumber;
				}
				break;

			case "test":
				this.settings = {...this.defSettings};	
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "/apiCRM/EfisenseHub/v2/Cart/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				break;
				
			

			default:
				return false
		}
		return this.settings
	}


	/* usuario */

	sendError = (data = {}) => {
		let json = this.jsonRequest("sendError", data);
		return this.petition(json, false)
	}

	getLogin = (data = {}) => {
		let json = this.jsonRequest("getLogin", data);
		return this.petition(json, false)
	}

	checkCodeValidation = (data = {}) => {
		let json = this.jsonRequest("checkCodeValidation", data);
		return this.petition(json, false)
	}

	registerUser = (data = {}) => {
		let json = this.jsonRequest("registerUser", data);
		return this.petition(json, false)
	}

	updateUser =  (data = {}) => {
		let json = this.jsonRequest("updateUser", data);
		return this.petition(json, false)
	}

	getUserData = (data = {}) => {
		let json = this.jsonRequest("getUserData", data);
		return this.petition(json, false)
	}

	getColoniesMatch = (data = {}) => {
		let json = this.jsonRequest("getColoniesMatch", data);
		return this.petition(json, false)
	}

	saveUserAddress = (data = {}) => {
		let json = this.jsonRequest("saveUserAddress", data);
		return this.petition(json, false)
	}

	saveCar = (data={}, token=null, storeNumber=null) => {
		let json = this.jsonRequest("saveCar", {'data':data,'token':token, 'storeNumber':storeNumber});
		return this.petition(json, false)
	}

	getCar = (data={}) => {
		let json = this.jsonRequest("getCar", data);
		return this.petition(json, false)
	}

	placeOrder = (data = {}) => {
		let json = this.jsonRequest("placeOrder", data);
		return this.petition(json, false)
	}


	/* - END - usuario */



	/* personalizacion */
	
	getCities = (data = null) => {
		let json = this.jsonRequest("getCities", data);
		return this.petition(json, false)
	}

	getStoreStatus = (data = {}) => {
		let json = this.jsonRequest("getStoreStatus", data);
		return this.petition(json, false)
	}
	
	getStoreStatusAll = (data = {}) => {
		let json = this.jsonRequest("getStoreStatusAll", data);
		return this.petition(json, false)
	}

	getTransitions = (token = null) => {
		let json = this.jsonRequest("getTransitions", token);
		return this.petition(json, false)
	}

	/* - END -  personalizacion */



	/* listado de items nuevo */

	getHomeItems = (token = {}) => {
		let json = this.jsonRequest("getHomeItems", token);
		return this.petition(json, false)
	}

	/* - END -  listado de items nuevo */





	/* listado de items antiguo */

	getCategories = (data = {}) => {
		let json = this.jsonRequest("getCategories", data);
		return this.petition(json, false)
	}

	getCategory = (data = 0) => {
		let json = this.jsonRequest("getCategory", data);
		return this.petition(json, false)
	}

	getFavorites = (token = {}) => {
		let json = this.jsonRequest("getFavorites", token);
		return this.petition(json, false)
	}

	getLastOrders = (token = {}) => {
		let json = this.jsonRequest("getLastOrders", token);
		return this.petition(json, false)
	}


	getProduct = (data = 0) => {
		let json = this.jsonRequest("getProduct", data);
		return this.petition(json, false)
	}

	getProductOptions = (data = 0) => {
		let json = this.jsonRequest("getProductOptions", data);
		return this.petition(json, false)
	}

	test = (data={}) =>{
		let json = this.jsonRequest("test", data);
		return this.petition(json, false)
	}

	/* - END - listado de items antiguo */




	petition = async (json, watch=true) => {
		return new Promise(function(resolve, reject) {
			if(json){
				axios(json)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if(watch){
						console.log(error);
					}
					reject(error);
				})
			}else{
				let response = {data:false,code:500}
				resolve(response);
			}
		});
	}
}


export { request };
