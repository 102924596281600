<template lang="html">
    <div class="w-100 d-flex flex-wrap justify-content-center desktopMax">

        <div class="w-100 mt-5 ps-lg-5 d-flex flex-wrap align-items-center align-content-center justify-content-between">
            <div class="d-flex ps-3 align-items-center align-content-center">
                <div class="buttonCity d-flex align-items-center align-content-center px-0">
                    <div class="titleSelect font-7 fw-bold">{{phrases.menu[lang]}}</div>
                    <div class="selectSpaceSelector font-1 d-flex align-items-center align-content-center justify-content-center">
                        <span @click="changeCity()">{{cityMenu.friendlyName}}</span>
                    </div>
                </div>
                <span @click="changeCity()" class="ms-2 me-2 ms-md-4 me-md-0 font-7 ts-3 changeCityLabel text-start ps-2 ps-md-0">{{phrases.label1[lang]}}</span>
            </div>
            <div class="col-xxl-4 col-lg-6 col-12 col-lg-4 pe-lg-5">
                <div class="col-12 pe-lg-1">
                    <order-sign :socket="socket" :lang="lang"></order-sign>
                </div>
            </div>
        </div>

        <div class="w-100 d-flex flex-wrap align-items-stretch" v-if="userLogin()">
            <last-orders :lang="lang" :con="con" :cityMenu="cityMenu" :user="user"></last-orders>
        </div>

        <div class="w-100 d-flex d-flex flex-wrap">
            <top-favorites :con="con" :lang="lang" :cityMenu="cityMenu" :user="user"></top-favorites>
        </div>

        <div class="w-100 d-flex d-flex flex-wrap">
            <menu-home :lang="lang" :transfer="transfer" :categories="categories" :socket="socket" :con="con" :cityMenu="cityMenu" :lastPosition="lastPosition" :user="user"></menu-home>
         </div>

    </div>
</template>
<script>
import orderSign from "@/components/common/orderSign.vue";
import lastOrders from "@/components/responsive/structures/lastOrders.vue";
import topFavorites from "@/components/responsive/structures/topFavorites.vue";
import MenuHome from "@/components/responsive/structures/MenuHome.vue";
export default {
    name:"HomeArticles",
    emits:['changeCity', 'event'],
    components:{
        lastOrders,
        topFavorites,
        MenuHome,
        orderSign
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        user:{
            type:Object,
            default:{}
        },
        cities:{
            default:[],
            type: Array
        },
        categories:{
            default:{},
            type: Object
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        }
    },
    activated(){
        this.keyMount++;
    },
    methods:{
        changeCity(){
            this.$emit('changeCity')
        },
        userLogin(){
            if(this.user.dataToken != null){
                return true
            }
            return false
        },
    },
    data(){
        return{
            keyMount:0,
            phrases:{
                menu:{
                    es:"Menú:",
                    en:"Menu:",
                },
                label1:{
                    es:"Cambiar de ciudad",
                    en:"Change city"
                }
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .category{
        width: 80px;
        height: auto;
    }
    .catImage{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border:1px solid #000;
    }
    .changeCityLabel{
        font-size: 18px;
    }
    .catName{
        width: 100%;
        font-size: 18px;
    }
    .desktopMax{
        max-width: 1920px;
    }
    .buttonCity{
        height: 60px;
        width: 250px;
        background-color: var(--primary);
        border-radius: 15px;
        overflow: hidden;
    }
    .titleSelect{
        width: 30%;
        color:var(--text2);
        font-size: 15px;
    }
    .selectSpaceSelector{
        color:var(--text4);
        font-weight: bold;
        font-size: 15px;
        width:70%;
        height: 100%;
        background-color: var(--primary);
        overflow: hidden;
        padding-right: 3px;
        padding-top: 3px;
        padding-bottom: 2px;
    }
    .selectSpaceSelector span{
        text-align: center;
        border: 0;
        width: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-radius: 0 12px 12px 0;
        background-color: #fff;
        -webkit-appearance: none;
        cursor: pointer;
    }
</style>