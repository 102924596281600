<template lang="html">
    <div class="w-100 d-flex flex-wrap font-1 mt-md-5 mt-3">
        <div ref="stickMenu" :class="pad ? 'pad' : 'pad'" class="d-none d-md-flex flex-nowrap stickMenu align-items-stretch align-content-start show">
            <div class="col-12 h-100 d-flex flex-wrap font-4 fw-bold align-items-center align-content-center ps-2">
                <div class="title ts-1-title col-12 text-start fw-bold pe-2 ps-lg-5 ps-3">
                    {{categoryNameOver}}
                </div>
                <div class="subtitle ts-3 col-12 text-start fw-bold mt-0 pe-2 ps-lg-5 ps-3">
                    <div class="ps-3 ps-md-4 d-flex mt-2 mt-md-0">{{categorySubtitleOver}}</div>
                </div>
            </div>
            
        </div>

        <div class="loaderPoints" v-if="pendingScrollInfinite">
            <div class="h-100 w-100 position-relative d-flex justify-content-center align-items-center align-content-center">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                        <path class="st0" d="M80.5,10.3c1.3,1.1,2.6,2.3,3.6,3.7c-4.5-2.5-9.4-4.5-15.2-3.6c-1.6,0.2-3.1,0.5-4.6,0.9   c0.1-2.6-0.5-5.2-2.6-6.9c-1.8-1.2-3.8-2-5.9-2.2c1.7,1.7,2.8,4,2.5,6.5c-0.1,1.1-1.2,2.2-2.2,2.5c-1.7,0.6-3.9,0.4-5.3-0.8   c-1.5-1.1-2.7-2.7-2.3-4.6l0.2-0.5c0.3,0.5,0.5,1.2,1.2,1.6c0.4,0.2,1,0.1,1.4-0.1c0.3-0.2,0.4-0.5,0.4-0.8c-0.1-1.5-1.7-2.4-3-2.7   c-2.9-0.7-6.7-0.7-9.4,0.2c3.8,2.6,8,6.2,9,10.7c0.4,0.9,0.5,1.9,0.6,2.9c-2.7-4.3-6.9-7.7-11.6-8.9C33,7.4,27.8,6.8,23.4,7.9   c6.2-4.3,13.4-6.5,21-7.6C58-1.1,70.4,2.4,80.5,10.3L80.5,10.3z"/>
                        <path class="st0" d="M10,20.1l-1.4,1.7c-3.9,5.8-6.8,12.1-8,19C-0.6,50.7-0.3,61.5,4,69.9c-0.1-2.4-0.2-5.1,0.4-7.3   c1.4-4.6,3.4-9.2,7.6-12.5c-1.1,0.2-2.4,0.3-3.4,0c-2-0.5-3.3-2.5-4.1-4.2c-0.6-2.1-1-5,0.1-7.1c0.2,1.5,0.9,3,2,4   c1.3,1.1,3.2,1.3,4.7,0.7c2-1,3.2-3.3,3-5.6c-0.3-1.8-2.1-3.1-3.9-3.5c0.3,0.6,1.1,1.2,0.5,1.9c-0.2,0.3-0.5,0.3-1,0.2   c-0.8-0.4-1.1-1.3-1.3-2.1C7.9,29.7,8.2,24.3,10,20.1L10,20.1z"/>
                        <path class="st0" d="M98.4,39.5c-0.9,6-3.5,12-9,15.3c-4.7,2.8-12,3.2-16.7,0.2c1.5,4,5.1,8.1,9.3,8.9c1.5,0.3,3.3-0.2,4.6,0.6   c1.1,1.1,1.7,2.4,1.7,3.9c0.1,4.1-3.1,7.8-6.6,9.3c-2.6,0.9-5.6,0.6-7.5-1.4c-1.2-1.4-1.5-3.5-1.1-5.3c0.3-1.5,2-2.6,3.4-2.9   c1.6-0.2,3.2,0.2,4.1,1.7c0.6,0.8,0.6,2,0.2,2.9c-0.1,0.3-0.3,0.6-0.6,0.7c0.3-1.3,0.5-2.8-0.5-3.8c-1.1-0.7-2.8-0.7-3.9-0.2   c-0.6,0.3-0.9,0.9-1,1.4c-0.6,1.6-0.2,3.6,1,4.7c1.5,1.3,4,1,5.5,0.2c1.6-1,2.1-2.6,2.1-4.4c0-2.3-1.1-4.5-3.1-5.5   c-2.7-1.4-6.3-0.6-8.5,1.3c-2.5,2.9-2.4,6.9-0.9,10.2c2.1,3.4,5.7,5,9.8,4.6c8.5-1,14.8-9.5,16.9-17.1   C100.1,57.4,101.1,47.4,98.4,39.5L98.4,39.5z"/>
                        <path class="st0" d="M74.8,83.1c-0.1,4.2-3.4,7.7-7,9.5c-2.6,1.3-6.4,1.5-8.7-0.5c-1-0.9-1.4-2-1.3-3.3c0.2-0.8,0.8-1.6,1.5-1.8   c0.6-0.2,1.5-0.1,1.9,0.4c0.3,0.3,0.4,0.6,0.4,1c0,0.6-0.3,1.2-0.9,1.4c-0.4,0.3-1,0.1-1.4,0c0.1,0.1,0.1,0.3,0.2,0.4   c0.9,1.5,2.5,2.1,4.1,1.9c1.3-0.2,2.3-1.4,2.7-2.6c0.3-1.9-0.4-3.7-1.8-4.8c-1.5-1.2-3.6-1.1-5.5-0.7c-3,1.4-4.7,4-7.1,6.3   c-1.5,1.2-2.9,2.7-4.7,3.2c0.6-1,1.3-2,1.5-3.2c0.1-1.3-0.2-2.5-1.1-3.3c-1.4-1.4-3.5-1.7-5.4-1.2c-1.7,0.5-3.4,1.8-3.6,3.7   l0.8-0.4c0.4-0.2,1.2-0.2,1.6,0.1c0.4,0.4,0.5,1.1,0.3,1.6c-1.2,1.9-3.6,1.9-5.8,2C29,92.8,22.9,91.3,17.5,88   c4.7,4.1,9.9,7.3,15.3,9.3c7.4,2.9,16.5,3.3,24.7,2c10.9-1.4,21-6.2,28.6-15c-2.9,2-6.1,3.3-9.9,3.2C76.6,85.8,76.1,84.2,74.8,83.1   L74.8,83.1z"/>
                        <path class="st0" d="M84.2,15.8c7.2,4.7,13.6,12.4,13,21.7c-0.7,6.3-3.5,13-9.7,15.6c-4.6,2.1-11.3,2-15.7-0.7   c-5.3-4-6.8-11-5.1-17.3c1-3.4,3.9-6.7,7.5-7.1c3-0.5,5.8,0.4,7.5,2.9c2.2,3.1,2.6,7.8,0.5,11.2c-1.4,2.3-4,3.5-6.8,3.2   c-1.7-0.1-3.3-1.1-4.1-2.7c-1.2-2.6-0.5-6,1.2-8.1c0.9-1,1.9-1.5,3.2-1.2c1,0.2,2,0.7,2.5,1.7c0.6,1.3,0.2,3-0.2,4.3   c1-0.1,1.7-1.3,1.9-2.2c0.3-1.9-0.2-3.8-1.6-5.1c-1.2-1.1-3.1-1.3-4.6-0.8c-3.3,1.4-4.5,5-4.4,8.4c0,2.8,1.3,5.7,4,6.9   c3.4,1.3,7.3,0.1,9.5-2.5c3.8-4.4,3.6-11.8,1-16.7c-2-4.6-6.8-9.2-12-9.2c-2.1,0-4.1,0.7-5.4,2.3c-1.3,1.6-1.6,3.5-1.6,5.6   l-0.4,13.9v6.4l0.4,15.3c0.3,7.9,1.8,15.4,4.3,22.4c-3.6-2.5-9.4-2.3-13.4-0.4l-2.4,1.5c1.2-5.3,1.7-10.9,1.9-16.6l0.1-8.9   l-0.3-12.6L54.6,38c-0.6-8.5-1.9-16.8-4.4-24.6c3.9,0.5,8,0.6,12,0.4l8.5-0.7C75.6,13.1,80.4,13.1,84.2,15.8L84.2,15.8z"/>
                        <path class="st0" d="M47.1,17.4c2.3,3.2,3.3,6.9,3,11.1c-0.8,4.7-4.6,9.2-9.2,10.7c9.9,7.4,14.7,19.6,11.3,31.8   c-3.1,10.8-14.5,17.4-25.1,18.1c-5.6,0.1-10.6-1.6-14.7-5.1c-5.6-5.1-7.5-12.2-6.6-19.7c1-6.9,6.5-13.1,13.1-15.3   c6.3-1.8,12.9,0.2,17.3,5c4.2,4.5,4.2,12.3,2.3,17.9c-1.4,3.9-5.3,7-9.3,7.5c-4.5,0.4-8.5-1.2-11.5-4.3c-3-3.9-3.6-10-1.5-14.6   c1.3-3,4.4-5.7,7.8-5.7c3,0.2,6,1.1,7.7,3.8c1.9,2.8,2.4,6.8,1.1,10c-0.6,1.8-2.3,3.7-4.2,4c-2.8,0.5-5.4-0.5-6.8-2.8   c-1.5-2.2-1.6-5.7-0.1-7.9c1.1-1.4,2.5-2.4,4.3-2c1.2,0.2,2.2,0.7,3,1.6c0.3-0.7-0.2-1.4-0.6-2c-1.2-1.3-3-1.5-4.7-1.3   c-2,0.3-3.9,2.5-4.5,4.5c-1.2,3.3-0.6,7.5,1.8,10c2,2.2,5,2.9,8,2.4c2.5-0.5,5-2.5,5.8-5c1.7-3.9,1.5-9.5-1.3-12.8   c-3-3.5-7.5-5.2-12.2-4c-4.3,1.2-8.3,5-9,9.4c-1.6,6.4,0.3,13,5.3,17.1c4.4,3.4,11.5,3.4,16.3,1.1c7.1-3.5,9.5-11.3,9-19   c-1-7.3-3-14.4-9-19.2c-2.1-1.6-5-2.3-7.8-1.6c-0.8,2.2-0.2,4.6,0.8,6.5c-2.9-1-6.5-0.8-9.4-0.1c-1.2,0.3-2.2,0.9-3.3,1.4   c2.6-6.6,2.3-15,1.8-22.4c-0.4-2.3-0.3-4.8-1.1-6.9c-0.4-0.8-1.2-1.2-1.9-1.1c-0.5-0.1-1,0.2-1.4,0.4c4.6-7.5,13.1-10.6,21.8-9.1   C38.4,10.5,43.5,12.9,47.1,17.4L47.1,17.4z M36.8,27.5c0.2-2.3-0.1-4.6-1.1-6.6c-0.5-1.2-1.7-2.1-3-2.5c-1.4-0.4-2.7,0.2-3.5,1.1   c-2.7,3.4-4,7.7-3.3,12.2c0.3,1.6,1.5,3.4,3.1,3.7c2.5,0,3.8-0.2,5.6-2.1C35.8,31.7,36.5,29.6,36.8,27.5L36.8,27.5z"/>
                    </g>
                </svg>
                <img :src="a13">
            </div>
        </div>

        <div ref="menuListMobile" class="menuList mobile w-100 mx-3 mb-4 d-flex d-md-none flex-md-wrap align-items-start align-content-start placeholder" :class="pad ? 'pad' : 'pad'">
            <template v-for="(cat, catidx) in categories">
                <div v-if="existProducts(cat.name)" class="catAccess mobile py-4 font-1 flex-wrap flex-md-nowrap align-items-center align-content-center  justify-content-start text-start ps-lg-5 ps-md-5 ps-0" :class="categoryNameOver == cat.name ? 'active' : ''" role="button" :id="'catMenu-'+cat.name" @click="getCategory(cat.idCat, cat.name)">
                    {{ oneWord(cat.name) }}
                    <div class="indicator mobile d-flex"></div>
                </div>
            </template>
        </div>

        <div class="w-100 d-flex flex-wrap flex-md-nowrap align-items-stretch align-content-start justify-content-start pt-1 pt-md-3 ps-2">

            <div class="d-none d-md-flex flex-wrap menuColumn justify-content-start align-content-start align-items-start ps-lg-5 ps-3">
                <div class="d-flex flex-wrap h-100 w-100 justify-content-start align-content-start align-items-start ps-md-4 ps-3 pb-4">
                    <div ref="menuList" class="menuList ts-3 d-flex flex-md-wrap align-items-start align-content-start placeholder" :class="pad ? 'pad' : 'pad'">
                        <template v-for="(cat, catidx) in categories">
                            <div v-if="existProducts(cat.name)" class="catAccess ts-3-cat font-1 d-flex flex-wrap flex-md-nowrap align-items-center align-content-center  justify-content-start text-start ps-lg-5 ps-md-5 ps-0" :class="categoryNameOver == cat.name ? 'active' : ''" role="button" @click="getCategory(cat.idCat, cat.name)">
                                <div class="indicator d-flex"></div>
                                {{ oneWord(cat.name) }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div ref="itemsData" class="d-flex flex-wrap align-content-start align-items-start itemsColumn pe-2 pe-xxl-0 placeholder">

                <div class="w-100 pe-sm-3 ps-xl-4 pe-xl-5 innerItemsColumn">
                    <template v-for="(cat, catKey, catIdx) in products">
                        <div v-if="cat.length > 0" class="categoryContent d-flex flex-wrap w-100 align-content-start align-items-between" :class="categoryNameOver == catKey ? 'active' : 'inactive'" :id="'cat-'+catKey" observe="categoryContent" :catName="catKey" @mouseenter="setCategoryView(catKey, productsIndexing[catKey].subtitle||'')">
                            <template v-if="productsIndexing[catKey].mounted">
                                
                                <div class="catSpacer mb-5" v-if="catIdx != 0"></div>
                                <template v-for="(item, itidx) in cat">
                                
                                    <div class="itemSpace col-6 col-xxl-4 d-flex flex-wrap align-items-stretch align-content-stretch mb-md-5 mb-4 px-md-4 px-2" @click="viewItem(item)">
                                        <div class="d-flex w-100 flex-wrap align-items-start align-content-start justify-content-start">
                                            <div class="col-12 overflow-hidden d-flex align-items-start align-content-start">
                                                <div class="rectangleImage placeholder">
                                                    <img :src="item.image" :alt="item.name" class="itemImage imgHidden" @load="display($event, false)" @error="display($event, true)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex w-100 flex-wrap align-items-start align-content-between justify-content-start">
                                            <div class="w-100 text-start ps-md-4 pt-2 ts-3 fw-bold itemName text-end text-md-start">{{item.name}}</div>
                                            <div class="w-100 text-start ps-md-5 ps-1 pt-md-2 pt-1 ts-3 fw-bold text-end text-md-start">{{$filters.cash4(Number(item.price))}}&nbsp;</div>
                                        </div>
                                    </div>
                                    
                                </template>
                            </template>
                        </div>
                    </template>
                </div>

            </div>

        </div>
    </div>
</template>
<script>

export default {
    name:"MenuHome",
    emits:['event'],
    props:{
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        categories:{
            default:{},
            type: Object
        },
        category:{
            default:'',
            type: String
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        lastPosition:{
            default:{pos:0, module:null},
            type:Object
        },
        user:{
            type:Object,
            default:{}
        }
    },
    watch:{
        transfer(){
            if(this.transfer.to.includes(this.$options.name)){
                let catName = this.transfer.data.getAttribute('catName') || '';
                let catSubtitle = '';
                if(this.productsIndexing.hasOwnProperty(catName)){
                    catSubtitle = this.productsIndexing[catName].subtitle || '';
                }
                this.setCategoryView(catName, catSubtitle);
            }
        },
        scrollPercent(){
            this.updateCategoryShow();
        },
        category(){
            this.loading = true;
            this.selectedCategorie = this.category;
            this.getProducts();
        },
        categories(){
            if(this.categories.length > 0){
                if(this.category == ''){
                    this.selectedCategorie = this.categories[0].nameUrl;
                    this.categorySubtitleOver = this.categories[0].subTitle;
                    this.getProducts();
                }else{
                    this.getProducts();
                }
            }
        },
    },
    data(){
        return{
            a9:require('@/assets/images/a9.png'),
            a8:require('@/assets/images/static/8.webp'),
            a13:require('@/assets/images/a13.png'),
            loading:false,
            pendingScrollInfinite:false,
            pad:false,
            scrollPercent:0,
            menuShow:false,
            categoryNameOver:"",
            categorySubtitleOver:"",
            observer:null,
            observe:null,
            scrollPaddingView : this.isMobile() ? 60 : 80,
            selectedCategorie:(this.category != '') ? this.category : '',
            phrases:{
                title:{
                    es:"Nuestro menú:",
                    en:"Our Menu:"
                },
                subtitle:{
                    es:"Para compartir",
                    en:"for share"
                },
                buttonOrder:{
                    es:"Ordenar",
                    en:"Order"
                },
                menu:{
                    es:"Menú",
                    en:"Menu"
                },
            },
            products:{},
            productsIndexing:{},
        }
    },
    beforeUnmount(){
        document.removeEventListener("scroll", this.scrollCalc);
        window.removeEventListener("toggleMenuTop", this.setTranformation)
    },
    beforeMount() {
        if(this.selectedCategorie != ''){ //home
            this.getProducts();
        }else if(this.categories.length > 0){ //retorno a home
            this.selectedCategorie = this.categories[0].nameUrl;
            this.getProducts();
        }
    },
    mounted(){
        this.$refs.menuList.classList.add('placeholder');
        document.addEventListener("scroll", this.scrollCalc);
        this.$parent.$parent.$parent.$parent.$parent.$emit('event', {type:'updateObserver'})
        window.addEventListener("toggleMenuTop", this.setTranformation, false);
    },
    activated(){
        if(this.lastPosition.module == this.$options.name){
            setTimeout(()=>{
                this.restoreScrollPosition();
            }, 100)
        }
    },
    methods:{
        comprobateUserDir(){
            if(this.user.data != null && this.user.token != null){
                let homes = Object.keys(this.user.data.homes);
                if(homes.length == 1){
                    return this.user.token
                }
            }
            return this.cityMenu.token
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        setTranformation(e){
           this.pad = e.detail;
        },
        updateCategoryShow(){
            if(!this.pendingScrollInfinite){
                if(this.scrollPercent >= this.scrollPaddingView){
                    this.pendingScrollInfinite = true;
                    if(this.showMoreCategories()){
                        setTimeout(()=>{
                            this.pendingScrollInfinite = false;
                            if(this.scrollPercent >= this.scrollPaddingView){
                                this.updateCategoryShow();
                            }
                        }, 500)
                    }else{
                        this.pendingScrollInfinite = false;
                    }
                    
                }
            }
        },
        showMoreCategories(){
            for(let i in this.productsIndexing){
                if(!this.productsIndexing[i].mounted){
                    this.productsIndexing[i].mounted = true;
                    return true
                }
            }   
            return false          
        },

        tryScrollMenu(){
            // Utiliza la variable domCatMen que ya tienes definida
                let domCatMen = document.getElementById('catMenu-' + this.categoryNameOver);

                if (domCatMen) {
                    // Obtén el contenedor flex que contiene el botón
                    let menuListContainer = domCatMen.parentNode;
                    menuListContainer.scrollLeft = domCatMen.offsetLeft;
                }
        },

        setCategoryView(name, subtitle=''){
            this.categoryNameOver = name;
            this.tryScrollMenu();
            this.categorySubtitleOver = subtitle;
        },

        scrollCalc(){
            this.scrollPercent = (document.documentElement.scrollTop) / ((document.documentElement.scrollHeight) - document.documentElement.clientHeight) * 100;
        },

        wait(time=2000){
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    resolve(true)
                }, time)
            })
        },

        existProducts(name){
            if(!this.productsIndexing.hasOwnProperty(name)){
                return false
            }else if(this.products[name].length <= 0){
                return false
            }
            return true
        },

        getCategory(id, name){ //ir a la categoria dentro de home
            let isFirst = false;
            if(!this.productsIndexing.hasOwnProperty(name)){
                return false
            }
            for(let i in this.productsIndexing){
                this.productsIndexing[i].mounted = true; //monta todo lo que encuentre hasta la categoria indicada a buscar, y detiene para no seguir montando
                if(i == name){
                    isFirst = this.productsIndexing[i].pos == 0;
                    break;                        
                }
            }
            setTimeout(()=>{
                let element = document.getElementById('cat-' + name);
                let position = element.getBoundingClientRect();

                if(window.innerWidth <= 768){ // mobile
                    if (position.top < window.innerHeight) {
                        if(!isFirst){
                            window.scrollTo(0, position.top + window.scrollY - 130);
                        }else{
                            window.scrollTo(0, position.top + window.scrollY - 200);
                        }
                    } else {
                        window.scrollTo(0, position.top + window.scrollY - 130);
                    }
                }else{ // desktop
                    if (position.top < window.innerHeight) {
                        if(!isFirst){
                            window.scrollTo(0, position.top + window.scrollY - 170);
                        }else{
                            window.scrollTo(0, position.top + window.scrollY - 240);
                        }
                    } else {
                        window.scrollTo(0, position.top + window.scrollY - 170);
                    }
                }
            }, 100)

        },

        getProducts(){
            this.categoryNameOver = this.selectedCategorie.charAt(0).toUpperCase() + this.selectedCategorie.slice(1);
            this.tryScrollMenu();
            let mountAllCategories = false;

            if(this.lastPosition.module == this.$options.name){
                mountAllCategories = true;
            }
            
            return new Promise((resolve, reject)=>{
                this.con.getHomeItems(this.cityMenu.token).then((res)=>{
                    try{
                        this.$refs.itemsData.classList.remove('placeholder');
                        this.$refs.menuList.classList.remove('placeholder');
                        this.$refs.menuListMobile.classList.remove('placeholder');
                    }catch(err){}
                    let count = 0;
                    for(let i in this.categories){
                        for(let j in res.data){
                            if(this.categories[i].idCat == res.data[j].idCat){ //si el id del objeto en el array menu es igual al id de la lista de categorias
                                this.productsIndexing[this.categories[i].name] = {mounted:(this.selectedCategorie == this.categories[i].nameUrl || mountAllCategories ? true : false), nameUrl:this.categories[i].nameUrl, empty:false, subtitle:this.categories[i].subTitle, pos:count}
                                //mounted significa que ya esta renderizado sus items en frontend
                                this.products[this.categories[i].name] = res.data[j].item;
                                count++;
                                break;
                            }
                        }
                    }

                    this.verifyNotEmptyCat();
                    resolve(true)
                }).catch((err)=>{console.log(err)});
            }).then((res)=>{
                this.$parent.$parent.$parent.$parent.$parent.$emit('event', {type:'updateObserver'})
                if(mountAllCategories){
                    setTimeout(()=>{
                        this.restoreScrollPosition();
                    }, 100)
                }
            }).catch((err)=>{console.log(err)})

        },


        verifyNotEmptyCat(not = true){
            /* recorre la lista de productos-categorias para identificar si la categoria por defecto tiene productos, si no la cambia*/
            let arr = Object.keys(this.productsIndexing);
            let founded = false;
            arr.forEach((key, index)=>{

                if(this.productsIndexing[key].nameUrl == this.selectedCategorie){ //revisar cual es el identificador nombre para la categoria seleccionada

                    founded = true;
                    this.productsIndexing[key].mounted = true;
                    this.productsIndexing[key].empty = false;

                    if(this.products[key].length <= 0 && (index+1) < arr.length){ // si su llave en products no tiene items y hay posibilidad de avanzar al siguiente indice

                        this.productsIndexing[key].empty = true;
                        this.productsIndexing[key].mounted = false;
                        this.selectedCategorie = arr[index+1];
                        this.verifyNotEmptyCat();

                    }
                }
                if((index+1) == arr.length && !founded && not){ // no existe en los productos de home la categoria

                    this.selectedCategorie = this.productsIndexing[arr[0]].nameUrl;
                    this.verifyNotEmptyCat(false);
                }
            })
            
        },

        viewItem(item){ // ir a las opciones del producto en menu
            this.scrollPosition = window.scrollY || window.pageYOffset;
            this.$parent.$parent.$parent.$parent.$parent.$emit('event', {type:"lastPosition", module:this.$options.name, pos:this.scrollPosition}) //guardar la posicion anterior en el padre general
            this.$router.push({ path: '/menu/'+item.catUrl+'/'+item.idItem })
            //window.open('/menu/' + item.catUrl + '/' + item.idItem, '_blank');
        },

        restoreScrollPosition(scrollPosition) {
            window.scrollTo({
                top: this.lastPosition.pos,
                behavior: 'instant', // O 'auto' para un desplazamiento instantáneo
            });
            this.$parent.$parent.$parent.$parent.$parent.$emit('event', {type:"lastPosition", module:null, pos:0})
        },

        display(e, error=false){
            if(error){
                e.target.src = this.a9
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay', 'error')
            }else{
                e.target.classList.remove('imgHidden')
                e.target.parentNode.classList.remove('placeholder')
                e.target.classList.add('imgDisplay')
            }
		},

        oneWord(string){
            string = string.trim();
            return string.split(" ")[0];
        },
    }
}
</script>
<style lang="css" scoped>
    .title{
        color:var(--text4);
        line-height: 0.9;
    }
    .subtitle{
        color:var(--text6);
        line-height: 1.1;
    }
    .itemDescription{
        text-overflow: ellipsis;
        overflow-wrap: break-word;
    }
    
    /* cargador */

    .loaderPoints{
        width: 200px;
        height: 100px;
        position: fixed;
        pointer-events: none;
        left: calc(50vw - 100px);
        bottom: 20px;
        z-index: 1000;
        animation: fade 1s forwards;
    }

    .loaderPoints svg{
        position: absolute;
        margin: auto;
        height: 75%;
    }
    .loaderPoints svg path{
        fill: var(--ondecagenary);
    }
    .loaderPoints img{
        position: absolute;
        margin: auto;
        height: 90%;
        animation: charge 2s linear infinite;
    }

    /* fin cargador */

    .stickMenu.pad{
        top: 117px !important;
    }
    .stickMenu{
        width: 100vw;
        height: 110px;
        padding-bottom: 10px;
        position: sticky;
        position: -webkit-sticky;
        top: -1px;
        z-index: 1001;
        background:#FFF;
        -moz-transition: opacity 0.50s ease-in-out;
        -webkit-transition: opacity 0.50s ease-in-out;
        -o-transition: opacity 0.50s ease-in-out;
        -ms-transition: opacity 0.50s ease-in-out;
        transition: opacity 0.50s ease-in-out;
    }
    .stickMenu.sticking{
        background: linear-gradient(rgba(240, 240, 240, 1) 0%, rgba(255, 255, 255, 0.9) 92%, transparent 100%);
    }
    .stickMenu.show{
        opacity: 1;
        pointer-events: all;
    }
    .stickMenu.sticking.hidden{
        opacity: 0;
        position: none;
    }

    @media (min-width: 0px) and (max-width: 993.99px) {  
        .loaderPoints{
            height: 60px;
        }
    }


    /* imagen */


    .rectangleImage{
        border:1px solid var(--octagenary);
        overflow: hidden;
        width:100%;
        height: 200px;
        position: relative;
        border-radius: 17px;
        cursor: pointer;
    }
    .itemSpace:hover .rectangleImage img{
        transform: scale(1.05);
    }
    .itemSpace:hover .itemName{
        color: var(--primary);
    }
    .rectangleImage.placeholder{
        background: #ddd;
        background-size: 800px 104px;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        animation: placeholderer 1s infinite;
    }
    .rectangleImage:not(.placeholder){
        background-color:var(--octagenary);
    }
    .rectangleImage img{
        pointer-events: none;
        position: absolute;
        object-position: center top;
        object-fit: cover;
        object-position: center center;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .imgHidden{
		visibility: hidden;
	}
	.imgDisplay{
		animation: 0.3s fade 1;
	}
    .itemImage{
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
        pointer-events: none
    }
	
    .itemImage.error{
        object-position: center center;
        background-color: var(--octagenary);
        object-fit: contain !important;
    }

    @media (max-width: 1199.99px){
        .rectangleImage{
            height: 150px;
        }
    }

    /* fin de imagen */

    .menuColumn{
        width: 300px;
        flex-shrink: 0;
    }
    .itemsColumn{
        width:calc(100% - 300px);
        min-height: 100vh;
        overflow-x:auto
    }
    .itemsColumn.placeholder{
        width:calc(100% - 330px);
        background: #ddd;
        background-size: 800px 104px;
        border-radius: 14px;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        animation: placeholderer 1s infinite;
    }

    .menuList{
        position: sticky;
        position: -webkit-sticky;
        top: 110px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 90%;
        height: auto;
        max-height:calc(100vh - 60px);
        background-color: var(--sexagenary);
        z-index:1;
        border-radius: 14px;
    } 

    .menuList.pad{ /* menu top aparece */
        top: 238px
    }

    .menuList::-ms-scrollbar {
        display: none;
    }
    .menuList::-webkit-scrollbar {
        width: 100%;
        height: 12px;
    }
    .menuList::-webkit-scrollbar-track {
        background: transparent;
    }
    .menuList::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg, var(--ondecagenary), var(--ondecagenary), var(--ondecagenary));
        border-radius: 10px;
        height: 12px;
        width: 10px;
    }

    @media (max-width: 768px) {  
        .menuList{
            background-color: var(--tertiary) ;
            overflow-x:auto;
            top: 0px;
            padding-top: 2px;
            padding-bottom: 2px;
            border-radius: 0px;
        }

        .menuList.pad{ /* menu top aparece */
            top: 75px !important;
        }
        
        .menuColumn{
            width: 100%;
        }
        .innerItemsColumn{
            min-width:calc(100%)
        }
        .itemsColumn.placeholder{
            width:calc(100% - 10px) !important;
        }
        .itemsColumn{
            width:100%;
        }
    }
    
    .menuList.placeholder{
        background: #ddd;
        background-size: 800px 104px;
        background: linear-gradient(to right, #ddd 5%, #eeeeee 25%, #ddd 33%);
        animation: placeholderer 1s infinite;
    }
    .menuList.mobile.placeholder{
        min-height: 100px;
        border-radius:10px;
    }
    .menuList:not(.mobile).placeholder{
        min-height: 70vh;
    }

    .catAccess{
        width: 100%;
        color:var(--text6);
        font-weight: bold;
        position:relative;
        padding-top:0.80rem;
        padding-bottom:0.80rem;
        cursor:pointer
    }

    .catAccess:not(.mobile):not(.active):hover{
        color:var(--text2);
        background-color:var(--decagenary);
    }

    .catAccess:not(.mobile).active{
        background-color:var(--quartenary);
        color:var(--text2);
        pointer-events:none;
    }

    .catAccess.mobile{
        font-size: 19px;
    }

    .catAccess.mobile:not(.active):hover{
        color:var(--text6);
        background-color:transparent;
    }

    .catAccess.mobile:not(:last-child){
        margin-right: 30px;
    }
    .catAccess.mobile:last-child{
        margin-right: 10px;
    }

    .catAccess.mobile.active{
        color:var(--text6);
        pointer-events:none;
    }
    .catAccess .indicator{
        background-color:var(--quinary);
        opacity:0;
        pointer-events:none;
        position:absolute;
        left:0;
    }
    .catAccess .indicator:not(.mobile){
        top:0;
        width:10px;
        height:100%;
    }

    .catAccess .indicator.mobile{
        width:100%;
        height:7px;
        bottom:20%;
    }


    .catAccess.active .indicator{
        opacity:1 !important;
    }

    .categoryContent.inactive{
        opacity: 0.4;
    }

    .catSpacer{
        height: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        align-content: start;
    }

    .catSpacer::after{
        content:"";
        width: 70%;
        margin-top:20px;
        height: 1px;
        border-bottom:5px dashed var(--nonagenary);
        opacity:0.1;
    }

      
</style>