<template lang="html">
    <div class="portrait w-100 d-none d-md-flex align-items-center align-content-center justify-content-center overflow-hidden">
    <!--<img :src="p1" alt=""> -->
        <!--<div class="titleFloating font-3" v-html="phrases.title[lang]"></div>-->
        <div v-if="transitions.length > 1" class="arrow left" @click="nextTrans(-1)"><svg fill="#000000" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M604.7 759.2l61.8-61.8L481.1 512l185.4-185.4-61.8-61.8L357.5 512z"/></svg></div>
        <div v-if="transitions.length > 1" class="arrow right" @click="nextTrans(1)"><svg fill="#000000" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M419.3 264.8l-61.8 61.8L542.9 512 357.5 697.4l61.8 61.8L666.5 512z"/></svg> </div>
        <template v-for="(transition, traix) in transitions">
            <template v-if="transition.type == 'video'">
                <div class="video-container pe-none" :class="transIndex == (traix+1) ? 'd-flex' : 'd-none' " :pos="traix" v-bind:style="{ backgroundColor:transition.bgColor}">
                    <video autoplay muted loop>
                        <source :src="transition.file" :type="'video/'+transition.subType">
                        Tu navegador no soporta el elemento de video.
                    </video>
                </div>
            </template>
            <template v-else>
                <div class="image-container pe-none" :class="transIndex == (traix+1) ? 'd-flex' : 'd-none' " :pos="traix" v-bind:style="{ backgroundColor:transition.bgColor}">
                    <img :src="transition.file">
                </div>
            </template>
        </template>
    </div>
</template>
<script>
export default {
    name:"portraits",
    props:{
        view:'desktop',
        lang:{
            default:"es",
            type: String
        },
        cityMenu:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        user:{
            type:Object,
            default:{}
        },
    },
    watch:{
        transfer(){
            if(this.transfer.to.includes(this.$options.name)){
                this.pause = true;
                this.transIndex = 1;
                this.startBanners(this.user.token != null ? this.user.token : this.cityMenu.token);
            }
        }
    },
    beforeMount() {
        this.startBanners(this.user.token != null ? this.user.token : this.cityMenu.token);
    },
    data(){
        return{
            pause:false,
            transIndex:0,
            transitions:[],
            phrases:{
                title:{
                    es:"Menú<br>Kids",
                    en:"Kid's<br>Menu"
                },
            },
            p1:require('@/assets/images/static/6.jpeg')
        }
    },
    methods:{
        startBanners(token = this.cityMenu.token){
            this.con.getTransitions(token).then((res)=>{
                if(res.code == 200){
                    this.transitions = res.data;
                    if(this.transitions.length > 0){
                        this.transIndex = 1;
                        this.runTransition()
                    }
                }
            }).catch((err)=>{})
        },
        nextTrans(orientation=1){
            let limit = this.transitions.length;
            this.pause = true;
            let nextPos = this.transIndex+orientation;
            if(nextPos <= limit && nextPos > 0){
                this.transIndex = nextPos;
            }else if(nextPos <= 0){
                this.transIndex = limit;
            }else{
                this.transIndex = 1;
            }
        },
        resumeCount(){
            this.pause = false;
            setTimeout(()=>{
                if(!this.pause){
                    this.runTransition();
                }else{
                    this.resumeCount();
                }
            }, 5000)
        },
        runTransition(){
            let wait = this.transitions[this.transIndex-1].duration * 1000;
            setTimeout(()=>{
                if(!this.pause){
                    let limit = this.transitions.length;
                    if(this.transIndex < limit){
                        this.transIndex++;
                    }else{
                        this.transIndex = 1;
                    }
                    this.runTransition();
                }else{
                    this.resumeCount();
                }
            }, wait);
        },
    }
}
</script>
<style lang="css">
    .portrait{
        position: relative;
        margin: 0 5px 0 5px;
        border-top:1px solid var(--secondary);
        height: 551px;
        background-color: #fff;
    }
    @media (min-width: 0px) and (max-width: 768.17px) {
        .portrait{
            height: 300px;
            margin: 0 1px 0 1px;
        }
        .image-container img{
            object-fit: cover !important;
        }    
        .video-container video{
            object-fit: cover !important;
        }
        .titleFloating{
            font-size: 14vw !important;
        }
    }

    @media (min-width: 768.18px) and (max-width: 1199px) {
        .portrait{
            height: 400px;
        }
        .image-container img{
            object-fit: cover !important;
        }    
        .video-container video{
            object-fit: cover !important;
        }
        .titleFloating{
            font-size: 12vw !important;
        }
    }

    @media (min-width: 1200px) and (max-width: 1800px) {

    }

    .titleFloating{
        line-height: 1.2;
        font-size: 7vw;
        color:var(--text2);
        position:absolute;
        left: 6%;
        text-align: left;
        margin: auto;
    }

    .video-container {
        position: absolute;
        left: 100%;
        top:0;
        width: 100%;
        height: 100%; /* Ajusta la altura como desees */
        overflow: hidden;
        animation: transite 0.5s ease-in 1 forwards;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .image-container{
        position: absolute;
        left:100%;
        top:0;
        width: 100%;
        height: 100%; /* Ajusta la altura como desees */
        overflow: hidden;
        animation: transite 0.5s ease-in 1 forwards;
    }

    .image-container img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
    }

    .arrow{
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: var(--primary);
        border-radius: 2px;
        opacity: 0.5;
        cursor: pointer;
    }

    .arrow:hover{
        opacity: 1;
        transform: scale(1.1);
    }

    .arrow svg{
        width: 100%;
        height: 100%;
    }

    .arrow svg  path{
        fill: var(--text2)
    }

    .arrow.left{
        left: 5px;
        top:calc(50% - 25px);
        z-index: 10;
    }

    .arrow.right{
        right: 5px;
        top:calc(50% - 25px);
        z-index: 10;
    }

</style>