<template lang="html">
    <div id="loadCharge" class="loaderPoints" v-if="active">
        <div class="h-100 w-100 position-relative d-flex justify-content-center align-items-center align-content-center">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                    <path class="st0" d="M80.5,10.3c1.3,1.1,2.6,2.3,3.6,3.7c-4.5-2.5-9.4-4.5-15.2-3.6c-1.6,0.2-3.1,0.5-4.6,0.9   c0.1-2.6-0.5-5.2-2.6-6.9c-1.8-1.2-3.8-2-5.9-2.2c1.7,1.7,2.8,4,2.5,6.5c-0.1,1.1-1.2,2.2-2.2,2.5c-1.7,0.6-3.9,0.4-5.3-0.8   c-1.5-1.1-2.7-2.7-2.3-4.6l0.2-0.5c0.3,0.5,0.5,1.2,1.2,1.6c0.4,0.2,1,0.1,1.4-0.1c0.3-0.2,0.4-0.5,0.4-0.8c-0.1-1.5-1.7-2.4-3-2.7   c-2.9-0.7-6.7-0.7-9.4,0.2c3.8,2.6,8,6.2,9,10.7c0.4,0.9,0.5,1.9,0.6,2.9c-2.7-4.3-6.9-7.7-11.6-8.9C33,7.4,27.8,6.8,23.4,7.9   c6.2-4.3,13.4-6.5,21-7.6C58-1.1,70.4,2.4,80.5,10.3L80.5,10.3z"/>
                    <path class="st0" d="M10,20.1l-1.4,1.7c-3.9,5.8-6.8,12.1-8,19C-0.6,50.7-0.3,61.5,4,69.9c-0.1-2.4-0.2-5.1,0.4-7.3   c1.4-4.6,3.4-9.2,7.6-12.5c-1.1,0.2-2.4,0.3-3.4,0c-2-0.5-3.3-2.5-4.1-4.2c-0.6-2.1-1-5,0.1-7.1c0.2,1.5,0.9,3,2,4   c1.3,1.1,3.2,1.3,4.7,0.7c2-1,3.2-3.3,3-5.6c-0.3-1.8-2.1-3.1-3.9-3.5c0.3,0.6,1.1,1.2,0.5,1.9c-0.2,0.3-0.5,0.3-1,0.2   c-0.8-0.4-1.1-1.3-1.3-2.1C7.9,29.7,8.2,24.3,10,20.1L10,20.1z"/>
                    <path class="st0" d="M98.4,39.5c-0.9,6-3.5,12-9,15.3c-4.7,2.8-12,3.2-16.7,0.2c1.5,4,5.1,8.1,9.3,8.9c1.5,0.3,3.3-0.2,4.6,0.6   c1.1,1.1,1.7,2.4,1.7,3.9c0.1,4.1-3.1,7.8-6.6,9.3c-2.6,0.9-5.6,0.6-7.5-1.4c-1.2-1.4-1.5-3.5-1.1-5.3c0.3-1.5,2-2.6,3.4-2.9   c1.6-0.2,3.2,0.2,4.1,1.7c0.6,0.8,0.6,2,0.2,2.9c-0.1,0.3-0.3,0.6-0.6,0.7c0.3-1.3,0.5-2.8-0.5-3.8c-1.1-0.7-2.8-0.7-3.9-0.2   c-0.6,0.3-0.9,0.9-1,1.4c-0.6,1.6-0.2,3.6,1,4.7c1.5,1.3,4,1,5.5,0.2c1.6-1,2.1-2.6,2.1-4.4c0-2.3-1.1-4.5-3.1-5.5   c-2.7-1.4-6.3-0.6-8.5,1.3c-2.5,2.9-2.4,6.9-0.9,10.2c2.1,3.4,5.7,5,9.8,4.6c8.5-1,14.8-9.5,16.9-17.1   C100.1,57.4,101.1,47.4,98.4,39.5L98.4,39.5z"/>
                    <path class="st0" d="M74.8,83.1c-0.1,4.2-3.4,7.7-7,9.5c-2.6,1.3-6.4,1.5-8.7-0.5c-1-0.9-1.4-2-1.3-3.3c0.2-0.8,0.8-1.6,1.5-1.8   c0.6-0.2,1.5-0.1,1.9,0.4c0.3,0.3,0.4,0.6,0.4,1c0,0.6-0.3,1.2-0.9,1.4c-0.4,0.3-1,0.1-1.4,0c0.1,0.1,0.1,0.3,0.2,0.4   c0.9,1.5,2.5,2.1,4.1,1.9c1.3-0.2,2.3-1.4,2.7-2.6c0.3-1.9-0.4-3.7-1.8-4.8c-1.5-1.2-3.6-1.1-5.5-0.7c-3,1.4-4.7,4-7.1,6.3   c-1.5,1.2-2.9,2.7-4.7,3.2c0.6-1,1.3-2,1.5-3.2c0.1-1.3-0.2-2.5-1.1-3.3c-1.4-1.4-3.5-1.7-5.4-1.2c-1.7,0.5-3.4,1.8-3.6,3.7   l0.8-0.4c0.4-0.2,1.2-0.2,1.6,0.1c0.4,0.4,0.5,1.1,0.3,1.6c-1.2,1.9-3.6,1.9-5.8,2C29,92.8,22.9,91.3,17.5,88   c4.7,4.1,9.9,7.3,15.3,9.3c7.4,2.9,16.5,3.3,24.7,2c10.9-1.4,21-6.2,28.6-15c-2.9,2-6.1,3.3-9.9,3.2C76.6,85.8,76.1,84.2,74.8,83.1   L74.8,83.1z"/>
                    <path class="st0" d="M84.2,15.8c7.2,4.7,13.6,12.4,13,21.7c-0.7,6.3-3.5,13-9.7,15.6c-4.6,2.1-11.3,2-15.7-0.7   c-5.3-4-6.8-11-5.1-17.3c1-3.4,3.9-6.7,7.5-7.1c3-0.5,5.8,0.4,7.5,2.9c2.2,3.1,2.6,7.8,0.5,11.2c-1.4,2.3-4,3.5-6.8,3.2   c-1.7-0.1-3.3-1.1-4.1-2.7c-1.2-2.6-0.5-6,1.2-8.1c0.9-1,1.9-1.5,3.2-1.2c1,0.2,2,0.7,2.5,1.7c0.6,1.3,0.2,3-0.2,4.3   c1-0.1,1.7-1.3,1.9-2.2c0.3-1.9-0.2-3.8-1.6-5.1c-1.2-1.1-3.1-1.3-4.6-0.8c-3.3,1.4-4.5,5-4.4,8.4c0,2.8,1.3,5.7,4,6.9   c3.4,1.3,7.3,0.1,9.5-2.5c3.8-4.4,3.6-11.8,1-16.7c-2-4.6-6.8-9.2-12-9.2c-2.1,0-4.1,0.7-5.4,2.3c-1.3,1.6-1.6,3.5-1.6,5.6   l-0.4,13.9v6.4l0.4,15.3c0.3,7.9,1.8,15.4,4.3,22.4c-3.6-2.5-9.4-2.3-13.4-0.4l-2.4,1.5c1.2-5.3,1.7-10.9,1.9-16.6l0.1-8.9   l-0.3-12.6L54.6,38c-0.6-8.5-1.9-16.8-4.4-24.6c3.9,0.5,8,0.6,12,0.4l8.5-0.7C75.6,13.1,80.4,13.1,84.2,15.8L84.2,15.8z"/>
                    <path class="st0" d="M47.1,17.4c2.3,3.2,3.3,6.9,3,11.1c-0.8,4.7-4.6,9.2-9.2,10.7c9.9,7.4,14.7,19.6,11.3,31.8   c-3.1,10.8-14.5,17.4-25.1,18.1c-5.6,0.1-10.6-1.6-14.7-5.1c-5.6-5.1-7.5-12.2-6.6-19.7c1-6.9,6.5-13.1,13.1-15.3   c6.3-1.8,12.9,0.2,17.3,5c4.2,4.5,4.2,12.3,2.3,17.9c-1.4,3.9-5.3,7-9.3,7.5c-4.5,0.4-8.5-1.2-11.5-4.3c-3-3.9-3.6-10-1.5-14.6   c1.3-3,4.4-5.7,7.8-5.7c3,0.2,6,1.1,7.7,3.8c1.9,2.8,2.4,6.8,1.1,10c-0.6,1.8-2.3,3.7-4.2,4c-2.8,0.5-5.4-0.5-6.8-2.8   c-1.5-2.2-1.6-5.7-0.1-7.9c1.1-1.4,2.5-2.4,4.3-2c1.2,0.2,2.2,0.7,3,1.6c0.3-0.7-0.2-1.4-0.6-2c-1.2-1.3-3-1.5-4.7-1.3   c-2,0.3-3.9,2.5-4.5,4.5c-1.2,3.3-0.6,7.5,1.8,10c2,2.2,5,2.9,8,2.4c2.5-0.5,5-2.5,5.8-5c1.7-3.9,1.5-9.5-1.3-12.8   c-3-3.5-7.5-5.2-12.2-4c-4.3,1.2-8.3,5-9,9.4c-1.6,6.4,0.3,13,5.3,17.1c4.4,3.4,11.5,3.4,16.3,1.1c7.1-3.5,9.5-11.3,9-19   c-1-7.3-3-14.4-9-19.2c-2.1-1.6-5-2.3-7.8-1.6c-0.8,2.2-0.2,4.6,0.8,6.5c-2.9-1-6.5-0.8-9.4-0.1c-1.2,0.3-2.2,0.9-3.3,1.4   c2.6-6.6,2.3-15,1.8-22.4c-0.4-2.3-0.3-4.8-1.1-6.9c-0.4-0.8-1.2-1.2-1.9-1.1c-0.5-0.1-1,0.2-1.4,0.4c4.6-7.5,13.1-10.6,21.8-9.1   C38.4,10.5,43.5,12.9,47.1,17.4L47.1,17.4z M36.8,27.5c0.2-2.3-0.1-4.6-1.1-6.6c-0.5-1.2-1.7-2.1-3-2.5c-1.4-0.4-2.7,0.2-3.5,1.1   c-2.7,3.4-4,7.7-3.3,12.2c0.3,1.6,1.5,3.4,3.1,3.7c2.5,0,3.8-0.2,5.6-2.1C35.8,31.7,36.5,29.6,36.8,27.5L36.8,27.5z"/>
                </g>
            </svg>
            <img :src="a13">
        </div>
    </div>
</template>
<script>
export default {
    name:"loaderAnim",
    props:{
        active:{
            default: false,
            type: Boolean
        }
    },
    data(){
        return{
            a13:require('@/assets/images/a13.png'),
        }
    }
}
</script>
<style lang="css" scoped>
#loadCharge.loaderPoints{
    width: 100vw;
    height: 100vh;
    position: fixed;
    pointer-events: all;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: 9999;
    animation: fade 1s forwards;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.3);
}

#loadCharge.loaderPoints svg{
    width: 70px;
    height: 100px;
    position: absolute;
    margin: auto;
    height: 75%;
    animation: palp 1s linear infinite;
    pointer-events: none;
}
#loadCharge.loaderPoints svg path{
    fill: var(--ondecagenary);
}
#loadCharge.loaderPoints img{
    width: 80px;
    height: 80px;
    position: absolute;
    margin: auto;
    animation: charge 1s linear infinite;
    pointer-events: none;
}

</style>